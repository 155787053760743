import React from 'react';
import { Avatar, AvatarProps, Box, BoxProps } from '@mui/material';
import GlobalPaymentLogos from './Global';
import AfricaPaymentLogos from './Africa';
import { getEnv, envEnum } from 'src/utils/env';

type Props = {
  containerProps?: BoxProps;
  avatarProps?: AvatarProps;
};
export const PaymentLogos = ({ avatarProps, containerProps }: Props) => {
  const version = getEnv(envEnum.VERSION);
  return version === 'global' ? (
    <GlobalPaymentLogos
      avatarProps={avatarProps}
      containerProps={containerProps}
    />
  ) : (
    <AfricaPaymentLogos
      avatarProps={avatarProps}
      containerProps={containerProps}
    />
  );
};

