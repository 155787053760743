import React, { useRef, useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { useGetKycUrl } from 'src/hooks/queries/useKyc';

const KycVerification = () => {
  const iframeRef = useRef<any>(null);
  const { data } = useGetKycUrl({});
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Box>
      {isLoading && <LinearProgress />}
      <Box sx={{
        mt: 4,
        iframe: {
          width: '100%',
          height: '800px',
          minHeight: 0,
          border: 'none',
        },
      }}
      >
        <iframe
          id="myIframe"
          title="Inline Frame Example"
          ref={iframeRef}
          src={data?.url}
          onLoad={() => setIsLoading(false)}
        />
      </Box>
    </Box>
  );
};

export default KycVerification;
