import React from 'react';
import _, { get } from 'lodash';

import BuyCurrency from 'src/Features/BuyCrypto/BuyCurrency';
import EnterAddress from 'src/Features/BuyCrypto/EnterAddress';
import AddNewCard from 'src/Features/BuyCrypto/AddNewCard';
import MobilePayment from 'src/Features/BuyCrypto/MobilePayment';
import SubOptions from 'src/Features/BuyCrypto/SubOptions';
import CISubOptions from './CISubOption';
import PaymentInfo from './PaymentInfo';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { useGetPaymentOptions } from 'src/hooks/queries/useCurrency';
import styled from '@emotion/styled';
import { getEnv, envEnum } from 'src/utils/env';
import PaymentOptions from './PaymentOptions';
import Instruction from './Instruction';

const BuyCrypto = () => {
  const { step } = useBuyCurrencyStore();
  const { data } = useGetPaymentOptions();
  const version = getEnv(envEnum.VERSION);

  const mapOptionToElement = () => {
    if (!data) {
      return null;
    }
    if (data.options.length == 1) {
      if (version == 'CI') {
        return <CISubOptions />;
      }
      if (data.options[0].name == 'Card') {
        return <AddNewCard />;
      }
      if (data.options[0].name == 'Mobile') {
        return <MobilePayment />;
      }
    }
    return <PaymentOptions options={data.options} />;
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <BuyCurrency />;
      case 2:
        return (
          <PaymentInfo>
            <EnterAddress />
          </PaymentInfo>
        );
      case 3:
        return <PaymentInfo>{mapOptionToElement()}</PaymentInfo>;
      case 4:
        return (
          <PaymentInfo>
            <SubOptions />
          </PaymentInfo>
        );
      case 5:
        return (
          <PaymentInfo>
            <Instruction />
          </PaymentInfo>
        );

      default:
        return null;
    }
  };

  return <Wrapper>{renderStep()}</Wrapper>;
};

const Wrapper = styled.div`
  .MuiPaper-rounded {
    border-radius: 20px;
  }
`;

export default BuyCrypto;

