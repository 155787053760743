import * as React from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';
import { Box, Typography, Grid, Stack, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { useGlobalStore } from 'src/stores';
import { useGetCurrencies } from 'src/hooks/queries/useCurrency';
import CardWrapper from 'src/components/Common/CardWrapper';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { shortAddress } from 'src/utils/string';
import { useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactNode;
};

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

function PaymentInfo({ children }: Props) {
  const { i18n, t } = useTranslation();
  const {
    onBack,
    transactionState: { from_currency, spend, to_currency, receive },
    formState: { walletAddress },
  } = useBuyCurrencyStore();

  const [isLoadedImage, setIsLoadedImage] = React.useState(false);

  const { mode } = useGlobalStore();
  const { data } = useGetCurrencies();

  return (
    <Grid
      container
      spacing={{
        base: 4,
        xs: 2,
        sm: 2,
        md: 2,
        lg: 4,
      }}
    >
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} position='relative'>
        <Box
          position='relative'
          display={{ base: 'block', xs: 'none', md: 'block' }}
          mt={6}
        >
          <IconButton onClick={onBack}>
            <ArrowBack />
            <Typography>back</Typography>
          </IconButton>

          <CardWrapper>
            <Stack spacing={2}>
              <Stack spacing={1} direction='row' justifyContent='center'>
                <Typography
                  fontWeight='bold'
                  textAlign='center'
                  fontSize={{ base: 14, xs: 10, sm: 22 }}
                >
                  {t('YouArePaying')}
                </Typography>
                <Typography
                  fontWeight='bold'
                  textAlign='center'
                  fontSize={{ base: 14, xs: 10, sm: 22 }}
                  color={mode === 'light' ? 'primary.main' : 'white'}
                >
                  {getSymbolFromCurrency(from_currency || '')}
                  {_.toNumber(spend)} ({from_currency})
                </Typography>
              </Stack>
              <Stack spacing={1} direction='row' justifyContent='center'>
                <Typography
                  fontWeight='bold'
                  textAlign='center'
                  fontSize={{ base: 14, xs: 10, sm: 22 }}
                >
                  {t('ForThePriceOf')}
                </Typography>
                <Typography
                  fontWeight='bold'
                  textAlign='center'
                  fontSize={{ base: 14, xs: 10, sm: 22 }}
                  color={mode === 'light' ? 'primary.main' : 'white'}
                >
                  {receive} {to_currency}
                </Typography>
              </Stack>
              {walletAddress && (
                <Stack spacing={1} direction='row' justifyContent='center'>
                  <Typography
                    fontWeight='bold'
                    textAlign='center'
                    fontSize={{ base: 14, xs: 10, sm: 22 }}
                  >
                    {t('Your')} {to_currency} {t('WillBeSentTo')}{' '}
                    {shortAddress(walletAddress)}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </CardWrapper>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        {children}
      </Grid>
    </Grid>
  );
}
export default PaymentInfo;
