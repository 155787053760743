/* eslint-disable react/no-unescaped-entities */
import React, { ReactNode, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Drawer,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { RoutePath } from 'src/routes/path';

type LegalLayoutProps = {
  children: ReactNode;
};

const NoService = () => (
  <Container maxWidth='lg'>
    <Stack
      mb={2}
      sx={{
        justifyContent: 'center',
        width: '100%',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src='https://i.imgur.com/qIufhof.png' style={{ width: 500 }} />
      <div id='info'>
        <h3>Service unavailable, please try again later</h3>
      </div>
    </Stack>
  </Container>
);

export default NoService;
