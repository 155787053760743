import React, { useEffect } from 'react';
import { Box, Typography, Stack, CircularProgress } from '@mui/material';
import { Controller, useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import _ from 'lodash';
import postalCodes from 'postal-codes-js';

import { SelectInput, TextInput } from 'src/components/Inputs';
import { COUNTRIES } from 'src/constants/country';
import { getError, getErrorMessage } from 'src/utils/common';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { usePayment } from 'src/hooks/queries/useCurrency';
import { useGetProfile } from 'src/hooks/queries/useAuth';
import CardWrapper from 'src/components/Common/CardWrapper';
import TimeOut from './TimeOut';
import { getCountry } from 'src/utils/localStorage';
import { useTranslation } from 'react-i18next';

const formSchema = yup.object().shape({
  country: yup.string().required('Required'),
  state: yup.string().required('Required'),
  address: yup.string().required('Required'),
  city: yup.string().required('Required'),
  zip: yup.string().required('Required'),
});

export const AddNewCard = () => {
  const { i18n, t } = useTranslation();
  const {
    transactionState: { id, receive },
    sessionState: { firstPay },
    onNext,
    setSessionState,
  } = useBuyCurrencyStore();
  const country = getCountry();

  const [flashSubmitting, setFlashSubmitting] = React.useState(false);

  const { mutateAsync: payment, isLoading } = usePayment();
  const { data } = useGetProfile();

  const { register, formState, handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(formSchema),
  });

  const { field } = useController({ name: 'expiration_date', control });

  const handleSubmitForm = async (values: any) => {
    await payment({
      ...values,
      phone_number: '0',
      payment_id: id as string,
      payment_option: 'Mobile',
    });
    onNext();
  };

  const handleChangeCountry = () => {
    setValue('zip', '');
  };

  useEffect(() => {
    // ignore address input for the first time if payment < 1000 USD
    setValue('country', country as string);

    // if (country && receive && parseFloat(receive.toString()) < 1000) {
    // if (firstPay) {
    setFlashSubmitting(true);
    setSessionState({ firstPay: false });
    handleSubmitForm({
      country: country as string | 'CI',
      state: '0',
      address: '0',
      city: '0',
      zip: '0',
    });
    // }
    // }
  }, [country]);

  useEffect(() => {
    setValue('state', _.get(data, 'state', '') as string);
    setValue('country', country as string);
    setValue('address', _.get(data, 'street1', '') as string);
    setValue('city', _.get(data, 'city', '') as string);
    setValue('zip', _.get(data, 'postal_code', '') as string);
  }, [data]);
  if (flashSubmitting) {
    return (
      <CardWrapper>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h4' fontWeight='bold' mb={1}>
            {t('MobilePayment')}
          </Typography>
          <TimeOut />
        </Box>
        <Stack spacing={2} mt={4}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        </Stack>
      </CardWrapper>
    );
  } else
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <CardWrapper>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='h4' fontWeight='bold' mb={1}>
              {t('MobilePayment')}
            </Typography>
            <TimeOut />
          </Box>
          <Stack spacing={2} mt={4}>
            <Typography textAlign='center' color='grey.400' fontWeight='bold'>
              {t('ReviewBillingAddress')}
            </Typography>
            <Box display='flex' gap={2}>
              <Controller
                name='country'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <SelectInput
                    label='Country'
                    placeholder='Your country'
                    error={getError(formState, 'country')}
                    message={getErrorMessage(formState, 'country')}
                    options={COUNTRIES}
                    {...field}
                    onChange={(event) => {
                      field.onChange(event);
                      handleChangeCountry();
                    }}
                  />
                )}
              />

              <TextInput
                label='Zipcode'
                error={getError(formState, 'zip')}
                message={getErrorMessage(formState, 'zip')}
                {...register('zip')}
              />
            </Box>
            <TextInput
              label='Address'
              error={getError(formState, 'address')}
              message={getErrorMessage(formState, 'address')}
              {...register('address')}
            />
            <Box display='flex' gap={2}>
              <TextInput
                label='City'
                error={getError(formState, 'city')}
                message={getErrorMessage(formState, 'city')}
                {...register('city')}
              />
              <TextInput
                label='State'
                error={getError(formState, 'state')}
                message={getErrorMessage(formState, 'state')}
                {...register('state')}
              />
            </Box>
          </Stack>
          <Box display='flex' justifyContent='center' mt={2}>
            <LoadingButton
              variant='contained'
              type='submit'
              loading={isLoading}
              fullWidth
            >
              {t('Continue')}
            </LoadingButton>
          </Box>
        </CardWrapper>
      </form>
    );
};

export default AddNewCard;

