import * as React from 'react';
import styled from '@emotion/styled';
import { Container } from '@mui/material';

import { useListenRouterChange } from 'src/hooks/app/useListenRouter';

type Props = {
  children: React.ReactNode;
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

function EmptyLayout({ children }: Props) {
  // Listen router change
  useListenRouterChange();

  return (
    <Wrapper>
      <Container maxWidth="xl" sx={{ flex: 1, pt: 2, pb: 4 }}>
        {children}
      </Container>
    </Wrapper>
  );
}
export default EmptyLayout;
