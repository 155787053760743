import { TransactionStatus } from 'src/Types/Currency';

const {
  COMPLETED,
  DONE,
  PENDING,
  PROCESSING,
  ERROR,
  INIT,
  CREATED,
  DECLINED,
  PARTIAL,
} = TransactionStatus;

export const colors = {
  COMPLETED: 'success.main',
  DONE: 'success.main',
  PENDING: 'warning.main',
  PROCESSING: 'warning.main',
  ERROR: 'error.main',
  INIT: 'primary.main',
  CREATED: 'success.main',
  DECLINED: 'error.main',
  PARTIAL: 'primary.main',
};
