import * as React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

type DialogConfirmProps = DialogProps & {
  onCancel: () => void;
  onAccept: () => void;
  isLoading?: boolean;
  contentText?: string;
}

export const DialogConfirm = ({
  onCancel,
  onAccept,
  isLoading,
  contentText,
  ...props
}: DialogConfirmProps) => (
  <Dialog
    maxWidth="md"
    PaperProps={{ sx: { borderRadius: '20px' } }}
    {...props}
  >
    <DialogTitle variant="button">Confirm</DialogTitle>
    <DialogContent sx={{ width: '320px' }}>
      {contentText && (
        <Typography mb={1}>
          {contentText}
        </Typography>
      )}
      <Typography fontWeight="bold">
        Are you sure?
      </Typography>
      <Box display="flex" mt={4} gap={1}>
        <LoadingButton
          onClick={onCancel}
          variant="outlined"
          size="small"
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          onClick={onAccept}
          variant="outlined"
          size="small"
          loading={isLoading}
        >
          Accept
        </LoadingButton>
      </Box>
    </DialogContent>

  </Dialog>
);
