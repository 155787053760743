import React, { useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import getTheme from 'src/theme';
import { useGlobalStore } from 'src/stores';
import { useMutateGetCountry } from 'src/hooks/queries/useCommon';
import { useMutateGetSettings } from 'src/hooks/queries/useSettings';
import { useSettingsStore } from 'src/stores/settings';
import NoServiceScreen from 'src/screens/NoService';
import { getCountry } from 'src/utils/localStorage';

type Props = {
  children: React.ReactNode;
};

const GlobalProvider = ({ children }: Props) => {
  const { mode } = useGlobalStore();
  const { mutate: fetchCountry } = useMutateGetCountry();
  const { mutate: fetchSettings } = useMutateGetSettings();
  const {
    activeState: { enabled },
  } = useSettingsStore();

  useEffect(() => {
    fetchSettings();
    if (!getCountry()) {
      fetchCountry();
    }
  }, []);
  if (enabled)
    return (
      <MuiThemeProvider theme={getTheme(mode)}>{children}</MuiThemeProvider>
    );
  else {
    return (
      <MuiThemeProvider theme={getTheme(mode)}>
        <NoServiceScreen />
      </MuiThemeProvider>
    );
  }
};

export default GlobalProvider;

