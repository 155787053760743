import { Typography } from '@mui/material';
import React from 'react';
import ReactCountdown, { zeroPad } from 'react-countdown';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { toastWarning } from 'src/utils/toast';
import { t } from 'i18next';

type RenderTimeProps = {
  minutes: number;
  seconds: number;
};

const RenderTime = ({ minutes, seconds }: RenderTimeProps) => {
  return (
    <Typography color='warning.main' variant='caption'>
      {`${t('remainingTime')}:  ${zeroPad(minutes)}m : ${zeroPad(seconds)}s`}
    </Typography>
  );
};

const TimeOut = () => {
  const { resetStore } = useBuyCurrencyStore();

  const handleEndTime = () => {
    toastWarning({ description: 'Payment has expired. Please try again' });
    setTimeout(() => resetStore(), 3000);
  };

  return (
    <ReactCountdown
      date={Date.now() + 1000 * 60 * 30}
      renderer={RenderTime}
      onComplete={handleEndTime}
    />
  );
};

export default React.memo(TimeOut);

