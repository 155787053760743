import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';

import { CountryPhoneInput, TextInput } from 'src/components/Inputs';
import { getError, getErrorMessage } from 'src/utils/common';
import { CreateAccount } from 'src/Types/Auth';
import { useCreateAccount } from 'src/hooks/queries/useAuth';
import { isValidPhoneNumber } from 'react-phone-number-input';
import CardWrapper from 'src/components/Common/CardWrapper';
import { useTranslation } from 'react-i18next';
import { getCountry } from 'src/utils/localStorage';
import { useHistory, useLocation } from 'react-router';
import { RoutePath } from 'src/routes/path';
import { getCountryCode } from 'src/constants/countryPhone';

const formSchema = yup.object().shape({
  email: yup.string().email('Email is invalid'),
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  phone: yup
    .string()
    .nullable()
    .required('Required')
    .test('phone', 'Invalid phone number', (phone) =>
      isValidPhoneNumber(phone || '')
    ),
  checked: yup.boolean().oneOf([true], 'Required'),
});

const RegisterWithPhone = () => {
  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const prefillPhone = searchParams.get('phone');
  const refId = searchParams.get('refId');

  const { i18n, t } = useTranslation();
  const storageCountry = getCountry();

  const [country, setCountry] = useState(storageCountry);

  const { register, handleSubmit, formState, control, setValue, watch } =
    useForm<CreateAccount>({
      resolver: yupResolver(formSchema),
      defaultValues: {
        type: 'ID',
        phone: '',
      },
      mode: 'all',
    });

  const checked = watch('checked');

  const { mutate, isLoading } = useCreateAccount();
  const handleSubmitForm = async (values: CreateAccount) => {
    mutate(
      { ...values, ref_id: refId },
      {
        onSuccess: () => {
          setTimeout(() => {
            history.push(`${RoutePath.LOGIN}?phone=${values.phone}`);
          }, 1000);
        },
      }
    );
  };

  useEffect(() => {
    if (prefillPhone) {
      let _phone = prefillPhone.trim();
      const CountryCode = getCountryCode(_phone);
      setCountry(CountryCode || '');
      if (!prefillPhone.startsWith('+')) {
        _phone = ('+' + prefillPhone).replace(/\s/g, '');
      }

      setValue('phone', _phone);
    }
  }, [prefillPhone]);

  useEffect(() => {
    if (storageCountry) {
      setCountry(storageCountry);
    }
  }, [storageCountry]);

  return (
    <Box maxWidth='xl' sx={{ mx: 'auto' }}>
      <CardWrapper>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='h4' fontWeight='bold' my={2}>
              {t('RegisterAccount')}
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Box display='flex' gap={2}>
              <CountryPhoneInput
                country={country || ''}
                label={t('phoneNumber')}
                error={getError(formState, 'phone')}
                message={getErrorMessage(formState, 'phone')}
                control={control}
                {...register('phone')}
              />
            </Box>

            <Box display='flex' gap={2}>
              <TextInput
                label={t('firstName')}
                placeholder=''
                error={getError(formState, 'first_name')}
                message={getErrorMessage(formState, 'first_name')}
                {...register('first_name')}
              />
              <TextInput
                label={t('lastName')}
                placeholder=''
                error={getError(formState, 'last_name')}
                message={getErrorMessage(formState, 'last_name')}
                {...register('last_name')}
              />
            </Box>
          </Stack>
          <Box display='flex' justifyContent='center' mt={2}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...register('checked')} />}
                label={
                  <Typography color='action.active' fontWeight='medium'>
                    {t('IAgreeBitmambo')}{' '}
                    <Typography
                      component='span'
                      color='primary'
                      fontWeight='medium'
                    >
                      {t('TermsOfUse')}
                    </Typography>
                  </Typography>
                }
              />
            </FormGroup>
          </Box>
          <Box display='flex' justifyContent='center' mt={2}>
            <LoadingButton
              variant='contained'
              type='submit'
              loading={isLoading}
              fullWidth
              disabled={!checked}
            >
              Continue
            </LoadingButton>
          </Box>
        </form>
      </CardWrapper>
    </Box>
  );
};

export default RegisterWithPhone;
