import * as React from 'react';
import styled from '@emotion/styled';
import { Box, Container, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { useListenRouterChange } from 'src/hooks/app/useListenRouter';
import Header from 'src/Features/core/Header';

import Footer from '../Footer';

type Props = {
  children: React.ReactNode;
};

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

function PrimaryLayout({ children }: Props) {
  // Listen router change
  const theme = useTheme();
  useListenRouterChange();
  const match_4k = useMediaQuery(theme.breakpoints.up('xl_4k'));
  const match_2k = useMediaQuery(theme.breakpoints.up('xl_2k'));
  let applyMaxWidth = true;
  let maxWith = 2000;
  if (match_2k) {
    applyMaxWidth = false;
    maxWith = 1200;
  }
  if (match_4k) {
    maxWith = 2000;
  }
  return (
    <Wrapper>
      <Header />
      <Container
        maxWidth={applyMaxWidth == true ? 'lg' : false}
        sx={{
          py: {
            base: 10,
            xs: 5,
            sm: 5,
            md: 5,
            lg: 10,
          },
          flex: 1,
          maxWidth: maxWith,
          display: 'flex',
        }}
      >
        <Grid
          container
          spacing={{
            base: 4,
            xs: 2,
            sm: 2,
            md: 2,
            lg: 4,
          }}
          sx={{ alignItems: 'center' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Wrapper>
  );
}
export default PrimaryLayout;

