/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { LegalLayout } from '../Privacy';

const UserAgreement = () => (
  <LegalLayout>
    <Box width="100%">
      <Typography textAlign="center" fontWeight="bold" fontSize={24} mb={5}>USER AGREEMENT&nbsp;</Typography>
      <Box mt={5} width="100%">
        <div>
          <div>
            <div>
              <ol start={1}>
                <li>
                  <p>Instant Product&nbsp;</p>
                </li>
              </ol>
            </div>
            <div>
              <p>&nbsp;</p>
            </div>
            <div>
              <p>
                This agreement (the “Agreement”) is for customers who reside
                in the European Economic Area.&nbsp;
              </p>
            </div>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              In reviewing these&nbsp;terms&nbsp;you will see that some text
              is coloured in green. These clauses only apply to the regulated
              services provided to you by Global Wallet EOOD.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Global Wallet EOOD is regulated by the&nbsp;National Revenue
              Agency&nbsp;of&nbsp;Bulgaria&nbsp;("NRAB").&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>This is a contract between you and:&nbsp;</p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Global Wallet EOOD ("BITmambo"), a private limited company
              incorporated in Bulgaria with company number&nbsp;207121280,
              whose registered office address is 2V&nbsp;Topli&nbsp;dol str.,
              Sofia&nbsp;1680;&nbsp;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              References in this Agreement to "bitmambo", "we", "our" or "us",
              are to Bitmambo, and references to "you" or "your" are to the
              person with whom&nbsp;Bitmambo&nbsp;enters into this
              Agreement.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              By signing up to use an account
              through bitmambo.com or&nbsp;instant.bitmambo.com, or
              any of our associated websites, application programming
              interfaces (“APIs”), or mobile applications (collectively the
              "Site"), you agree that you have read, understood, and
              accept&nbsp;all of&nbsp;the terms and conditions contained in
              this Agreement, as well as our Privacy Policy and Cookie
              Policy.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              We refer to the Digital Currency Services and Additional
              Services (all defined below) collectively as the "bitmambo
              Services", which can be accessed via the platform operated
              by&nbsp;bitmambo&nbsp;(the “bitmambo&nbsp;Platform”) (including
              the online platform which is accessible via the Site or at such
              location as may be prescribed by&nbsp;bitmambo&nbsp;from time to
              time). Each of the&nbsp;bitmambo&nbsp;Services is provided
              by&nbsp;bitmambo, as set out in clause 2 below.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You should be aware that the risk of loss in trading or holding
              Digital Currencies can be substantial. As with any asset, the
              value of Digital Currencies can go up or down and there can be a
              substantial risk that you lose money buying, selling, holding,
              or investing in digital currencies. Digital Currency Services
              are not currently regulated by the NRAB, or any other regulator
              in Bulgaria. You should carefully consider whether trading or
              holding Digital Currencies is suitable for you in light of your
              financial condition.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>1. ELIGIBILITY.&nbsp;</p>
          </div>
          <div>
            <p>
              To be eligible to use any of the&nbsp;bitmambo&nbsp;services,
              you must be at least 18 years old and reside in a country in
              which the relevant&nbsp;bitmambo&nbsp;Services are accessible.
              Please note that not all&nbsp;bitmambo&nbsp;services&nbsp;are
              available in every country.&nbsp;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>2. SERVICES.&nbsp;</p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>2.1&nbsp;Digital Currency Services.&nbsp;</p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              The following services (the "Digital Currency Services") may be
              provided to you by&nbsp;bitmambo:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) one or more hosted digital currency wallets
              (the "Digital Currency Wallets") enabling you to store, track,
              transfer, and manage your balances of certain supported digital
              currencies like Bitcoin or Ethereum (collectively "Digital
              Currency" or “Digital Currencies”); and&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) a Digital Currency exchange service enabling
              you to obtain prices for your purchases and sales of Digital
              Currencies, and (subject to certain restrictions) carry out any
              such purchases or sales on the Site (the “Digital Currency
              Exchange Service”).&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Digital Currency Services are not regulated by the Central Bank
              of Bulgaria.&nbsp;Bitmambo&nbsp;is not a regulated financial
              services provider.&nbsp;Bitmambo&nbsp;is based in, and provides
              its services from, Bulgaria.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              IMPORTANT NOTE:&nbsp;Bitmambo&nbsp;is not a regulated financial
              service provider and is not registered with or regulated or
              authorised by the NRAB or any other regulatory body in Bulgaria
              for financial services and so you will not be able to avail of
              regulatory protections associated with such regulated entities
              such as investor or deposit protection schemes in relation to
              the Digital Currency Services.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>2.2&nbsp;Additional&nbsp;Bitmambo&nbsp;Services.&nbsp;</p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              In addition to the core services (i.e. the Digital Currency
              Services), the following services ("Additional Services") may be
              made available by&nbsp;Bitmambo, or another member of
              the&nbsp;Bitmambo&nbsp;Group (as defined at Section 2.6 below)
              to users that fulfil certain eligibility criteria:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) an order book exchange platform for Digital
              Currencies (as further described in Appendix 4)
              (“bitmambo&nbsp;Pro”);&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) development applications provided
              by&nbsp;bitmambo&nbsp;(as further described in Appendix 6)
              (“Developer’s Tools”);&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              2.4&nbsp;Fees. You agree to be responsible for the payment of
              and pay all fees. A full list of fees
              for&nbsp;bitmambo&nbsp;services, as amended from time to time,
              can be found on the Site at the ‘Pricing and Fees Disclosures’
              and ‘Trading Fees’ pages, which shall form part of this
              Agreement.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              2.5&nbsp;Bitmambo&nbsp;Group. In this Agreement,
              “Bitmambo&nbsp;Group” means&nbsp;bitmambo&nbsp;and its corporate
              affiliates.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>3. ACCOUNT SETUP.&nbsp;</p>
          </div>
          <div>
            <p>
              3.1 Registration of&nbsp;Bitmambo&nbsp;Account. To use
              the&nbsp;bitmambo&nbsp;Services, you will need to register for
              a&nbsp;Bitmambo&nbsp;account (a "Bitmambo&nbsp;Account"), which
              includes the Digital&nbsp;Currency&nbsp;Wallet(s), by providing
              your details, including your name, email address and a password
              and accepting the terms of this Agreement. By using
              a&nbsp;Bitmambo&nbsp;Account, you agree and represent that you
              will use the&nbsp;bitmambo&nbsp;Services only for yourself, and
              not on behalf of any third party, unless you have obtained prior
              approval from&nbsp;Bitmambo&nbsp;in accordance with Section 3.2
              of this Agreement. Each customer may register only
              one&nbsp;Bitmambo&nbsp;account. You are fully responsible for
              all activity that occurs under your&nbsp;Bitmambo&nbsp;Account.
              We may, in our sole discretion, refuse to open
              a&nbsp;Bitmambo&nbsp;Account for you, or suspend or terminate
              any&nbsp;Bitmambo&nbsp;Accounts (including but not limited to
              duplicate accounts) or suspend or terminate the trading of
              specific Digital Currency in your account.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              3.2 Third party Access. If, to the extent permitted by Section
              4.10, you grant express permission to a Regulated Third Party to
              access or connect to your&nbsp;Bitmambo&nbsp;Account(s), either
              through the Regulated Third Party's product or service or
              through the Site, you acknowledge that granting permission to a
              Regulated Third Party to take specific actions on your behalf
              does not relieve you of any of your responsibilities under this
              Agreement. You are fully responsible for all acts or omissions
              of any Regulated Third Party with access to
              your&nbsp;Bitmambo&nbsp;Account(s) and any action of such
              Regulated Third Party shall&nbsp;be considered to be&nbsp;an
              action authorised by you. Further, you acknowledge and agree
              that you will not hold&nbsp;Bitmambo&nbsp;responsible for, and
              will indemnify&nbsp;Bitmambo&nbsp;from, any liability arising
              out of or related to any act or omission of any Regulated Third
              Party with access to your&nbsp;Bitmambo&nbsp;Account(s).&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You may change or remove permissions granted by you to a
              Regulated Third Party with respect to
              your&nbsp;Bitmambo&nbsp;Account(s) at any time through the tabs
              on the ‘API Key’ page on the Site.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              3.3 Identity Verification. You agree to provide us with the
              information we request (which we may request at any time deemed
              necessary) for the purposes of identity verification and the
              detection of money laundering, terrorist financing, fraud, or
              any other financial crime, including as set out in Appendix 2
              (Verification Procedures and Limits) and permit us to keep a
              record of such information. You will need to complete certain
              verification procedures before you are permitted to start using
              the&nbsp;Bitmambo&nbsp;Services and to access
              specific&nbsp;Bitmambo&nbsp;Services, including certain
              transfers of Digital Currency, and the limits that apply to your
              use of the&nbsp;Bitmambo&nbsp;Services may be altered as a
              result of information collected on an ongoing basis.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              The information we request may include (but is not limited to)
              personal information such as your name, residential address,
              telephone number, email address, date of birth, taxpayer
              identification number, government identification number,
              information regarding your bank account (such as the name of the
              bank, the account type, routing number, and account number)
              network status, customer type, customer role, billing type,
              mobile device identifiers (e.g. international mobile subscriber
              identity and international mobile equipment identity) and other
              subscriber status details, and any such information
              that&nbsp;Bitmambo&nbsp;is required to collect from time to time
              under applicable law.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You may also be required to undergo “Enhanced Due Diligence”
              (see Section 6.2 below), where&nbsp;Bitmambo&nbsp;may request
              that you submit additional information about yourself or your
              business, provide relevant records, and arrange for meetings
              with&nbsp;Bitmambo&nbsp;staff so that&nbsp;Bitmambo&nbsp;may,
              among other things, establish the source of your wealth and
              source of funds for any transactions carried out in the course
              of your use of&nbsp;Bitmambo&nbsp;Services.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              In providing us with this or any other information that may be
              required, you confirm that the information is true, accurate and
              complete, and you have not withheld any information that may
              influence&nbsp;Bitmambo’s&nbsp;evaluation of you for the
              purposes of your registration for a&nbsp;Bitmambo&nbsp;Account
              or the provision of&nbsp;Bitmambo&nbsp;Services to you. You
              undertake to promptly notify in writing and
              provide&nbsp;Bitmambo&nbsp;with information regarding any
              changes in circumstances that may cause any such information
              provided to become false, inaccurate or incomplete and also
              undertake to provide any other additional documents, records and
              information as may be required by&nbsp;Bitmambo&nbsp;and/or
              applicable law. You permit us to keep records of such
              information. We will treat this information in accordance with
              Section 11 (Data Protection).&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You authorise us to make enquiries, whether directly or through
              third parties, that we consider necessary to verify your
              identity or protect you and/or us against fraud or other
              financial crime, and to take action we reasonably deem necessary
              based on the results of such enquiries. When we carry out these
              enquiries, you acknowledge and agree that your personal
              information may be disclosed to credit reference and fraud
              prevention or financial crime agencies and that these agencies
              may respond to our enquiries in full. This is an identity check
              only and should have no adverse effect on your credit rating.
              Additionally, we may require you to wait some amount of time
              after completion of a transaction before permitting you to use
              further&nbsp;Bitmambo&nbsp;Services and/or before permitting you
              to engage in transactions beyond certain volume limits.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>5. DIGITAL CURRENCY SERVICES.&nbsp;</p>
          </div>
          <div>
            <p>
              5.1 In General. Your Digital Currency Wallet enables you to send
              Digital Currency to, and request, receive, and store Digital
              Currency from, other users or third parties outside
              the&nbsp;bitmambo&nbsp;Platform, by giving instructions through
              the Site (each such transaction is a "Digital Currency
              Transaction").&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              The Digital Currency Exchange Service enables you to buy Digital
              Currency on the&nbsp;Bitmambo&nbsp;Platform using:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A)&nbsp;Central bank issued currency (such as
              EUR,&nbsp;USD&nbsp;or GBP) supported by&nbsp;Bitmambo;
              and/or&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B)&nbsp;Other types of Digital Currency in your
              Digital Currency Wallet.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Similarly, when you sell Digital Currency on
              the&nbsp;Bitmambo&nbsp;Platform, you may elect to receive:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(C)&nbsp;Central bank issued currency (such as EUR
              or GBP) supported by&nbsp;Bitmambo; and/or&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(D)&nbsp;Other types of Digital Currency in your
              Digital Currency Wallet.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Bitmambo&nbsp;facilitates and enables the purchase and sale of
              Digital Currency between its customers on
              the&nbsp;Bitmambo&nbsp;Platform.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.2 Fiat Currency Transactions;&nbsp;&nbsp;you&nbsp;may purchase
              supported Digital Currency by linking a valid payment method to
              your Digital Currency Wallet. You authorise us to debit funds
              using your selected payment method(s) to complete your
              purchase.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Although we will attempt to deliver Digital Currency to you as
              promptly as possible, funds may be debited from your selected
              payment method before the status of your Digital Currency
              Transaction is shown as complete, and the Digital Currency is
              delivered to your Digital Currency Wallet. You may sell Digital
              Currency in exchange for fiat currency (such as EUR or GBP)
              supported by&nbsp;Bitmambo. In such circumstances, you authorise
              us to debit your Digital Currency Wallet and to send
              instructions to credit your selected payment method(s) in
              settlement of sell transactions. We will send these instructions
              as soon as reasonably possible.&nbsp;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.3 Transaction Fulfilment. We will make reasonable efforts to
              fulfil all purchases of Digital Currency, but in some
              circumstances, we may be unable to. If this is the case, we will
              notify you and seek your approval to re-attempt the purchase at
              the current Exchange Rate (as defined below).&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.4 Availability of Payment Methods. The availability of a
              method of payment depends on a number of factors including, for
              example, where you are located, the identification information
              you have provided to us, and limitations imposed by third party
              payment processors.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.5 Conversion Fees. Each purchase or sale of Digital Currency
              is subject to a fee (a "Conversion Fee"). The applicable
              Conversion Fee will be displayed to you on the Site prior to
              each transaction and is stated in each receipt we issue to you.
              We may adjust our Conversion Fees at any time. We will not
              process a transaction if the Conversion Fee and any other
              associated fees would, together, exceed the value of your
              transaction. A full list of&nbsp;Bitmambo&nbsp;fees can be found
              on our Pricing and Fees Disclosures page.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.6 Exchange Rates. Each purchase or sale of Digital Currency is
              also subject to the Exchange Rate for the given transaction. The
              "Exchange Rate" means the price of a given supported Digital
              Currency in fiat currency as quoted on the Site. The Exchange
              Rate is stated either as a "Buy Price" or as a "Sell Price",
              which is the price at which you may buy or sell Digital
              Currency, respectively.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You acknowledge that the Buy Price Exchange Rate may not be the
              same as the Sell Price Exchange Rate at any given time, and that
              we may add a margin or ‘spread’ to the quoted Exchange Rate. You
              agree to accept the Exchange Rate when you authorise a
              transaction. You can learn more
              about&nbsp;bitmambo&nbsp;Exchange Rates on our ‘Pricing and Fees
              Disclosures’ page on the Site. We do not guarantee the
              availability of any Exchange Rate. We do not guarantee that you
              will be able to buy and / or sell your Digital Currency on the
              open market at any&nbsp;particular price&nbsp;or time.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.7 Authorisations; Reversals; Cancellations. By clicking the
              ‘Buy’ or ‘Sell’ button on the Site, you are
              authorising&nbsp;Bitmambo&nbsp;to initiate the transaction at
              the quoted Buy Price or Sell Price and agree to any associated
              Conversion Fees and Exchange Fees and any other fees.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You cannot cancel, reverse, or change any transaction marked as
              complete or pending. If your payment is not successful or if
              your payment method has insufficient funds, you authorise us, in
              our sole discretion, either to cancel the transaction or to
              debit your other payment methods, including balances on your
              Digital Currency Wallets, in any amount necessary to complete
              the transaction. You are responsible for maintaining an adequate
              balance and/or sufficient credit limits&nbsp;in order
              to&nbsp;avoid overdraft, insufficient funds, or similar fees
              charged by your payment provider.&nbsp;Bitmambo&nbsp;reserves
              the right to suspend access to&nbsp;Bitmambo&nbsp;Services until
              such insufficient payment is addressed.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.8 Digital Currency Transactions. We will process Digital
              Currency Transactions in accordance with the instructions we
              receive from you. You should verify all transaction information
              prior to submitting instructions to us. We do not guarantee the
              identity of any user, receiver, requestee or other third party
              and we will have no liability or responsibility for ensuring
              that the information you provide is accurate and complete.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Digital Currency Transactions cannot be reversed once they have
              been broadcast to the relevant Digital Currency network. If you
              initiate a Digital Currency Transaction by entering the
              recipient's email address or mobile phone number and the
              recipient does not have an existing&nbsp;Bitmambo&nbsp;Account,
              we will invite the recipient to open
              a&nbsp;Bitmambo&nbsp;Account. If the recipient does not open
              a&nbsp;Bitmambo&nbsp;Account within 30 days, we will return the
              relevant Digital Currency to your Digital Currency Wallet.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              We may charge network fees (“miner fees”) to process a Digital
              Currency Transaction on your behalf. We will calculate the miner
              fees at our discretion, although we will always notify you of
              the miner fees at (or before) the time you authorise the Digital
              Currency Transaction. A full list of&nbsp;Bitmambo&nbsp;miner
              fees can be found on our ‘Pricing and Fees Disclosures’ page on
              the Site.&nbsp;&nbsp;Miner fees for each individual transaction
              will be disclosed to you at the time of purchase on the checkout
              page. When you or a third party sends Digital Currency to
              a&nbsp;Bitmambo&nbsp;Digital Currency Wallet from an external
              wallet not hosted on&nbsp;Bitmambo, the person initiating the
              transaction is solely responsible for executing the transaction
              properly, which may include, among other things, payment of
              miner fees&nbsp;in order for&nbsp;the transaction to be
              completed successfully. Non-payment of miner fees may cause your
              transaction to remain in a pending state outside
              of&nbsp;Bitmambo&nbsp;control and we are not responsible for
              delays or loss incurred&nbsp;as a result of&nbsp;an error in the
              initiation of the transaction and have no obligation to assist
              in the remediation of such transactions.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Once submitted to a Digital Currency network, a Digital Currency
              Transaction will be unconfirmed for&nbsp;a period of
              time&nbsp;pending sufficient confirmation of the transaction by
              the Digital Currency network. A Digital Currency Transaction is
              not complete while it is in a pending state. Digital Currency
              associated with Digital Currency Transactions that are in a
              pending state will be designated accordingly and will not be
              included in your Digital Currency Wallet balance or be available
              to conduct Digital Currency Transactions until confirmed by the
              network.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              We may also refuse to process or cancel any pending Digital
              Currency Transaction as required by law, regulation or any court
              or other authority to which&nbsp;Bitmambo&nbsp;is subject in any
              jurisdiction, for instance, if there is suspicion of money
              laundering, terrorist financing, fraud, or any other financial
              crime.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.9 Supported Digital Currencies. Our Digital Currency Services
              are available only in connection with those digital currencies
              that&nbsp;Bitmambo&nbsp;supports (“Supported Digital
              Currencies”), and this may change from time to time.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Under no circumstances should you attempt to use your Digital
              Currency Wallet to store, send, request, or receive digital
              currencies in any form that we do not support. We assume no
              responsibility or liability in connection with any attempt to
              use your Digital Currency Wallet for digital currencies that we
              do not support. You acknowledge and agree
              that&nbsp;Bitmambo&nbsp;bears no responsibility and is not
              liable for any unsupported asset that is sent to a wallet
              associated with your&nbsp;Bitmambo&nbsp;Account. If you send an
              unsupported asset to a wallet associated with
              your&nbsp;Bitmambo&nbsp;Account, then you will lose that
              asset.&nbsp;Bitmambo&nbsp;does not evaluate the authenticity,
              safety, or security of unsupported assets. You acknowledge and
              agree that&nbsp;Bitmambo&nbsp;is not liable for any loss
              incurred during the recovery attempt or subsequent use of the
              recovered asset.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              If you have any questions about which Digital Currencies we
              currently support, please visit https://help.Bitmambo.com.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.10 Ending support of a Digital
              Currency.&nbsp;Bitmambo&nbsp;may in its sole discretion
              terminate support for any Digital
              Currency.&nbsp;Bitmambo&nbsp;will provide you no less than 10
              days notice (unless a shorter period is required by applicable
              law or a regulatory authority) via email to the email address
              associated with your&nbsp;Bitmambo&nbsp;account to announce the
              end of such support. If you do not sell or send such Digital
              Currency off platform during this
              time,&nbsp;Bitmambo&nbsp;reserves the right to withdraw such
              Digital Currencies from your account and credit
              your&nbsp;Bitmambo&nbsp;account with the market value of a
              supported Digital Currency or a fiat currency (which
              denomination will be selected in our reasonable
              discretion).&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.13 Supplemental Protocols Excluded. Unless specifically
              announced on the Site or via an official public statement
              of&nbsp;Bitmambo, or otherwise as set forth in this Agreement,
              Supported Digital Currencies excludes all other protocols and/or
              functionality which supplement or interact with the Supported
              Digital Currency. This exclusion includes but is not limited
              to:&nbsp;metacoins,&nbsp;colored&nbsp;coins, side chains, or
              other derivative, enhanced, or forked protocols, tokens, or
              coins or other functionality, such as staking, protocol
              governance, and/or any smart contract functionality, which may
              supplement or interact with a Supported Digital Currency
              (collectively, “Supplemental Protocols”). Do not use
              your&nbsp;Bitmambo&nbsp;Account to attempt to receive, request,
              send, store, or engage in any other type of transaction or
              functionality involving any such Supplemental Protocols, as
              the&nbsp;bitmambo&nbsp;Platform is not configured to detect,
              secure, or process these transactions and functionalities. Any
              attempted transactions in such items will result in loss of the
              item.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You acknowledge and agree that other than as set forth in this
              Agreement, Supplemental Protocols are excluded from Supported
              Digital Currency and that&nbsp;bitmambo&nbsp;has no liability
              for any losses related to Supplemental Protocols.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.14 Operation of Digital Currency Protocols. We do not own or
              control the underlying software protocols which govern the
              operation of Digital Currencies supported on our platform.
              Generally, the underlying protocols are ‘open source’&nbsp;and
              anyone can use, copy, modify, and distribute them.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              We assume no responsibility for the operation of the
              underlying&nbsp;protocols&nbsp;and we are not able to guarantee
              the functionality or security of network operations. You
              acknowledge and accept the risk that underlying software
              protocols relating to any Digital Currency you store in your
              Digital Currency Wallet may change.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              In particular, the underlying protocols are likely to be subject
              to sudden changes in operating rules (including “forks”). Any
              such material operating changes may materially affect the
              availability, value, functionality, and/or the name of the
              Digital Currency you store in your Digital Currency
              Wallet.&nbsp;Bitmambo&nbsp;does not control the timing and
              features of these material operating changes. It is your
              responsibility to make yourself aware of upcoming operating
              changes and you must carefully consider publicly available
              information and information that may be provided
              by&nbsp;Bitmambo&nbsp;in determining whether to continue to
              transact in the affected Digital Currency using
              your&nbsp;Bitmambo&nbsp;Account. In the event of any such
              operational change,&nbsp;Bitmambo&nbsp;reserves the right to
              takes such steps as may be necessary to protect the security and
              safety of assets held on the&nbsp;Bitmambo&nbsp;Platform,
              including temporarily suspending operations for the involved
              digital currency(ies), and other necessary
              steps;&nbsp;Bitmambo&nbsp;will use its best efforts to provide
              you notice of its response to any material operating change;
              however, such changes are outside
              of&nbsp;Bitmambo’s&nbsp;control and may occur without notice
              to&nbsp;Bitmambo.&nbsp;Bitmambo’s&nbsp;response to any material
              operating change is subject to its sole discretion and includes
              deciding not to support any new digital currency, fork, or other
              actions.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You acknowledge and accept the risks of operating changes to
              Digital Currency protocols and agree that&nbsp;Bitmambo&nbsp;is
              not responsible for such operating changes and not liable for
              any loss of value you may experience&nbsp;as a result
              of&nbsp;such changes in operating rules. You acknowledge and
              accept that&nbsp;Bitmambo&nbsp;has sole discretion to determine
              its response to any operating change and that we have no
              responsibility to assist you with unsupported currencies
              or&nbsp;protocols&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.15 Fungibility of Certain Digital Currencies. &nbsp;You
              acknowledge and agree that&nbsp;Bitmambo&nbsp;may hold Supported
              Digital Currencies in your Digital Currency Wallets in a variety
              of different ways, including across multiple blockchain
              protocols, such as layer two networks, alternative layer one
              networks, or side chains. In connection with its holding of
              Supported Digital Currencies in your Digital Currency
              Wallets,&nbsp;Bitmambo&nbsp;may transfer such Digital
              Currencies&nbsp;off of&nbsp;the primary blockchain protocol and
              hold such Digital Currencies on shared blockchain addresses,
              controlled by&nbsp;Bitmambo, on alternative blockchain protocols
              in forms compatible with such protocols. &nbsp;You agree that
              all forms of the same Digital Currencies that are held and made
              available across multiple blockchain protocols may be treated as
              fungible and the equivalent of each other, without regard to (a)
              whether any form of such Digital Currencies is wrapped or (b)
              the blockchain protocol on which any form of such Digital
              Currencies is stored.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.16 Digital Currency Storage &amp; Transmission Delays.
              The&nbsp;Bitmambo&nbsp;Group securely stores Digital Currency
              private keys, in a combination of online and offline storage.
              Our security protocols may delay the initiation or crediting of
              a Digital Currency Transaction.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.17 Third party Payments. We have no control over, or liability
              for, the delivery, quality, safety, legality or any other aspect
              of any goods or services that you may purchase or receive from,
              or sell or transfer to, any third party (including other users
              of the Digital Currency Services). We are not responsible for
              ensuring that a&nbsp;third party&nbsp;buyer or a seller you
              transact with will complete the transaction or is authorised to
              do so. If you experience a problem with any goods or
              services&nbsp;purchased from, or sold to, any third party using
              Digital Currency transferred using the Digital Currency
              Services, or if you have a dispute with such third party, you
              should resolve the dispute directly with that third party.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              If you believe a third party has behaved in a fraudulent,
              misleading, or inappropriate manner, or if you cannot adequately
              resolve a dispute with a third party, you may
              notify&nbsp;Bitmambo&nbsp;Support at:&nbsp;support@Bitmambo.com
              so that we may consider what action to take, if any.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.19 Digital Currency Title. All Digital Currencies held in your
              Digital Currency Wallet are assets held by
              the&nbsp;Bitmambo&nbsp;Group for your benefit on a custodial
              basis. Among other things, this means:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) Title to Digital Currency shall&nbsp;at all
              times&nbsp;remain with you and shall not transfer to any company
              in the&nbsp;Bitmambo&nbsp;Group. As the owner of Digital
              Currency in your Digital Currency Wallet, you shall bear all
              risk of loss of such Digital Currency. No company within
              the&nbsp;Bitmambo&nbsp;Group shall have any liability for
              fluctuations in the fiat currency value of Digital Currency held
              in your Digital Currency Wallet.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) None of the Digital Currencies in your Digital
              Currency Wallet are the property of, or shall or may be loaned
              to,&nbsp;Bitmambo;&nbsp;Bitmambo&nbsp;does not represent or
              treat assets in a user’s Digital Currency Wallets as belonging
              to&nbsp;Bitmambo.&nbsp;Bitmambo&nbsp;may not grant a security
              interest in the Digital Currency held in your Digital Currency
              Wallet. Except as required by a facially valid court order, or
              except as provided herein,&nbsp;Bitmambo&nbsp;will not sell,
              transfer, loan, hypothecate, or otherwise alienate Digital
              Currency in your Digital Currency Wallet unless instructed by
              you or compelled by a court of competent jurisdiction to do
              so.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(C) You control the Digital Currencies held in your
              Digital Currency Wallet. At any time, subject to outages,
              downtime, and other applicable policies, you may withdraw your
              Digital Currency by sending it to a different blockchain address
              controlled by you or a third party.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(D) In order to more securely hold customer Digital
              Currency, the&nbsp;Bitmambo&nbsp;Group may use shared blockchain
              addresses, controlled by a member of
              the&nbsp;Bitmambo&nbsp;Group, to hold Digital Currencies held on
              behalf of customers. Customers’ Digital Currency is segregated
              from the&nbsp;Bitmambo&nbsp;Group’s own Digital Currency or
              funds by way of separate ledger accounting entries for customer
              and&nbsp;Bitmambo&nbsp;Group accounts. Notwithstanding the
              foregoing, the&nbsp;Bitmambo&nbsp;Group shall not have any
              obligation to use different blockchain addresses to store
              Digital Currencies owned by you and Digital Currencies owned by
              other customers or by the&nbsp;Bitmambo&nbsp;Group.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Bitmambo&nbsp;is under no obligation to issue any replacement
              Digital Currency&nbsp;in the event that&nbsp;any Digital
              Currency, password or private key is lost, stolen,
              malfunctioning, destroyed or otherwise inaccessible.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              5.20 Advanced Trading.&nbsp;Bitmambo&nbsp;offers and, eligible
              users may access, an order book for various Digital Currency and
              Fiat Currency trading pairs (each an “Order Book”) on
              https://www.bitmambo.com/exchange. See
              your&nbsp;Bitmambo&nbsp;Account to see what Order Books are
              available within&nbsp;the Exchange. &nbsp;Bitmambo&nbsp;does not
              offer Advanced Trading to customers in all jurisdictions. By
              accessing&nbsp;Exchange&nbsp;or the&nbsp;Bitmambo&nbsp;API
              for&nbsp;Exchange&nbsp;Trading, you accept and agree to be bound
              by the Trading Rules.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              (A) Trading Fees. By placing an order on Advanced Trading, you
              agree to pay all applicable fees and
              authorise&nbsp;Bitmambo&nbsp;to automatically deduct fees
              directly from your account. Trading Fees are set forth in the
              Trading Rules.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              (B) Withdrawal Fees.&nbsp;Bitmambo&nbsp;may charge a fee on
              certain Fiat Currency deposit or withdrawal methods
              (e.g.&nbsp;bank wire). DEPOSITS AND WITHDRAWALS MAY BE SUBJECT
              TO LIMITS.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              (C) Trading Account Use. You may not sell, lease, furnish, or
              otherwise permit or provide access to your Trading Account to
              any other entity or to any individual that is not your employee
              or agent. You accept full responsibility for your employees' or
              agents' use of Advanced Trading, whether such use is directly
              through&nbsp;Bitmambo&nbsp;or by other means, such as those
              facilitated through API keys, and/or applications which you may
              authorise. You understand and agree that you are responsible for
              any and all orders, trades, and other instructions entered into
              Advanced Trading including identifiers, permissions, passwords,
              and security codes associated with your Account.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              (D) Suspension and Cancellation. In the event that your Account
              is suspended or terminated, we will immediately cancel all open
              orders associated with your Account, block all withdrawals and
              bar the placing of further orders until resolution or Account
              cancellation.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>6. TRANSACTIONS LIMITS AND ENHANCED DUE DILIGENCE.&nbsp;</p>
          </div>
          <div>
            <p>
              6.1 Transactions Limits. The use of
              all&nbsp;Bitmambo&nbsp;Services is subject to a limit on the
              volume, stated in EUR, GBP or other fiat currency or Digital
              Currency, you may transact or transfer in a given period
              (e.g.&nbsp;daily). Please see Appendix 2 (Verification
              Procedures and Limits) for further details. To view your limits,
              login to your&nbsp;Bitmambo&nbsp;Account and
              visit&nbsp;https://www.bitmambo.com/verifications. Your
              transaction limits may vary depending on your payment method,
              verification steps you have completed, and other factors. We
              reserve the right to change applicable limits as we deem
              necessary. If you wish to raise your limits beyond the posted
              amounts, you may submit a request at
              https://help.bitmambo.com.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              6.2 Enhanced Due Diligence. We may require you to submit
              additional information about yourself or your business, provide
              records, and arrange for meetings with&nbsp;Bitmambo&nbsp;staff
              if you wish to raise your limits ("Enhanced Due Diligence"). We
              reserve the right to charge you costs and fees
              associated&nbsp;with such Enhanced Due Diligence although if we
              intend to do so, we will notify you in advance so that you can
              decide whether you wish to proceed with the request. In our
              discretion, we may refuse to raise your limits, or we may lower
              your limits at a subsequent time even if you have completed
              Enhanced Due Diligence.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>7. SUSPENSION, TERMINATION, AND CANCELLATION.&nbsp;</p>
          </div>
          <div>
            <p>
              7.1 Suspension, Termination and Cancellation. We may: (a) refuse
              to complete, or place on hold, block, cancel or reverse a
              transaction you have authorised (even after funds have been
              debited from your&nbsp;Bitmambo&nbsp;Account), (b) suspend,
              restrict, or terminate your access to any or all of
              the&nbsp;Bitmambo&nbsp;Services, and/or (c) deactivate or cancel
              your&nbsp;Bitmambo&nbsp;Account with immediate effect for any
              reason, including but not limited to where:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) we reasonably believe that we need to do so in
              order to protect our&nbsp;reputation;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) we are, in our reasonable opinion, required to
              do so by applicable law, regulation or any court or other
              authority to which we are subject in any jurisdiction;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(C) we reasonably suspect you of acting in breach
              of this Agreement;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(D) we reasonably suspect you to have breached our
              ‘Behaviour Policy’ or our ‘Policy on Prohibited Use, Prohibited
              Businesses and Conditional Use’ (as set out in Appendix
              1);&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(E) we have concerns that a transaction is
              erroneous or about the security of
              your&nbsp;bitmambo&nbsp;Account or we suspect that
              the&nbsp;bitmambo&nbsp;Services are being used in a fraudulent
              or unauthorised&nbsp;manner;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(F) we suspect money laundering, terrorist
              financing, fraud, or any other financial&nbsp;crime;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(G) use of your&nbsp;Bitmambo&nbsp;Account is
              subject to any pending litigation, investigation, or government
              proceeding and / or we perceive a heightened risk of legal or
              regulatory non-compliance associated with
              your&nbsp;Bitmambo&nbsp;Account activity; and / or&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(H) you take any action that may circumvent our
              controls such as opening multiple&nbsp;Bitmambo&nbsp;Accounts or
              abusing promotions which we may offer from time to time.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              We may also refuse to complete or block, cancel or reverse a
              transaction you have authorised where there is insufficient
              Digital Currency in your Digital Currency Wallet to cover the
              transaction and (where applicable) associated fees at the time
              that we receive notification of the transaction or if your
              credit or debit card or any other valid payment method linked to
              your&nbsp;Bitmambo&nbsp;Account or Digital Currency Wallet is
              declined.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              7.2 If we refuse to complete a transaction and / or suspend,
              restrict or close your&nbsp;Bitmambo&nbsp;Account, and / or
              terminate your use of&nbsp;Bitmambo&nbsp;Services, we will
              (unless it would be unlawful for us to do so) provide you with
              notice of our actions and the reasons for refusal, suspension or
              closure, and where appropriate, with the procedure for
              correcting any factual errors that led to the refusal,
              suspension or closure of your&nbsp;Bitmambo&nbsp;Account. In the
              event that we refuse to complete a transaction and / or suspend
              your&nbsp;Bitmambo&nbsp;Account we will lift the suspension or
              complete the transaction as soon as reasonably practicable once
              the reasons for refusal and / or suspension no longer exist.
              However, we are under no obligation to allow you to reinstate a
              transaction at the same price or on the same terms as the
              suspended, reversed or cancelled transaction.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              We may suspend, restrict, or terminate your access to any or all
              of the&nbsp;Bitmambo&nbsp;Services and/or deactivate or cancel
              your&nbsp;Bitmambo&nbsp;Account, without reason by giving you
              two months’ notice. You acknowledge that our decision to take
              certain actions, including limiting access to, suspending, or
              closing your&nbsp;Bitmambo&nbsp;Account, may be based on
              confidential criteria that are essential for the purposes of our
              risk management and security protocols. You agree
              that&nbsp;Bitmambo&nbsp;is under no obligation to disclose the
              details of its risk management and security procedures to
              you.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              7.3 Consequences of Termination or Suspension. On termination of
              this Agreement for any reason, unless prohibited by applicable
              law or by any court or other order to
              which&nbsp;Bitmambo&nbsp;is subject in any jurisdiction, you are
              permitted to access your&nbsp;Bitmambo&nbsp;Account&nbsp;for
              ninety (90) days thereafter for the purposes of transferring
              Digital Currency out of your Digital Currency Wallet(s) and/or
              out of the&nbsp;Bitmambo&nbsp;Platform; and/or&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You are not permitted to use the&nbsp;Bitmambo&nbsp;Services or
              your&nbsp;Bitmambo&nbsp;Account for any other purposes during
              these periods and we may, at our discretion, limit the
              functionality of the&nbsp;Bitmambo&nbsp;Platform or access to
              the Site for you accordingly.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              If we suspend or close your&nbsp;Bitmambo&nbsp;Account or
              terminate your use of&nbsp;Bitmambo&nbsp;Services for any
              reason, we reserve the right to require you to re-complete the
              procedures outlined at Section 3.3 (Identity Verification)
              before permitting you to transfer or withdraw Digital Currency.
              You may cancel your&nbsp;Bitmambo&nbsp;Account at any time by
              withdrawing all balances in your Digital Currency Wallet(s).
              &nbsp;You will not be charged for cancelling
              your&nbsp;Bitmambo&nbsp;Account, although you will be required
              to pay any outstanding amounts owed to us. You authorise us to
              cancel or suspend any pending transactions at the time of
              cancellation.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>8. LIABILITY&nbsp;</p>
          </div>
          <div>
            <p>
              8.1 Release of&nbsp;Bitmambo. If you have a dispute with one or
              more users of the&nbsp;Nitmambo&nbsp;Services (other
              than&nbsp;Bitmambo), you agree that neither we nor our
              affiliates or service providers, nor any of our respective
              officers, directors, agents, joint venturers, employees and
              representatives, will be liable for any claims, demands and
              damages (actual and consequential, direct or indirect) of any
              kind or nature arising out of or in any way connected with such
              disputes.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              8.2 Indemnification. You agree to indemnify us, our affiliates
              and service providers, and each of our, or their, respective
              officers, directors, agents, employees and representatives, in
              respect of any costs (including attorneys' fees and any fines,
              fees or penalties imposed by any regulatory authority) that have
              been reasonably incurred in connection with any claims, demands
              or damages arising out of or related to your breach and / or our
              enforcement of this Agreement (including without limitation your
              breach of our ‘Behaviour Policy’ or our ‘Policy on Prohibited
              Use, Prohibited Businesses and Conditional Use’ (as set out in
              Appendix 1)) or your violation of any law, rule or regulation,
              or the rights of any third party.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              8.3 Limitations of Liability.&nbsp;Bitmambo’s&nbsp;total
              aggregate liability to you for any individual claim or series of
              connected claims for losses, costs,&nbsp;liabilities&nbsp;or
              expenses which you may suffer arising out of, or in connection
              with, any breach by&nbsp;Bitmambo&nbsp;of this Agreement shall
              be limited to a maximum aggregate value of the combined value of
              the Digital Currency&nbsp;on&nbsp;your Digital Currency Wallet
              at the time of the relevant breach by&nbsp;Bitmambo. Where we
              are considering a specific claim relating to a specific
              transaction this sum shall be further limited to the purchase /
              sale amount (as relevant) of the transaction in dispute.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              8.4 Limitation of loss. In addition to the liability cap at
              Section 8.3 (Limitations of Liability) above, in no event shall
              we, our affiliates or service providers, or any of our or their
              respective officers, directors, agents, employees or
              representatives, be liable for any of the following types of
              loss or damage arising under or in connection with this
              Agreement or otherwise:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) any loss of profits or loss of expected revenue
              or gains, including any loss of anticipated trading profits and
              / or any actual or hypothetical trading losses, whether direct
              or indirect, even if we are advised of or knew or should have
              known of the possibility of the same. This means, by way of
              example only (and without limiting the scope of the preceding
              sentence), that if you claim that we failed to process a Digital
              Currency Transaction properly, your damages are limited to no
              more than the combined value of the supported Digital Currency
              at issue in the transaction, and that you may not recover for
              any "loss" of anticipated trading profits or for any actual
              trading losses made as a result of the failure to buy or sell
              the Digital Currency;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) any loss of, or damage to, reputation or
              goodwill; any loss of business or opportunity, customers or
              contracts; any loss or waste of overheads, management or other
              staff time; or any other loss of revenue or actual or
              anticipated savings, whether direct or indirect, even if we are
              advised of or knew or should have known of the possibility of
              the same;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(C) any loss of use of
              hardware,&nbsp;software&nbsp;or data and / or any corruption of
              data; including but not limited to any losses or damages arising
              out of or relating to any inaccuracy, defect or omission of
              digital currency price data; any error or delay in the
              transmission of such data; and / or any interruption in any such
              data; and&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(D) any loss or damage whatsoever which does not
              arise directly as a result of our breach of this Agreement
              (whether or not you are able to prove such loss or
              damage).&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              8.5 Applicable law. The limitation of liability in this Section
              8 (Liability) is subject to any obligations that we have under
              applicable law and regulation, including our obligation to
              exercise reasonable care and skill in our provision of
              the&nbsp;Bitmambo&nbsp;Services. Nothing in this Agreement shall
              limit our liability resulting from our fraud or fraudulent
              misrepresentation, gross negligence, deliberate misconduct, for
              death or personal injury resulting from either our or our
              subcontractors’ negligence.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              8.6 No Warranties. The&nbsp;Bitmambo&nbsp;Services,
              the&nbsp;Bitmambo&nbsp;Platform and the Site are provided on an
              "as is" and "as available" basis, with no further promises made
              by us around availability of the&nbsp;Bitmambo&nbsp;Services.
              Specifically, we do not give any implied warranties of title,
              merchantability, fitness for a particular purpose and/or
              non-infringement. We do not make any promises that access to the
              Site, any of the&nbsp;Bitmambo&nbsp;Services, or any of the
              materials contained therein, will be continuous, uninterrupted,
              timely, or error-free.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              We make no representations about the accuracy,
              order,&nbsp;timeliness&nbsp;or completeness of historical
              Digital Currency price data available on the Site. Any
              materials, information, view, opinion, projection or estimate
              presented via the Site is made available
              by&nbsp;Bitmambo&nbsp;for informational purposes only, and is
              subject to change without notice. You must make your own
              assessment of the relevance, timeliness, accuracy, adequacy,
              commercial value, completeness and reliability of the materials,
              information, view opinion, projection or estimate provided on
              the Site and/or the Site. Accordingly, no warranty whatsoever is
              given by&nbsp;Bitmambo&nbsp;and no liability whatsoever is
              accepted by&nbsp;Bitmambo&nbsp;for any loss arising whether
              directly or indirectly as a result of you acting on any
              materials, information, view, opinion, projection or estimate
              provided in or made available through the Site and/or the
              Site.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              The&nbsp;Bitmambo&nbsp;Services,&nbsp;Bitmambo&nbsp;Platform and
              Site are not intended to provide specific
              investment,&nbsp;tax&nbsp;or legal advice or to make any
              recommendations about the suitability of any investments or
              products for any particular investor. You should seek your own
              independent financial, legal, regulatory, tax or other advice
              before making an investment in the investments or products. In
              the event that you choose not to seek advice from a relevant
              adviser, you should consider whether the investment or product
              is suitable for you.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              We will make reasonable efforts to ensure that Digital Currency
              Transactions, requests for debits and credits involving Digital
              Currency Wallets, E-Money Wallets, bank accounts, credit and
              debit cards are processed in a timely manner
              but&nbsp;Bitmambo&nbsp;makes no representations or warranties
              regarding the amount of time needed to complete processing which
              is dependent upon many factors outside of our control. We will
              make reasonable efforts to ensure that requests for electronic
              debits and credits involving bank accounts, credit cards, and
              cheque issuances are processed in a timely manner, but we make
              no representations or warranties regarding the amount of time
              needed to complete processing which is dependent upon many
              factors outside of our control.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Except for the express statements set forth in this Agreement,
              you hereby acknowledge and agree that you have not relied upon
              any other statement or understanding, whether written or oral,
              with respect to your use and access of
              the&nbsp;Bitmambo&nbsp;Services and Site.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              8.7 No Liability for Breach. We are not liable for any breach of
              the Agreement, including delays, failure in performance or
              interruption of service, where they arise directly or indirectly
              from abnormal and unforeseeable circumstances beyond our
              control, the consequences of which would have been unavoidable
              despite all effects to the contrary, nor are we liable where the
              breach is due to the application of mandatory legal rules.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>9. SITE AVAILABILITY AND ACCURACY&nbsp;</p>
          </div>
          <div>
            <p>
              9.1 Access &amp; Availability. Access
              to&nbsp;Bitmambo&nbsp;Services may become degraded or
              unavailable during times of significant volatility or volume.
              This could result in limitations on access to
              your&nbsp;Bitmambo&nbsp;Account or
              the&nbsp;Bitmambo&nbsp;Services, including the inability to
              initiate or complete transactions and may also lead to support
              response time delays.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) although we strive to provide you with
              excellent service, we do not guarantee that the Site or
              other&nbsp;Bitmambo&nbsp;Services will be available without
              interruption and we do not guarantee that any order will be
              executed, accepted, recorded, or remain open or that
              your&nbsp;Bitmambo&nbsp;Account will be accessible; and&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) please note that our customer support response
              times may be delayed, including during times of significant
              volatility or volume, especially for non-trust and safety
              issues.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Under no circumstances shall&nbsp;Bitmambo&nbsp;be liable for
              any alleged damages arising from service interruptions, delays
              in processing transactions, or lack of timely response
              from&nbsp;Bitmambo&nbsp;customer support.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              9.2 Website Accuracy. Although we intend to provide accurate and
              timely information on the Site, the Site (including, without
              limitation, the Content (as defined below)) may not always be
              entirely accurate,&nbsp;complete&nbsp;or current and may also
              include technical inaccuracies or typographical errors.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              In an effort to&nbsp;continue to provide you with as complete
              and accurate information as possible, information may, to the
              extent permitted by applicable law, be changed or updated from
              time to time without notice, including without limitation
              information regarding our policies, products and services.
              Accordingly, you should verify all information before relying on
              it, and all decisions based on information contained on the Site
              are your sole responsibility and we shall have no liability for
              such decisions.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Links to third party materials (including without limitation any
              websites) may be provided as a convenience but are not
              controlled by us. You acknowledge and agree that we are not
              responsible for any aspect of the information, content, or
              services contained in any such&nbsp;third party&nbsp;materials
              accessible or linked to from the Site.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              10. CUSTOMER FEEDBACK, QUERIES, COMPLAINTS, AND DISPUTE
              RESOLUTION&nbsp;
            </p>
          </div>
          <div>
            <p>
              10.1 Contact&nbsp;Bitmambo. If you have any feedback, questions,
              or complaints, contact us via our ‘Customer Support’ webpage at
              https://help.bitmambo.com. When you contact&nbsp;us&nbsp;please
              provide us with your name, email address, and any other
              information that we may need to identify you,
              your&nbsp;Bitmambo&nbsp;Account, and the transaction on which
              you have feedback, questions, or complaints.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              10.2 Complaints. If you have a dispute with&nbsp;Bitmambo, you
              agree to contact our support team in the first instance to
              attempt to resolve such dispute. If we cannot resolve the
              dispute through our support team, you and we agree to use the
              complaints process set out in this Section 10.2. You agree to
              use this process before commencing any action as set out in
              Section 10.4. If you do not follow the procedures set out in
              this Section 10.2 before pursuing action under Section 10.4, we
              shall have the right to ask the relevant court/authority to
              dismiss your action/application unless and until you complete
              the following steps:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              In the event of a complaint which has not been resolved through
              your contact with&nbsp;Bitmambo&nbsp;Support, please use our
              complaint form to set out the cause of your complaint, how you
              would like us to resolve the complaint and any other information
              you believe to be relevant. The complaint form can be found on
              the&nbsp;Bitmambo&nbsp;support pages, help.Bitmambo.com or can
              be requested from&nbsp;Bitmambo&nbsp;Customer Support. We will
              acknowledge receipt of your complaint form after you submit it.
              A customer complaints officer (an "Officer") will consider your
              complaint. The Officer will consider your complaint without
              prejudice based on the information you have provided, and any
              information provided by&nbsp;Bitmambo.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Within&nbsp;45&nbsp;business days of our receipt of your
              complaint the Officer will address all points raised in your
              complaint by sending you an email ("Resolution Notice") in which
              the Officer will: (i) offer to&nbsp;resolve your complaint in
              the way you requested; (ii) make a determination rejecting your
              complaint and set out the reasons for the rejection; or (iii)
              offer to resolve your complaint with an alternative solution. In
              certain circumstances, if the Officer is unable to respond to
              your complaint within&nbsp;45&nbsp;business days, the Officer
              will (unless prohibited by applicable law) send you a holding
              reply indicating the reasons for a delay in answering your
              complaint and specifying the deadline by which the Officer will
              respond to your complaint (which will be no later
              than&nbsp;90&nbsp;business days from our receipt of your
              complaint).&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Any offer of resolution made to you will only become binding on
              us if accepted by you. An offer of resolution will not
              constitute any admission by us of any wrongdoing or liability
              regarding the subject matter of the complaint.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              10.3 If we have not been able to resolve your complaint via the
              complaint process set out in Section 10.2 above, you may
              escalate your complaint via the dispute processes set out in
              Sections 10.4&nbsp;below as applicable to you.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              10.4 Both you and we agree that we shall not commence any of the
              dispute processes set out at Sections 10.4 below in relation to
              the whole or part of your complaint until the complaint process
              set out in Section 10.2 has been completed, although nothing in
              Section 10.2 or in this Section 10.4 shall be construed as
              preventing either party from seeking conservatory or similar
              interim relief in any court of competent jurisdiction:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              For complaints or disputes arising out of or in connection with
              this Agreement or the provision of&nbsp;Bitmambo&nbsp;Services,
              the&nbsp;Bitmambo&nbsp;Platform or the Site, that cannot be
              resolved via the complaint process set out in Section 10.2
              above, you submit to the non-exclusive jurisdiction of the
              courts of Bulgaria without prejudice to any mandatory rights
              available to consumers (being individuals not engaged in conduct
              related to their trade, business or profession, “Consumers”) to
              commence proceedings against&nbsp;Bitmambo&nbsp;before the
              courts of the jurisdiction in which they are domiciled.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>11. DATA PROTECTION.&nbsp;</p>
          </div>
          <div>
            <p>
              11.1 Personal Data. You acknowledge that we may process personal
              data in relation to you (if you are an individual), and personal
              data that you have provided (or in the future provide) to us in
              relation to your employees and other associates, or other
              individuals (if you are not an individual), in connection with
              this Agreement, or the&nbsp;Bitmambo&nbsp;Services. We will
              process this personal data in accordance with the Privacy
              Policy, which shall form part of this Agreement. Accordingly,
              you represent and warrant that:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) your disclosure to us of any personal data
              relating to individuals other than yourself was or will be made
              in accordance with all applicable data protection and data
              privacy laws, and those data are accurate, up to date and
              relevant when disclosed;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) before providing any such personal data to us,
              you acknowledge that you have read and understood our Privacy
              Policy, a copy of which is available here:&nbsp;Privacy Policy,
              and, in the case of personal data relating to an individual
              other than yourself, have (or will at the time of disclosure
              have) provided the individual with a copy of, or directed the
              individual towards a webpage containing that Privacy Policy (as
              amended from time to time); and&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(C) if from time to time we provide you with a
              replacement version of the Privacy Policy, you will promptly
              read that notice and provide updated copies of the Privacy
              Policy to, or re-direct towards a webpage containing the updated
              Privacy Policy, any individual whose personal data you have
              provided to us.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>12. SECURITY&nbsp;</p>
          </div>
          <div>
            <p>
              12.1 Password Security.&nbsp;In order
              to&nbsp;access&nbsp;Bitmambo&nbsp;Services, you will be required
              to create or will be given security details, including a
              username and password. You are responsible for keeping the
              electronic device through which you
              access&nbsp;Bitmambo&nbsp;Services safe and maintaining adequate
              security and control of&nbsp;any and all&nbsp;security details
              that you use to access the&nbsp;Bitmambo&nbsp;Services. This
              includes taking all reasonable steps to avoid the loss, theft or
              misuse of such electronic device and ensuring that such
              electronic device is both encrypted and password
              protected.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Any loss or compromise of your electronic device or your
              security details may result in unauthorised access to
              your&nbsp;Bitmambo&nbsp;Account by third-parties and the loss or
              theft of any Digital Currency and/or funds held in
              your&nbsp;Bitmambo&nbsp;Account and any associated accounts,
              including your linked bank account(s) and credit card(s). You
              must keep your security details safe at all times. For example,
              you should not write them down or otherwise make them visible to
              others.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You should never allow remote access or share your computer
              and/or computer screen with someone else when you are logged on
              to your&nbsp;Bitmambo&nbsp;Account.&nbsp;Bitmambo&nbsp;will
              never under any circumstances ask you for your IDs, passwords,
              or 2-factor authentication codes or to screen share or otherwise
              seek to access your computer or account. You should not provide
              your details to any third party for the purposes of remotely
              accessing your account unless specifically authorised in
              accordance with Sections 3.2. Always log into
              your&nbsp;Bitmambo&nbsp;Account through the Site to review any
              transactions or required actions if you have any uncertainty
              regarding the authenticity of any communication or notice.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              We assume no responsibility for any loss that you may sustain
              due to compromise of account login credentials due to no fault
              of&nbsp;Bitmambo&nbsp;and/or failure to follow the requirements
              set out in this Section&nbsp;12.1, or&nbsp;follow or act on any
              notices or alerts that we may send to you.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              12.2 Authentication and Verification. In order to
              access&nbsp;Bitmambo&nbsp;Services users are required to provide
              an email address and create a
              password.&nbsp;Bitmambo&nbsp;offers two-factor authentication
              via a user’s mobile device (Short Message Service (“SMS”) or a
              supported Time-based One Time Password application.&nbsp;A
              verified phone number is required to enable two-factor
              authentication via SMS. Users are responsible for keeping
              electronic devices through which&nbsp;Bitmambo&nbsp;Services are
              accessed safe and maintaining adequate security and control
              of&nbsp;any and all&nbsp;security details that are used to
              access the&nbsp;Bitmambo&nbsp;Services. This includes taking all
              reasonable steps to avoid the loss,&nbsp;theft&nbsp;or misuse of
              said electronic devices and ensuring that said electronic
              devices are password protected. Any loss or compromise of
              personal electronic devices or security details may result in
              unauthorised access of a user’s&nbsp;Bitmambo&nbsp;Account by
              third-parties and the loss or theft of any Digital Currency
              and/or funds held in your&nbsp;Bitmambo&nbsp;Account and the
              misuse of any associated accounts, including linked bank
              account(s) and credit/debit card(s).&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              12.3 Security Breach If you suspect that
              your&nbsp;Bitmambo&nbsp;Account or any of your security details
              have been compromised or if you become aware of any fraud or
              attempted fraud or any other security incident (including a
              cyber-security attack) affecting you and /
              or&nbsp;Bitmambo&nbsp;(together a “Security Breach”), you
              must:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) notify&nbsp;Bitmambo&nbsp;Support as soon as
              possible by email free of charge
              at:&nbsp;trust@Bitmambo.com;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) continue to provide accurate and up to date
              information throughout the duration of the Security Breach;
              and&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(C)you must take any steps that we reasonably
              require to reduce,&nbsp;manage&nbsp;or report any Security
              Breach.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Failure to provide prompt notification of any Security Breach
              may be&nbsp;taken into account&nbsp;in our determination of the
              appropriate resolution of the matter.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              12.4 Safety and Security of Your Computer and
              Devices.&nbsp;Bitmambo&nbsp;is not liable for any damage or
              interruptions caused by any computer viruses or other malicious
              code that may affect your computer or other equipment, or any
              phishing, spoofing or other attack. We advise the regular use of
              a reputable and readily available virus screening and prevention
              software. You should also be aware that SMS and email services
              are vulnerable to spoofing and phishing attacks and should use
              care in reviewing messages purporting to originate from
              us.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>13. GENERAL&nbsp;</p>
          </div>
          <div>
            <p>
              13.1 Your Compliance with Applicable Law. You must comply with
              all applicable laws, regulations, licensing requirements
              and&nbsp;third party&nbsp;rights (including, without limitation,
              data privacy laws and&nbsp;anti-money laundering and countering
              the financing of terrorism laws) in your use of
              the&nbsp;Bitmambo&nbsp;Services, the&nbsp;Bitmambo&nbsp;Platform
              and the Site.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.2 Limited Licence. We grant you a limited, non-exclusive,
              non-transferable licence, subject to the terms of this
              Agreement, to access and use the Site, and related content,
              materials, information (collectively, the "Content") solely for
              approved purposes as permitted by us from time to time. Any
              other use of the Site or Content is expressly prohibited and all
              other right, title, and interest in the Site or Content is
              exclusively the property of&nbsp;Bitmambo&nbsp;and its
              licensors. You agree not to copy, transmit, distribute, sell,
              license, reverse engineer, modify, publish, or participate in
              the transfer or sale of, create derivative works from, or in any
              other way exploit any of the Content, in whole or in part.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              "Bitmambo.com", "bitmambo"&nbsp;and all logos related to
              the&nbsp;Bitmambo&nbsp;Services or displayed on the Site are
              trade marks or registered marks of&nbsp;Bitmambo&nbsp;or its
              licensors. You may not copy, imitate or use them without our
              prior written consent.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.3 Prohibited and Conditional Use. In connection with your use
              of the&nbsp;Bitmambo&nbsp;Services, and your interactions with
              other users and third parties, you agree to comply with the
              ‘Policy on Prohibited Use, Prohibited Businesses and Conditional
              Use’ (as set out in Appendix 1). Nothing contained herein should
              be construed as granting, by implication, estoppel or otherwise,
              any licence or right for Digital Currency to be used for
              illegal, unlawful, fraudulent, unethical, or unauthorised
              purposes or to promote or facilitate any illegal, unlawful,
              fraudulent, unethical or unauthorised activities. We reserve the
              right at all times to monitor, review, retain and/or disclose
              any information as necessary to satisfy any applicable law,
              regulation, sanctions programs, legal process or governmental
              request.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.4 Export Controls &amp; Sanctions. Your use of
              the&nbsp;Bitmambo&nbsp;Services and the Site is subject to
              international export controls and economic sanctions
              requirements. By sending, receiving, buying,
              selling,&nbsp;trading&nbsp;or storing Digital Currency through
              the Site or&nbsp;Bitmambo&nbsp;Services, you agree that you will
              comply with those requirements. You are not permitted to acquire
              Digital Currency or use any of the&nbsp;Bitmambo&nbsp;Services
              through the Site if:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) you are in, under the control of, or a national
              or resident of Cuba, Iran, North Korea, Sudan, or Syria or any
              other country subject to United States embargo, UN
              sanctions&nbsp;or&nbsp;the European Union sanctions regimes
              (each a "Sanctioned Country"), or if you are a person on the
              economic sanctions lists as published from time to time by
              applicable authorities (including, but not limited to the U.S.
              Commerce Department's Denied Persons List, Unverified List, or
              Entity List, or the EU financial sanctions regime (a "Sanctioned
              Person"); or&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) you intend to supply any acquired or stored
              Digital Currency&nbsp;or Bitmambo&nbsp;Services to a Sanctioned
              Country (or a national or resident of a Sanctioned Country) or
              Sanctioned Person.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.5 Amendments. We may make all other amendments to the
              Agreement (including in relation to any
              other&nbsp;Bitmambo&nbsp;Services) by posting the revised
              Agreement on the Site or by emailing it to you, indicating when
              the revised Agreement becomes effective. Although we will
              endeavour to provide you with advance notice where possible,
              where lawful we may indicate that the revised Agreement shall be
              effective immediately and if you do not agree with any such
              modification, you should close your&nbsp;Bitmambo&nbsp;Account
              and cease using the&nbsp;Bitmambo&nbsp;Services. You agree that
              any notification of amendments in the manner as aforesaid shall
              be sufficient notice to you, and your continued access and/or
              use of&nbsp;Bitmambo&nbsp;Services and/or the Site shall
              constitute an affirmative acknowledgement by you of the
              amendments and shall be deemed to be your acceptance of the
              revised Agreement.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Copies of the most up-to-date version of the Agreement will
              be&nbsp;made available in the Site at all times.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.6 Relationship of the Parties. Nothing in this Agreement
              shall be deemed or is intended to be deemed, nor shall it cause,
              either you or&nbsp;Bitmambo&nbsp;to be treated as partners or
              joint ventures, or either you or&nbsp;Bitmambo&nbsp;to be
              treated as the agent of the other.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.7 Privacy of Others. If you receive information about another
              user through the&nbsp;Bitmambo&nbsp;Services, you must keep the
              information confidential and only use it in connection with
              the&nbsp;Bitmambo&nbsp;Services. You may not disclose or
              distribute a user’s information to a third party or use the
              information except as reasonably necessary to carry out a
              transaction and other functions reasonably incidental thereto
              such as support,&nbsp;reconciliation&nbsp;and accounting unless
              you receive the user’s express consent to do so. You may not
              send unsolicited communications to another user through
              the&nbsp;Bitmambo&nbsp;Services.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.8 Contact Information.&nbsp;You are responsible for keeping
              your contact details (including your email address and telephone
              number) up to date in your&nbsp;Bitmambo&nbsp;Account
              profile&nbsp;in order to&nbsp;receive any notices or alerts that
              we may send to you (including notices or alerts of actual or
              suspected Security Breaches). Please see Appendix 3 for more
              detail in relation to how we will communicate with you.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.9 Taxes. It is your sole responsibility to determine whether,
              and to what extent, any taxes apply to any transactions you
              conduct through the&nbsp;Bitmambo&nbsp;Services, and to
              withhold, collect,&nbsp;report&nbsp;and remit the correct amount
              of tax to the appropriate tax authorities. Your transaction
              history is available through
              your&nbsp;Bitmambo&nbsp;Account.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.10 Unclaimed Property. If we hold Digital Currency on your
              behalf, and we are unable to contact you and have no record of
              your use of the&nbsp;Bitmambo&nbsp;Services for several years,
              applicable law may require us to report the Digital Currency as
              unclaimed property to the authorities in certain jurisdictions.
              We will try to locate you at the address shown in our records,
              but if we are unable to, we may be required to deliver any such
              Digital Currency to the authorities in certain jurisdictions
              as&nbsp;unclaimed property. We reserve the right to deduct a
              dormancy fee or other administrative charges from such unclaimed
              funds, as permitted by applicable law.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.11 Death of Account Holder. For security reasons, if we
              receive legal documentation confirming your death or other
              information leading us to believe you have died, we will freeze
              your&nbsp;Bitmambo&nbsp;Account and during this time, no
              transactions may be completed until: (i) your designated
              executor / trustee has opened a new&nbsp;Bitmambo&nbsp;Account
              or informed&nbsp;Bitmambo&nbsp;about another,
              existing,&nbsp;Bitmambo&nbsp;Account in their name, as further
              described below, and the entirety of
              your&nbsp;Bitmambo&nbsp;Account has been transferred to such new
              account, or (ii) we have received proof in a form satisfactory
              to us that you have not died. If we have reason to believe you
              may have died but we do not have proof of your death in a form
              satisfactory to us, you authorize us to make inquiries, whether
              directly or through third parties, that we consider necessary to
              ascertain whether you have died. Upon receipt by us of proof
              satisfactory to us that you have died, the executor / trustee
              you have designated in a valid Will or similar testamentary
              document will be required to open a
              new&nbsp;Bitmambo&nbsp;Account or inform&nbsp;Bitmambo&nbsp;of
              another, existing&nbsp;Bitmambo&nbsp;Account in their name, to
              which the entirety of the funds in
              your&nbsp;Bitmambo&nbsp;Account shall be transferred. If you
              have not designated an executor / trustee, then we reserve the
              right to (i) treat as your executor / trustee any person
              entitled to inherit your&nbsp;Bitmambo&nbsp;Account, as
              determined by us upon receipt and review of the documentation
              we, in our sole and absolute discretion, deem necessary or
              appropriate, including (but not limited to) a Will or similar
              document, or (ii) require an order designating an executor /
              trustee from a court having competent jurisdiction over your
              estate. In the event we determine, in our sole and absolute
              discretion, that there is uncertainty regarding the validity of
              the executor / trustee designation, we reserve the right to
              require an order resolving such issue from a court of competent
              jurisdiction before taking any action relating to
              your&nbsp;Bitmambo&nbsp;Account. Pursuant to the above, unless
              your designated executor / trustee already holds
              a&nbsp;Bitmambo&nbsp;Account (in which case they may still be
              required to undergo additional identity verification
              procedures), the opening of a new&nbsp;Bitmambo&nbsp;Account by
              a designated executor / trustee is mandatory following the death
              of a&nbsp;Bitmambo&nbsp;Account owner, and you hereby agree that
              your executor / trustee will be required to open a
              new&nbsp;Bitmambo&nbsp;Account and provide the information
              required under Section 3 of this Agreement in order to gain
              access to the contents of your&nbsp;Bitmambo&nbsp;Account.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.12 Entire Agreement. This Agreement (including documents
              incorporated by reference herein) comprise the entire
              understanding and agreement between you
              and&nbsp;Bitmambo&nbsp;as to the subject matter hereof, and it
              supersedes&nbsp;any and all&nbsp;prior discussions, agreements
              and understandings of any kind (including without limitation any
              prior versions of this Agreement), and every nature between and
              among you and&nbsp;Bitmambo.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.13 Interpretation. Section headings in this Agreement are for
              convenience only and shall not govern the meaning or
              interpretation of any provision of this Agreement.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.14 Transfer and Assignment. This Agreement is personal
              to&nbsp;you&nbsp;and you cannot transfer or assign your rights,
              licenses, interests and/or obligations to anyone else. We may
              transfer or assign our rights licenses, interests and / or our
              obligations at any time, including as part of a
              merger,&nbsp;acquisition or other corporate reorganisation
              involving&nbsp;Bitmambo, provided that this transfer or
              assignment does not materially impact the quality of
              the&nbsp;Bitmambo&nbsp;Services you receive. Subject to the
              foregoing, this Agreement will bind and inure to the benefit of
              the parties, their successors and permitted assigns. You reserve
              the right to terminate the agreement with immediate effect in
              the event we transfer and/or assign the Agreement.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.15 Security Interests. You must not create security over your
              Digital Currency unless you have obtained our prior approval in
              writing.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.16 Invalidity. If any provision of this Agreement is
              determined to be invalid or unenforceable under any applicable
              law, this will not affect the validity of any other provision.
              If any provision is found unenforceable, the unenforceable
              provision will be severed, and the remaining provisions will be
              enforced.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.17 Enforcement of Our Rights. We may not always strictly
              enforce our rights under this Agreement. If we do choose not to
              enforce our rights at any time, this is a
              temporary&nbsp;measure&nbsp;and we may enforce our rights
              strictly again at any time.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.18 Language. This Agreement and any information or
              notifications that you or we are to provide should be in
              English. Any translation of this Agreement or other documents is
              provided for your convenience only and may not accurately
              represent the information in the original English. In the event
              of any inconsistency, the English language version of this
              Agreement or other documents shall prevail.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.19 Change of Control. In the event that&nbsp;Bitmambo&nbsp;is
              acquired by or merged with a third party entity, we reserve the
              right, in any of these circumstances, to transfer or assign the
              information we have collected from you and our relationship with
              you (including this Agreement) as part of such merger,
              acquisition, sale, or other change of control.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.20 Survival. All provisions of this Agreement which by their
              nature extend beyond the expiration or termination of this
              Agreement, including, without limitation, the Sections relating
              to suspension or termination,&nbsp;Bitmambo&nbsp;Account
              cancellation, debts owed to&nbsp;Bitmambo, general use of
              the&nbsp;Bitmambo&nbsp;Platform or Site, disputes
              with&nbsp;Bitmambo, and general provisions will continue to be
              binding and operate after the termination or expiration of this
              Agreement.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              13.21 Governing Law and Jurisdiction. This Agreement and the
              relationship between us shall be governed by the laws of
              Bulgaria and the jurisdiction of the&nbsp;Bulgarian&nbsp;courts,
              subject to any local mandatory law, or rights available to
              Consumers.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>APPENDICES&nbsp;</p>
          </div>
          <div>
            <p>
              Appendix&nbsp;1 :&nbsp;PROHIBITED USE, PROHIBITED BUSINESSES AND
              CONDITIONAL USE&nbsp;
            </p>
          </div>
          <div>
            <p>
              1. Prohibited Use. You may not use
              your&nbsp;Bitmambo&nbsp;Account to engage in the following
              categories of activity ("Prohibited Uses"). The specific types
              of use listed below are representative, but not exhaustive. If
              you are uncertain as to&nbsp;whether or not&nbsp;your use
              of&nbsp;Bitmambo&nbsp;Services or
              the&nbsp;Bitmambo&nbsp;Platform involves a Prohibited Use or
              have questions about how these requirements apply to you, please
              submit a support request at: https://help.bitmambo.com.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              By opening a&nbsp;Bitmambo&nbsp;Account, you represent and
              warrant that you will not use your&nbsp;Bitmambo&nbsp;Account,
              any&nbsp;Bitmambo&nbsp;Services and/or
              the&nbsp;Bitmambo&nbsp;Platform to do any of the
              following:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) Unlawful Activity: Activity which would
              violate, or assist in violation of, any law, statute, ordinance,
              or regulation or sanctions programs administered in the
              countries where&nbsp;Bitmambo&nbsp;conducts business, including
              but not limited to regulatory regimes administered by the U.S.
              Department of Treasury's Office of Foreign Assets Control
              ("OFAC"), or which would involve proceeds of any unlawful
              activity; publish,&nbsp;distribute&nbsp;or disseminate any
              unlawful material or information.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) Abusive Activity: Actions which impose an
              unreasonable or disproportionately large load on our
              infrastructure, or detrimentally interfere with, intercept, or
              expropriate any system, data, or information; transmit or upload
              any material to the Site that contains viruses, trojan horses,
              worms, or any other harmful or deleterious programs; attempt to
              gain unauthorised access to the Site,
              other&nbsp;Bitmambo&nbsp;Accounts, computer systems or networks
              connected to the Site, through password mining or any other
              means; use&nbsp;Bitmambo&nbsp;Account information of another
              party to access or use the Site, except in the case of specific
              merchants and / or applications which are specifically
              authorised by a user to access such
              user's&nbsp;Bitmambo&nbsp;Account and information; or transfer
              your account access or rights to your account to a third party,
              unless by operation of law or with the express permission
              of&nbsp;Bitmambo.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(C) Abuse Other Users: Interfere with another
              individual's or entity's access to or use of
              any&nbsp;Bitmambo&nbsp;Services; defame, abuse, extort, harass,
              stalk, threaten or otherwise violate or infringe the legal
              rights (such as, but not limited to, rights of privacy,
              publicity and intellectual property) of others; harvest or
              otherwise collect information from the Site about others,
              including without limitation email addresses, without proper
              consent.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(D) Fraud: Activity which operates to
              defraud&nbsp;Bitmambo,&nbsp;Bitmambo&nbsp;users, or any other
              person; provide any false, inaccurate, or misleading information
              to&nbsp;Bitmambo.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(E) Gambling: Lotteries; bidding fee auctions;
              sports forecasting or odds making; fantasy sports leagues with
              cash prizes; internet gaming; contests; sweepstakes; games of
              chance.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(F) Intellectual Property Infringement: Engage in
              transactions involving items that infringe or violate any
              copyright, trademark, right of publicity or privacy or any other
              proprietary right under the law, including but not limited to
              sales, distribution, or access to counterfeit music, movies,
              software, or other licensed materials without the appropriate
              authorisation from the rights holder; use
              of&nbsp;Bitmambo&nbsp;intellectual property, name, or logo,
              including use of&nbsp;Bitmambo&nbsp;trade or service marks,
              without express consent from&nbsp;Bitmambo&nbsp;or in a manner
              that otherwise harms&nbsp;Bitmambo&nbsp;or
              the&nbsp;Bitmambo&nbsp;brand; any action that implies an untrue
              endorsement by or affiliation with&nbsp;Bitmambo.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              2 Prohibited Businesses. In addition to the Prohibited Uses
              described above, the following categories of businesses,
              business practices, and sale items are barred from being carried
              out using&nbsp;Bitmambo&nbsp;Services or
              the&nbsp;Bitmambo&nbsp;Platform ("Prohibited Businesses"). Most
              Prohibited Businesses categories are imposed by card network
              rules or the requirements of our banking providers or
              processors. The specific types of use listed below are
              representative, but not exhaustive. If you are uncertain as to
              whether or not your use of&nbsp;Bitmambo&nbsp;Services or
              the&nbsp;Bitmambo&nbsp;Platform involves a Prohibited Business,
              or have questions about how these requirements apply to you,
              please contact us at: https://help.Bitmambo.com.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              By opening a&nbsp;Bitmambo&nbsp;Account, you represent and
              warrant that you will not use&nbsp;Bitmambo&nbsp;Services or
              the&nbsp;Bitmambo&nbsp;Platform in connection with any of the
              following businesses, activities, practices, or items:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) Investment and Credit Services: securities
              brokers; mortgage consulting or debt reduction services; credit
              counselling or repair; real estate opportunities; investment
              schemes;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) Restricted Financial Services: check cashing,
              bail bonds; collections&nbsp;agencies;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(C) Intellectual Property or Proprietary Rights
              Infringement: sales, distribution, or access to counterfeit
              music, movies, software, or other licensed materials without the
              appropriate authorisation from the rights&nbsp;holder;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(D) Counterfeit or Unauthorised Goods: unauthorised
              sale or resale of brand name or designer products or services;
              sale of goods or services that are illegally imported or
              exported or which are&nbsp;stolen;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(E) Regulated Products and Services: marijuana
              dispensaries and related businesses; sale of tobacco,
              e-cigarettes, and e-liquid; online prescription or
              pharmaceutical services; age restricted&nbsp;goods or services;
              weapons and munitions; gunpowder and other explosives; fireworks
              and related goods; toxic, flammable, and
              radioactive&nbsp;materials;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(F) Drugs and Drug Paraphernalia: sale of
              narcotics, controlled substances, and any equipment designed for
              making or using drugs, such as bongs, vaporisers,
              and&nbsp;hookahs;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(G) Pseudo-Pharmaceuticals: pharmaceuticals and
              other products that make health claims that have not been
              approved or verified by the applicable local and/or national
              regulatory&nbsp;body;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(H) Substances designed to mimic illegal drugs:
              sale of a legal substance that provides the same effect as an
              illegal drug (e.g.&nbsp;salvia, kratom);&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(I) Adult Content and Services: pornography and
              other obscene materials (including literature, imagery and other
              media); sites offering any&nbsp;sexually-related&nbsp;services
              such as prostitution, escorts, pay-per view, adult live chat
              features;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(J) Multi-level Marketing: pyramid schemes, network
              marketing, and referral marketing&nbsp;programs;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(K) Unfair, predatory or deceptive practices:
              investment opportunities or other services that promise high
              rewards; sale or resale of a service without added benefit to
              the buyer; resale of government offerings without authorisation
              or added value; sites that we determine in our sole discretion
              to be unfair, deceptive, or predatory towards consumers;
              and&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(L) High-risk businesses: any businesses that we
              believe poses elevated financial risk, legal liability, or
              violates card network or bank policies.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              3. Conditional Use. Express written consent and approval
              from&nbsp;Bitmambo&nbsp;must be obtained prior to
              using&nbsp;Bitmambo&nbsp;Services for the following categories
              of business and/or use ("Conditional Uses"). Consent may be
              requested by contacting us at: https://help.bitmambo.com.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Bitmambo&nbsp;may also require you to agree to additional
              conditions, make supplemental representations and warranties,
              complete enhanced on-boarding procedures, and operate subject to
              restrictions if you use&nbsp;Bitmambo&nbsp;Services in
              connection with any of the following businesses, activities, or
              practices:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) Money Services: Money transmitters, Digital
              Currency transmitters; currency or Digital Currency exchanges or
              dealers; gift cards; prepaid cards; sale of in-game currency
              unless the&nbsp;merchant is the operator of the virtual world;
              act as a payment intermediary or aggregator or otherwise resell
              any of the&nbsp;Bitmambo&nbsp;Services;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) Charities: Acceptance of donations for
              non-profit&nbsp;enterprise;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(C) Games of Skill: Games which are not defined as
              gambling under this Agreement or by law, but which require an
              entry fee and award a prize; and&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(D) Religious/Spiritual Organisations: Operation of
              a for-profit religious or spiritual organisation.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>Appendix 2: VERIFICATION PROCEDURES AND LIMITS&nbsp;</p>
          </div>
          <div>
            <p>
              Bitmambo&nbsp;uses multi-level systems and procedures to collect
              and verify information about you&nbsp;in order
              to&nbsp;protect&nbsp;Bitmambo&nbsp;and the community from
              fraudulent users, and to keep appropriate records
              of&nbsp;Bitmambo's&nbsp;customers. Your access to one or
              more&nbsp;Bitmambo&nbsp;Services or
              the&nbsp;Bitmambo&nbsp;Platform, and limits imposed on your use
              of&nbsp;Bitmambo&nbsp;Services (including but not limited to
              daily or weekly conversion limits, deposit, withdrawal and
              trading limits, instant buy limits, Digital Currency Wallet
              transfer limits, and limits on transactions from a linked
              payment method), and any changes to such limits from time to
              time, may be based on the identifying information and/or proof
              of identity you provide to&nbsp;Bitmambo.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Bitmambo&nbsp;may require you to provide or verify additional
              information, or to wait some amount of time after completion of
              a transaction, before permitting you to use
              any&nbsp;Bitmambo&nbsp;Services and/or before permitting you to
              engage in transactions beyond certain volume limits. You may
              determine the volume limits associated with your level of
              identity verification by visiting your account's ‘Limits’ page
              on the Site.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You may submit a request at https://help.bitmambo.com to request
              larger limits.&nbsp;Bitmambo&nbsp;will require you to submit to
              Enhanced Due Diligence. Additional fees and costs may apply,
              and&nbsp;Bitmambo&nbsp;does not guarantee that we will raise
              your limits.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>Appendix&nbsp;3 :&nbsp;COMMUNICATIONS&nbsp;</p>
          </div>
          <div>
            <p>
              1. Electronic Delivery of Communications. You agree and consent
              to receive electronically all communications, agreements,
              documents,&nbsp;notices&nbsp;and disclosures (collectively,
              "Communications") that we provide in connection with
              your&nbsp;Bitmambo&nbsp;Account and your use
              of&nbsp;Bitmambo&nbsp;Services. Communications include:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) terms of use and policies you agree to
              (e.g.&nbsp;the Agreement and Privacy Policy), including updates
              to these agreements or policies;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) account details, history, transaction receipts,
              confirmations, and any other account or
              transaction&nbsp;information;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(C) legal, regulatory, and tax disclosures or
              statements we may be required to make available to you;
              and&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(D) responses to claims or customer support
              inquiries filed in connection with
              your&nbsp;Bitmambo&nbsp;Account.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Unless otherwise specified in this Agreement, we will provide
              these Communications to you by posting them on the Site,
              emailing them to you at the primary email address listed in
              your&nbsp;Bitmambo&nbsp;Account, communicating to you via
              instant chat, and/or through other electronic communication such
              as text message or mobile push notification, and you agree that
              such Communications will constitute sufficient notice of the
              subject matter therein.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              2. How to Withdraw Your Consent. You may withdraw your consent
              to receive Communications electronically by contacting us at:
              https://help.bitmambo.com. If you fail to provide or if you
              withdraw your consent to receive Communications in the specified
              manners,&nbsp;Bitmambo&nbsp;reserves the right to immediately
              close your&nbsp;Bitmambo&nbsp;Account or charge you additional
              fees for paper copies of the Communications.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              3. Updating your Information. It is your responsibility to
              provide us with a true, accurate and complete email address and
              your contact information, and to keep such information up to
              date. You understand and agree that if&nbsp;Bitmambo&nbsp;sends
              you an electronic&nbsp;Communication&nbsp;but you do not receive
              it because your primary email address you have provided is
              incorrect, out-of-date, blocked by your service provider, or you
              are otherwise unable to receive electronic
              Communications,&nbsp;Bitmambo&nbsp;will be deemed to have
              provided the Communication to you. You may update your
              information by logging into your&nbsp;Bitmambo&nbsp;Account and
              visiting settings or by contacting our support team at:
              https://help.bitmambo.com.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Appendix&nbsp;4 :&nbsp;BITMAMBO&nbsp;TRADING&nbsp;SERVICES&nbsp;
            </p>
          </div>
          <div>
            <p>
              1. General. Eligible users may establish an account at:
              https://bitmambo.com/exchange&nbsp;(a
              “bitmambo&nbsp;trading&nbsp;Account”), an order book exchange
              platform for Digital Currencies. The provisions of this Appendix
              4 (Bitmambo&nbsp;Trading&nbsp;Services) apply to your use of
              such&nbsp;Bitmambo&nbsp;Trading&nbsp;Account in addition to the
              other applicable provisions of this Agreement, including without
              limitation the releases, indemnities, disclaimers, limitations
              of liability, prohibited use, dispute resolution, and
              cancellation policies set forth above.&nbsp;Bitmambo&nbsp;does
              not offer&nbsp;Bitmambo&nbsp;Trading Services&nbsp;to customers
              in all jurisdictions. Access and use of
              the&nbsp;bitmambo&nbsp;API and the Developer’s Tools are
              governed by Appendix 6 (Developer’s Tools).&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              1.1 Order Books.&nbsp;Bitmambo&nbsp;Trading Services&nbsp;offers
              an order book for various Digital Currency and central bank
              issued currency trading pairs (each an ”Order Book”). Refer to
              your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account to determine which
              Order Books are available to you.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              1.2&nbsp;Bitmambo&nbsp;Trading&nbsp;Account.
              Your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account consists of the
              following:&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(A) a dedicated Digital Currency Wallet for each
              Digital Currency offered
              on&nbsp;Bitmambo&nbsp;Trading&nbsp;Services;&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              &nbsp; &nbsp;(B) associated user tools, accessible at:
              https://bitmambo.com/exchange&nbsp;and through
              the&nbsp;bitmambo&nbsp;API.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              You understand and agree that you are responsible for&nbsp;any
              and all&nbsp;orders, trades, and other instructions entered
              into&nbsp;Bitmambo&nbsp;Trading&nbsp;Services&nbsp;including
              identifiers, permissions, passwords, and security codes
              associated with
              your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              1.3 Deposits. You may fund
              your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account by depositing
              Digital Currency from your basic&nbsp;Bitmambo&nbsp;Account,
              bank account or an external Digital Currency address into
              your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account. The Digital
              Currency deposited in
              your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account can be used only to
              buy and sell Digital Currency
              using&nbsp;Bitmambo&nbsp;Trading.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              1.4 Withdrawals. You may withdraw Digital Currency from
              your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account by transfer to
              your&nbsp;Bitmambo&nbsp;Account or to an external Digital
              Currency address. You may withdraw central bank issued currency
              from your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account to
              your&nbsp;Bitmambo&nbsp;Account or directly to your bank
              account.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              ALL DEPOSITS AND WITHDRAWALS MAY BE SUBJECT TO LIMITS. ALL
              LIMITS WILL BE DISPLAYED IN YOUR
              BITMAMBO&nbsp;TRADING&nbsp;ACCOUNT.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              1.5 Withdrawal Fees.&nbsp;Bitmambo&nbsp;may also charge a fee on
              certain central bank issued currency deposit or withdrawal
              methods (e.g.&nbsp;bank wire). All such fees will be clearly
              displayed in your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>2. Trading Rules and Trading Fees.&nbsp;</p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              2.1 Trading Rules. By
              accessing&nbsp;Bitmambo&nbsp;Trading&nbsp;through:
              https://bitmambo.com/exchange&nbsp;or
              the&nbsp;bitmambo&nbsp;API, you accept and agree to be bound by
              the trading rules set out
              at https://www.bitmambo.com/legal/trading_rules (the
              "Trading Rules").&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              2.2 Trading Fees. By placing an order
              on&nbsp;Bitmambo&nbsp;Trading, you agree to pay all applicable
              fees and you authorise&nbsp;Bitmambo&nbsp;to automatically
              deduct fees directly from
              your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account. Trading fees are
              set forth in the Trading Rules and
              at:&nbsp;https://pro.bitmambo.com/fees.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              2.3 Trading Account Use. By using
              a&nbsp;Bitmambo&nbsp;Trading&nbsp;Account you agree and
              represent that you will use&nbsp;Bitmambo&nbsp;Trading&nbsp;only
              for yourself as the account owner, and not on behalf of any
              third party, unless you have obtained prior approval
              from&nbsp;Bitmambo. You may not sell, lease, furnish or
              otherwise permit or provide access to
              your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account to any other entity
              or to any individual that is not your employee or agent. You
              accept full responsibility for your employees' or agents' use
              of&nbsp;Bitmambo&nbsp;Trading, whether such use is directly
              through&nbsp;Bitmambo&nbsp;Trading&nbsp;website or by other
              means, such as those facilitated through API keys, and/or
              applications which you may authorise. You understand and agree
              that you are responsible for&nbsp;any and all&nbsp;orders,
              trades, and other instructions entered
              into&nbsp;Bitmambo&nbsp;Trading&nbsp;including identifiers,
              permissions, passwords, and security codes associated with
              your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              2.4 Suspension and Cancellation. We may suspend
              your&nbsp;bitmambo&nbsp;Pro Account or your access to any one or
              more Order Books in accordance with the suspension and
              termination provisions set out in this Agreement.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Suspension or termination of
              your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account shall not affect
              the payment of fees or other amounts you owe
              to&nbsp;Bitmambo.&nbsp;In the event
              that&nbsp;your&nbsp;Bitmambo&nbsp;Account is suspended or
              terminated, we will immediately cancel all open orders
              associated with your&nbsp;Bitmambo&nbsp;Trading&nbsp;Account,
              block all withdrawals and bar the placing of further orders
              until resolution or we cancel
              your&nbsp;Bitmambo&nbsp;Account.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              2.5 No Warranty. We do not represent
              that&nbsp;Bitmambo&nbsp;Trading&nbsp;and/or its
              constituent&nbsp;Bitmambo&nbsp;Trading&nbsp;Accounts, APIs, and
              related services, will be available without interruption.
              Although we will strive to provide you with continuous
              operations, we do not guarantee continuous access or that there
              will be no delays, failures, errors, omissions or loss of
              transmitted information, nor do we guarantee that any order will
              be executed, accepted, recorded, or remain
              open.&nbsp;Bitmambo&nbsp;reserves the right to cancel any open
              trades and/or suspend&nbsp;Bitmambo&nbsp;Trading&nbsp;activity
              in accordance with the Trading Rules.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              2.6 No Investment Advice or Brokerage. For the avoidance of
              doubt,&nbsp;Bitmambo&nbsp;does not provide investment, tax, or
              legal advice, nor does&nbsp;Bitmambo&nbsp;broker trades on your
              behalf. All trades are executed automatically, based on the
              parameters of your order instructions and in accordance with
              posted trade execution procedures, and you are solely
              responsible for determining whether any&nbsp;investment,
              investment strategy or related transaction is appropriate for
              you based on your personal investment objectives, financial
              circumstances and risk tolerance. You should consult your legal
              or tax professional regarding your specific situation.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              Bitmambo&nbsp;may provide educational information about
              supported Digital Currency, as well as Digital Currency not
              supported by&nbsp;Bitmambo, in order to assist users in learning
              more about such Digital Currency. Information may include, but
              is not limited to, blog posts, articles, links to third party
              content, news feeds, tutorials, and
              videos.&nbsp;Bitmambo&nbsp;will not be held responsible for the
              decisions you make to buy, sell, or hold Digital Currency based
              on the information provided by&nbsp;Bitmambo.&nbsp;
            </p>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>
              2.7 Debts. In the event that there are outstanding amounts owed
              to us hereunder, including in your (standard) bitmambo Account,
              bitmambo reserves the right to debit your bitmambo Pro Account
              accordingly and/or to withhold amounts from funds you may
              transfer from your bitmambo Pro Account to your bitmambo
              Account.&nbsp;
            </p>
          </div>
        </div>
        <div>
          <div>
            <br />
          </div>
        </div>
      </Box>
    </Box>
  </LegalLayout>
);

export default UserAgreement;
