import React, { useRef } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  LinearProgress,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction='up' ref={ref} {...props} />
);

interface ModalRedirectProps extends DialogProps {
  handleClose: () => void;
}

const ModalRedirect = (props: ModalRedirectProps) => {
  const iframeRef = useRef<any>(null);
  const {
    transactionState: { redirectUrl },
  } = useBuyCurrencyStore();

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      aria-describedby='alert-dialog-slide-description'
      maxWidth='md'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '20px', p: 2 },
      }}
      {...props}
    >
      <DialogTitle variant='button' textAlign='center'>
        <Box display='flex' alignItems='center'>
          <Box flexGrow={1}>Transaction</Box>
          <Box>
            <IconButton onClick={props.handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ borderRadius: '20px' }}>
        <LinearProgress />
        <Box
          sx={{
            mt: 4,
            iframe: {
              width: '100%',
              height: '1000px',
              minHeight: 0,
              border: 'none',
            },
          }}
        >
          <iframe
            id='myIframe'
            title='Inline Frame Example'
            ref={iframeRef}
            src={redirectUrl ? redirectUrl : ''}
            onChange={(e) => console.log(e)}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalRedirect;
