import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

import _ from 'lodash';

import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import CardWrapper from 'src/components/Common/CardWrapper';
import { useTranslation } from 'react-i18next';
import ModalRedirect from '../ModalRedirect';
import { usePayment } from 'src/hooks/queries/useCurrency';
import { useHistory } from 'react-router';
import { RoutePath } from 'src/routes/path';

export const BankTransfer = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const [openModal, setOpen] = useState(false);
  const {
    transactionState: { id },
  } = useBuyCurrencyStore();
  const { mutate: payment, isLoading } = usePayment();

  const submitPayment = async () => {
    payment(
      {
        payment_id: id as string,
        payment_option: 'Bank Transfer',
        country: 'FR',
        state: 'HCM city',
        address: '300 Nguyen Van Quy',
        city: 'HCM city',
        zip: '500000',
        card_holder_name: '',
        card_number: '',
        expiration_date: '',
        cardSaveNumber: '',
        cvv: '',
      },
      {
        onSuccess(data) {
          setOpen(true);
        },
      }
    );
  };
  useEffect(() => {
    if (id) {
      submitPayment();
    }
  }, []);
  const closeModal = () => {
    setOpen(false);
    history.push(
      `${RoutePath.TRANSACTION_DETAILS.replace(':id', id as string)}`
    );
  };
  return (
    <CardWrapper>
      <ModalRedirect open={openModal} handleClose={closeModal} />
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Typography variant='h4' fontWeight='bold' mb={1}>
          {t('bankTransfer')}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' alignItems={'center'}>
        <CircularProgress />
        <Typography mb={1} color='grey.400'>
          Fetching payment options
        </Typography>
      </Box>
    </CardWrapper>
  );
};

export default BankTransfer;
