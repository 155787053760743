import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { RoutePath } from 'src/routes/path';

type ProtectedRouteProps = RouteProps & {
  isAuthenticated: boolean;
  component: React.ComponentType<RouteProps>;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { isAuthenticated, component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(routeProps) => (isAuthenticated ? (
        React.createElement(component, routeProps)
      ) : (
        <Redirect to={RoutePath.LOGIN} />
      ))}
    />
  );
};

export default React.memo(ProtectedRoute);
