import React from 'react';
import { useQuery } from 'src/hooks/app/useQuery';
import ProfileLayout, { TabName } from './Layout';
import Infomation from './Infomation';
import KycVerification from './KycVerification';

const Profile = () => {
  const query = useQuery();
  const tab = (query.get('tab') || 'infomation') as TabName;

  return (
    <ProfileLayout>
      {tab === 'infomation' && <Infomation />}
      {tab === 'kyc' && <KycVerification />}
    </ProfileLayout>
  );
};

export default Profile;

