import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';

import { TextInput } from 'src/components/Inputs';
import { getError, getErrorMessage } from 'src/utils/common';
import { RoutePath } from 'src/routes/path';
import { useLogin } from 'src/hooks/queries/useAuth';
import { EmailIdentification } from 'src/Types/Auth';
import { useLoginStore } from 'src/stores/login';
import { CustomLink } from 'src/Features/core/Common';
import CardWrapper from 'src/components/Common/CardWrapper';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

const formSchema = yup.object().shape({
  email: yup.string().email('Email is invalid').required('Required'),
  checked: yup.boolean().oneOf([true], 'Required'),
});

const CheckEmail = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const preEmail = searchParams.get('email');

  const history = useHistory();
  const { i18n, t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const { onNext, setFormState } = useLoginStore();
  const { mutateAsync, isLoading } = useLogin();
  const { register, handleSubmit, formState, watch } =
    useForm<EmailIdentification>({ resolver: yupResolver(formSchema) });

  const checked = watch('checked');

  const handleSubmitForm = async ({ email }: EmailIdentification) => {
    try {
      await mutateAsync({ email, phone: '' });
      setFormState({ email, phone: '' });
      onNext();
    } catch (error) {
      const errCode = _.get(error, 'response.status', null);
      if (errCode === 404 || errCode === 400) {
        history.push(`${RoutePath.REGISTER}?email=${email}`);
      }
      setIsError(true);
    }
  };

  useEffect(() => {
    if (preEmail) {
      let _email = preEmail.trim();

      handleSubmitForm({
        email: _email.replace(' ', ''),
        checked: true,
        phone: '',
      });
    }
  }, [preEmail]);

  return (
    <Box>
      <CardWrapper>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography
              fontSize={{
                base: 32,
                xs: 22,
                sm: 24,
                md: 32,
              }}
              fontWeight='bold'
              mt={2}
              textAlign='center'
            >
              {t('WelcomeToBitmambo')}
            </Typography>
            <Typography
              fontWeight='medium'
              maxWidth='320px'
              textAlign='center'
              color='action.active'
            >
              {t('PleaseProvideYourEmailToReceivePaymentNotifications')}
            </Typography>
          </Box>
          <Box mt={4}>
            <TextInput
              placeholder='Enter your email...'
              inputProps={{ sx: { textAlign: 'center' } }}
              error={getError(formState, 'email')}
              message={getErrorMessage(formState, 'email')}
              {...register('email')}
            />
          </Box>
          {isError && (
            <Typography
              textAlign='center'
              mt={2}
              color='primary'
              fontWeight='bold'
            >
              {t('NoAccountWithThisEmailExists')}:{' '}
              <CustomLink
                to={RoutePath.REGISTER}
                style={{ color: 'inherit', textDecoration: 'underline' }}
              >
                {t('ClickHereToRegisterABitmamboAccountNow')}
              </CustomLink>
            </Typography>
          )}
          <Box display='flex' justifyContent='center' mt={1}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...register('checked')} />}
                label={
                  <Typography color='action.active' fontWeight='medium'>
                    {t('IAgreeBitmambo')}{' '}
                    <Typography
                      component='span'
                      color='primary'
                      fontWeight='medium'
                    >
                      {t('TermsOfUse')}
                    </Typography>
                  </Typography>
                }
              />
            </FormGroup>
          </Box>
          <Box display='flex' justifyContent='center' mt={4}>
            <LoadingButton
              variant='contained'
              type='submit'
              loading={isLoading}
              fullWidth
              disabled={!checked}
            >
              {t('Continue')}
            </LoadingButton>
          </Box>
        </form>
      </CardWrapper>
    </Box>
  );
};

export default CheckEmail;

