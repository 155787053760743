import { Typography } from '@mui/material';
import React from 'react';
import { KycStatus } from 'src/Types/Kyc';

type Props = {
  status: boolean;
};

export const AccountKycStatus = ({ status }: Props) => {
  const renderStatus = () => {
    if (status === true) {
      return (
        <Typography variant='caption' color='success.main' fontWeight='bold'>
          Verified
        </Typography>
      );
    }
    return (
      <Typography variant='caption' color='warning.main' fontWeight='bold'>
        Not verified
      </Typography>
    );
  };

  return renderStatus();
};

