import { Language } from '@mui/icons-material';

export const enCommon = {
  transactionHistory: 'Transaction History',
  transactionid: 'Transaction ID',
  address: 'Address',
  home: 'Home',
  BuyCrypto: 'Buy Crypto',
  YouSpend: 'You spend',
  YouReceive: 'You Recieve',
  YouWillReceive: 'You will recieve',
  SelectCurrency: 'Select Currency',
  SelectCrypto: 'Select Crypto',
  Continue: 'Continue',
  WelcomeToBitmambo: 'Welcome to bitmambo',
  EnterYourEmail: 'Enter your email...',
  ClickHereToRegisterABitmamboAccountNow:
    'Click here to register a Bitmambo account now',
  IAgreeBitmambo: 'I agree Bitmambo',
  TermsOfUse: 'Terms of Use',
  ByEnteringOTPYouAgreeWithTermsAndPrivacyPolicy:
    'By entering OTP you agree with terms & privacy policy',
  WalletAddress: 'Wallet Address',
  Wallet: 'Wallet',
  Register: 'Register',
  Login: 'Login',
  Newsletter: 'Newsletter',
  Company: 'Company',
  Support: 'Support',
  Request: 'Request',
  FAQ: 'FAQ',
  AboutUs: 'About Us',
  WeAcceptFollowingPaymentSystems: 'We accept following payment systems',
  YourRegistrationHasBeenSuccessful: 'Your registration has been successful',
  YouArePaying: 'You are paying',
  ForThePriceOf: 'for the amount of',
  Your: 'Your',
  WillBeSentTo: 'will be sent to',
  RemainingTime: 'Remaining time',
  PaymentHasExpired: 'Please try again',
  ReviewBillingAddress: 'Review Billing address',
  MobilePayment: 'Mobile Payment',
  PaymentDetails: 'Payment details',
  phoneNumber: 'phone number',
  email: 'email',
  firstName: 'first name',
  lastName: 'last name',
  identifyType: 'identify type',
  identifyNumber: 'identify number',
  RegisterAccount: 'Register Account',
  selectCountry: 'Select Country',
  description: 'Description',
  requestSupport: 'Request Support',
  enterYourFirstName: 'Enter your first name',
  enterYourLastName: 'Enter your last name',
  dayOfBirth: 'Date of birth',
  enterYourPhoneNumber: 'Enter your phone number',
  country: 'Country',
  city: 'City',
  state: 'State',
  zipcode: 'Zipcode',
  street: 'Street',
  identityType: 'Identity Type',
  identityNumber: 'Identity Number',
  update: 'Update',
  information: 'information',
  YourProfileHasBeenUpdated: 'Your profile has been updated',
  from: 'From',
  to: 'Receive',
  currency: 'Currency',
  amount: 'Amount',
  voucher: 'Voucher',
  fees: 'Fees',
  serviceFee: 'Service Fee',
  networkFee: 'Network Fee',
  token: 'Token',
  network: 'Network',
  ERROR: 'Error',
  INIT: 'Init',
  CREATED: 'Created',
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  COMPLETED: 'Completed',
  DONE: 'Done',
  remainingTime: 'Remaining time',
  payment: 'Payment',
  instructions: 'Instructions',
  paymentSuccess: 'Payment Success',
  plsSelectSubOption: 'Please select sub option',
  incorrectAddress: 'Incorrect address',
  transactionFee: 'Transaction Fee',
  spTooLow: 'The amount is too low',
  selectPaymentOption: 'Select Payment Option',
  cardPayment: 'Card Payment',
  billingAddress: 'Billing address associated with this card',
  saveCard: 'Save my card for future payments',
  cardNotice:
    'Your card will be saved securely on your account. We will only ask you to provide your CVV for your future payments.',
  bankTransfer: 'Bank Transfer',
  fetchingPaymentOption: 'Fetching payment options',
  waitingForPayment: 'Awaiting payment confirmation',
  kycNotify: 'The user is not verified. Please verify with basic verification.',
  SelectLanguage: 'Select Language',
  yourCard: 'Your Card',
  cardHolderName: 'Card holder name',
  cardNumber: 'Card number',
  expirationDate: 'expiration date',
  removeThisCard: 'Remove this card',
};

export const frCommon = {
  transactionHistory: 'Historique des transactions',
  transactionid: 'Transaction ID',
  address: 'Adresse',
  home: 'Accueil',
  BuyCrypto: 'Acheter des Crypto',
  YouSpend: 'tu dépenses',
  YouReceive: 'tu reçois',
  YouWillReceive: 'Vous allez recevoir',
  SelectCurrency: 'Sélectionnez la devise',
  SelectCrypto: 'Sélectionner la cryptomonnaie',
  Continue: 'Continuer',
  WelcomeToBitmambo: 'Bienvenue sur bitmambo',
  EnterYourEmail: 'Entrer votre Email...',
  ClickHereToRegisterABitmamboAccountNow:
    'Cliquez ici pour enregistrer un compte Bitmambo maintenant',
  IAgreeBitmambo: "J'accepte les",
  TermsOfUse: "conditions d'utilisation de Bitmambo",
  ByEnteringOTPYouAgreeWithTermsAndPrivacyPolicy:
    'En entrant OTP, vous acceptez les termes et la politique de confidentialité',
  WalletAddress: 'adresse de portefeuille',
  Wallet: 'Portefeuille',
  Register: 'Créer un compte',
  Login: 'Connexion',
  Newsletter: 'Bulletin',
  Company: 'Entreprise',
  Support: 'Soutien',
  Request: 'Demande',
  FAQ: 'FAQ',
  AboutUs: 'À propos de nous',
  WeAcceptFollowingPaymentSystems:
    'Nous acceptons les systèmes de paiement suivants',
  YourRegistrationHasBeenSuccessful: 'Votre inscription a réussi',
  YouArePaying: 'vous payez',
  ForThePriceOf: 'pour le montant de',
  Your: 'Votre',
  WillBeSentTo: 'era envoyé à',
  PaymentHasExpired: 'Veuillez réessayer',
  RemainingTime: 'Temps restant',
  ReviewBillingAddress: "Vérifier l'adresse de facturation",
  MobilePayment: 'Paiement mobile',
  PaymentDetails: 'Détails de paiemen',
  phoneNumber: 'numéro de téléphone',
  email: 'e-mail',
  firstName: 'prénom',
  lastName: 'nom de famille',
  identifyType: 'identifier le type',
  identifyNumber: 'identifier le numéro',
  RegisterAccount: 'Créer un compte',
  selectCountry: 'Choisissez le pays',
  description: 'Description',
  requestSupport: "Demander de l'aide",
  enterYourFirstName: 'Entrez votre prénom',
  enterYourLastName: 'Entrez votre nom de famille',
  dayOfBirth: 'Date de naissance',
  enterYourPhoneNumber: 'Entrez votre numéro de téléphone',
  country: 'Pays',
  city: 'Ville',
  state: 'Etat',
  zipcode: 'Code postal',
  street: 'Rue',
  identityType: 'Type d identité',
  identityNumber: 'Numéro d identité',
  update: 'Mettre à jour',
  information: 'information',
  YourProfileHasBeenUpdated: 'Votre profil a été mis à jour',
  from: 'De la devise',
  to: 'Sera reçu',
  currency: 'Devise',
  amount: 'Montant',
  voucher: 'Coupon',
  fees: 'Frais',
  serviceFee: 'Frais de service',
  networkFee: 'Frais de réseau',
  token: 'Crypto-monnaie',
  network: 'Réseau',
  ERROR: 'Erreur',
  INIT: 'Initialiser',
  CREATED: 'Créé',
  PENDING: 'En attente',
  PROCESSING: 'En cours de traitement',
  COMPLETED: 'Terminé',
  DONE: 'Terminé',
  remainingTime: 'Temps restant',
  payment: 'Paiement',
  instructions: 'Instructions',
  paymentSuccess: 'Paiement réussi',
  plsSelectSubOption: 'Veuillez sélectionner une sous-option',
  incorrectAddress: 'Adresse incorrecte',
  transactionFee: 'Frais de transaction',
  spTooLow: 'Le montant est trop faible',
  selectPaymentOption: "Sélectionnez l'option de paiement",
  cardPayment: 'Paiement par carte',
  billingAddress: 'Adresse de facturation associée à cette carte',
  saveCard: 'Sauvegarder ma carte pour de futurs paiements',
  cardNotice:
    'Votre carte sera enregistrée en toute sécurité sur votre compte. Nous vous demanderons uniquement de fournir votre CVV pour vos futurs paiements.',
  bankTransfer: 'Virement',
  fetchingPaymentOption: 'Récupérer les options de paiement',
  waitingForPayment: 'En attente de confirmation de paiement',
  kycNotify:
    "L'utilisateur n'est pas vérifié. Veuillez vérifier avec une vérification de base.",
  SelectLanguage: 'Choisir la langue',
  yourCard: 'Votre carte',
  cardHolderName: 'Nom du titulaire',
  cardNumber: 'numéro de carte',
  expirationDate: "date d'expiration",
  removeThisCard: 'retirer cette carte',
};
export const svCommon = {
  transactionHistory: 'Transaktionshistorik',
  transactionid: 'Transaktions-ID',
  address: 'Adress',
  home: 'Start',
  BuyCrypto: 'Köp Crypto',
  YouSpend: 'Du betalar',
  YouReceive: 'Du får',
  YouWillReceive: 'Du kommer få',
  SelectCurrency: 'Välj Valuta',
  SelectCrypto: 'Välj Cryptovaluta',
  Continue: 'Fortsätt',
  WelcomeToBitmambo: 'Välkommen till Bitmambo',
  EnterYourEmail: 'Ange din email...',
  ClickHereToRegisterABitmamboAccountNow:
    'Klicka här för att registrera ett konto',
  IAgreeBitmambo: 'Jag godkänner Bitmambo',
  TermsOfUse: 'Användervillkor',
  ByEnteringOTPYouAgreeWithTermsAndPrivacyPolicy:
    'Genom att ange engångslösenordet godkänner användarvillkor och integritetspolicy',
  WalletAddress: 'Cryptoadress',
  Wallet: 'Wallet',
  Register: 'Registrera',
  Login: 'Logga in',
  Newsletter: 'Nyhetsbrev',
  Company: 'Företag',
  Support: 'Support',
  Request: 'Request',
  FAQ: 'FAQ',
  AboutUs: 'Om oss',
  WeAcceptFollowingPaymentSystems: 'We accepterar följande betalningsmetoder',
  YourRegistrationHasBeenSuccessful: 'Your registrering lyckades ',
  YouArePaying: 'Du betalar',
  ForThePriceOf: 'för summan av',
  Your: '',
  WillBeSentTo: 'kommer att skickas till',
  RemainingTime: 'Ytterligare tid',
  PaymentHasExpired: 'Prova igen',
  ReviewBillingAddress: 'Granska fakturadress',
  MobilePayment: 'Mobile Payment',
  PaymentDetails: 'Betalningsdetaljer',
  phoneNumber: 'Mobilnummer',
  email: 'Email',
  firstName: 'Förnamn',
  lastName: 'Efternamn',
  identifyType: 'Identitetstyp',
  identifyNumber: 'Identitetsnummer',
  RegisterAccount: 'Registera konto',
  selectCountry: 'Välj land',
  description: 'Beskrivning',
  requestSupport: 'Request Support',
  enterYourFirstName: 'Fyll i ditt förnamn',
  enterYourLastName: 'Fyll i ditt efternamn',
  dayOfBirth: 'Födelsedatum',
  enterYourPhoneNumber: 'Fyll i ditt mobilnummer',
  country: 'Land',
  city: 'Stad',
  state: 'Region',
  zipcode: 'Postkod',
  street: 'Gatuadress',
  identityType: 'Identitetstyp',
  identityNumber: 'Identitetsnummer',
  update: 'Uppdatera',
  information: 'information',
  YourProfileHasBeenUpdated: 'Din profil har uppdaterats',
  from: 'Från',
  to: 'Mottagare',
  currency: 'Valuta',
  amount: 'Summa',
  voucher: 'Voucher',
  fees: 'Avgift',
  serviceFee: 'Serviceavgift',
  networkFee: 'Nätverksavgift',
  token: 'Token',
  network: 'Nätverk',
  ERROR: 'Fel',
  INIT: 'Init',
  CREATED: 'Created',
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  COMPLETED: 'Completed',
  DONE: 'Done',
  remainingTime: 'Återstående tid',
  payment: 'Betalning',
  instructions: 'Instruktioner',
  paymentSuccess: 'Betalning lyckades',
  plsSelectSubOption: 'Vänligen välj betalningsalternativ',
  incorrectAddress: 'Felaktig adress',
  transactionFee: 'Transaktionsavgift',
  spTooLow: 'Summan är för liten',
  selectPaymentOption: 'Välj betlaningsmetod',
  cardPayment: 'Kortbetalning',
  billingAddress: 'Fakturaadress för detta kortet',
  saveCard: 'Spara mitt kort för framtida betalningar',
  cardNotice:
    'Dit kort sparas säkert för ditt konto. Du kommer bara behöve ange CVV/CVC-kod för framtida betalningar',
  bankTransfer: 'Banköverföring',
  fetchingPaymentOption: 'Laddar betalningsmetoder',
  waitingForPayment: 'Väntar på att betalningen genomförs',
  kycNotify:
    'Användaren är inte verifierad. Vänligen gå igenom följande verifieringssteg.',
  SelectLanguage: 'Välj språk',
  yourCard: 'Ditt kort',
  cardHolderName: 'Kortinnehavarens namn',
  cardNumber: 'Kortnummer',
  expirationDate: 'utgångsdatum',
  removeThisCard: 'ta bort detta kort',
};
