import React from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { ToastMessageProps, ToastMessage } from 'src/components/Common/ToastMessage';

// Use this without component
export const toastSuccess = (props: ToastMessageProps) => toast(<ToastMessage {...props} type="success" />, { hideProgressBar: true });
export const toastError = (props: ToastMessageProps) => toast(<ToastMessage {...props} type="error" />, { hideProgressBar: true });
export const toastInfo = (props: ToastMessageProps) => toast(<ToastMessage {...props} type="info" />, { hideProgressBar: true });
export const toastWarning = (props: ToastMessageProps) => toast(<ToastMessage {...props} type="warning" />, { hideProgressBar: true });

// Handle error when call api
export const handleError = (errors: any) => {
  const messageError = _.get(errors, 'response.data.errors[0]');
  if (messageError) {
    toastError({ description: messageError });
    return;
  }

  const message = _.get(errors, 'message');
  toastError({ description: message });
};
