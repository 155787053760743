import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { RoutePath } from 'src/routes/path';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { useLoginStore } from 'src/stores/login';

export const useListenRouterChange = () => {
  const { resetStore } = useBuyCurrencyStore();
  const { resetStore: resetLoginStore } = useLoginStore();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === RoutePath.HOME) {
      resetStore();
    }
    if (location.pathname === RoutePath.LOGIN) {
      resetLoginStore();
    }
  }, [location]);
};
