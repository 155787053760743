import React from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';

export type TextInputProps = OutlinedInputProps & {
  label?: string;
  message?: string;
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const { label, message, error, ...rest } = props;

    return (
      <FormControl fullWidth>
        {label && <FormLabel error={error}>{label}</FormLabel>}
        <OutlinedInput error={error} ref={ref} {...rest} />
        {error && message && (
          <FormHelperText error={error}>{message}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

