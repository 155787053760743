/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Box,
  IconButton,
  LinearProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { ReceiptLongOutlined } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { formatDateTime } from 'src/utils/date';
import { formatPrice } from 'src/utils/number';
import { shortAddress } from 'src/utils/string';
import { useGetTransactionHistories } from 'src/hooks/queries/useCurrency';
import { useTransactionHistoryPage } from 'src/stores/transactionHistory';
import { TransactionStatus } from 'src/Types/Currency';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { RoutePath } from 'src/routes/path';
import { colors } from 'src/constants/transactionStatus';

const LIMIT = 10;

const Status = ({ status }: { status: TransactionStatus }) => {
  return (
    <Typography variant='button' color={colors[status]}>
      {status}
    </Typography>
  );
};

export default function TransactionHistory() {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  const { page, setPage } = useTransactionHistoryPage();
  const { data, isLoading } = useGetTransactionHistories({
    page,
    limit: LIMIT,
  });

  const items = _.get(data, 'items', []);
  const totalPage = Math.ceil(_.get(data, 'total', 0) / LIMIT);

  const handlaChangePage = (value: number) => {
    setPage(value);
  };

  return (
    <Box>
      <Typography
        mt={2}
        mb={4}
        display='flex'
        alignItems='center'
        justifyContent='center'
        variant='button'
        fontSize={20}
      >
        <ReceiptLongOutlined sx={{ mr: 2 }} />
        {t('transactionHistory')}
      </Typography>
      <Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell>{t('transactionid')}</TableCell>
                <TableCell align='right'>{t('address')}</TableCell>
                <TableCell align='right'>Network</TableCell>
                <TableCell align='right'>Amount</TableCell>
                <TableCell align='right'>Price</TableCell>
                <TableCell align='right'>Date</TableCell>
                <TableCell align='right'>Status</TableCell>
                <TableCell align='right'>Blockscan</TableCell>
                <TableCell align='right'>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(
                ({
                  id,
                  created_date,
                  status,
                  blockchain_explorer_url,
                  payment_details: {
                    from_currency,
                    to_currency,
                    from_amount,
                    to_amount,
                    address,
                    network,
                  },
                }) => (
                  <TableRow
                    key={id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>
                      {id}
                    </TableCell>
                    <TableCell align='right'>
                      <Tooltip title={address}>
                        <span>{shortAddress(address)}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align='right'>{network}</TableCell>
                    <TableCell align='right'>{`${formatPrice(
                      to_amount,
                      8
                    )} ${to_currency}`}</TableCell>
                    <TableCell align='right'>{`${formatPrice(
                      from_amount,
                      8
                    )} ${from_currency}`}</TableCell>
                    <TableCell align='right'>
                      {formatDateTime(created_date)}
                    </TableCell>
                    <TableCell align='right'>
                      <Status status={status} />
                    </TableCell>
                    <TableCell align='right'>
                      <IconButton
                        disabled={!blockchain_explorer_url}
                        onClick={() =>
                          window.open(blockchain_explorer_url, '_blank')
                        }
                      >
                        <LinkIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align='right'>
                      <IconButton
                        onClick={() =>
                          history.push({
                            pathname: `${RoutePath.TRANSACTION_DETAILS.replace(
                              ':id',
                              id
                            )}`,
                          })
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && <LinearProgress />}
      </Box>
      <Box display='flex' justifyContent='center' mt={2}>
        <Pagination
          count={totalPage}
          page={page}
          variant='outlined'
          onChange={(_event, page) => handlaChangePage(page)}
        />
      </Box>
    </Box>
  );
}

