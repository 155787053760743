import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';

import { BTC, USDT, USDC } from 'src/constants/images';
import { useTranslation } from 'react-i18next';

const icons = {
  BTC,
  USDT,
  USDC,
};

type Props = {
  currencies: any;
  value: any;
  onChange: any;
};

const SelectCrypto = ({ currencies, value, onChange }: Props) => {
  const {
    transactionState: { from_currency },
  } = useBuyCurrencyStore();
  const [open, setOpen] = useState(false);
  const { i18n, t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value: string) => {
    onChange({ target: { value } });
    handleClose();
  };

  const currency = _.find(currencies, (item) => item.id === value);

  return (
    <>
      <Chip
        label={`${_.get(currency, 'short_name', '')} (${_.get(
          currency,
          'network',
          ''
        )})`}
        onClick={handleOpen}
        onDelete={() => undefined}
        deleteIcon={<ChevronRightOutlinedIcon />}
        avatar={<Avatar src={_.get(icons, _.get(currency, 'short_name'))} />}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='xs'
        PaperProps={{ sx: { borderRadius: 4 } }}
      >
        <DialogTitle id='alert-dialog-title'>
          <Typography fontWeight='bold'>{t('SelectCrypto')}</Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 2,
              minHeight: '320px',
              maxHeight: '420px',
              overflow: 'auto',
              mt: 2,
            }}
          >
            <MenuList>
              {_.map(currencies, (item) => (
                <MenuItem
                  key={_.get(item, 'id')}
                  onClick={() => handleChange(_.get(item, 'id'))}
                >
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    width='100%'
                  >
                    <Box display='flex'>
                      <Avatar
                        src={_.get(icons, _.get(item, 'short_name'))}
                        alt={_.get(item, 'short_name')}
                      />
                      <Box ml={2}>
                        <Typography
                          variant='button'
                          display='block'
                          lineHeight={1.2}
                        >
                          {`${_.get(item, 'short_name')} (${_.get(
                            item,
                            'network'
                          )})`}
                        </Typography>
                        <Typography variant='caption' lineHeight={1}>
                          {_.get(item, 'long_name')}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography fontWeight='bold' fontSize={14}>
                        {getSymbolFromCurrency(from_currency || '')}{' '}
                        {_.get(item, 'price')}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SelectCrypto;

