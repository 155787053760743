import { create } from 'zustand';

type State = {
  page: number;
  setPage: (page: number) => void;
}

const initialState: State = {
  page: 1,
  setPage: () => null,
};

export const useTransactionHistoryPage = create<State>((set, get) => ({
  ...initialState,
  setPage: (page) => set(() => ({ page })),
}));
