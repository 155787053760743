import { DeleteCard, CardInfo } from 'src/Types/Card';
import { APIPath } from './api-path';
import request from './request';

export const getCards = ():
  Promise<CardInfo[]> => request.get(APIPath.GET_CARDS, { params: { page: 1, limit: 100 } });

export const getCardDetail = (
  card_number: string,
) => request.get(APIPath.GET_CARD_DETAIL, { params: { card_number } });

export const saveCard = (data: CardInfo):
  Promise<any> => request.post(APIPath.SAVE_CARD, data);

export const removeCard = (data: DeleteCard):
  Promise<any> => request.delete(APIPath.DELETE_CARD, { data });
