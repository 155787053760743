import * as React from 'react';
import styled from '@emotion/styled';
import { Box, Container, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { useListenRouterChange } from 'src/hooks/app/useListenRouter';
import Header from 'src/Features/core/Header';
import {
  BITMAMBO_INSTANTLY_DARK,
  BITMAMBO_INSTANTLY_DARK_FR,
  BITMAMBO_INSTANTLY_LIGHT,
  BITMAMBO_INSTANTLY_LIGHT_FR,
  BTC,
  CREDIT_CARD,
  ETH,
  BUY_CRYPTO_AFRICA,
} from 'src/constants/images';
import { useGlobalStore } from 'src/stores';
import { CoinPrice } from 'src/Features/core/Common';
import { useGetCurrencies } from 'src/hooks/queries/useCurrency';
import Footer from '../Footer';
import i18n from 'i18next';
import { envEnum, getEnv } from 'src/utils/env';

type Props = {
  children: React.ReactNode;
};

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

function SecondaryLayout({ children }: Props) {
  // Listen router change
  const theme = useTheme();
  useListenRouterChange();
  const match_4k = useMediaQuery(theme.breakpoints.up('xl_4k'));
  const match_2k = useMediaQuery(theme.breakpoints.up('xl_2k'));
  let applyMaxWidth = true;
  let maxWith = 2000;
  if (match_2k) {
    applyMaxWidth = false;
    maxWith = 1200;
  }
  if (match_4k) {
    maxWith = 3000;
  }

  const version = getEnv(envEnum.VERSION);
  let centerImg = CREDIT_CARD;
  if (version === 'Africa') {
    centerImg = BUY_CRYPTO_AFRICA;
  }

  const [isLoadedImage, setIsLoadedImage] = React.useState(false);

  const { mode } = useGlobalStore();
  const { data } = useGetCurrencies();

  return (
    <Wrapper>
      <Header />
      <Container
        maxWidth={applyMaxWidth == true ? 'lg' : false}
        sx={{
          py: {
            base: 10,
            xs: 5,
            sm: 5,
            md: 5,
            lg: 10,
          },
          flex: 1,
          maxWidth: maxWith,
          display: 'flex',
        }}
      >
        <Grid
          container
          spacing={{
            base: 4,
            xs: 2,
            sm: 2,
            md: 2,
            lg: 4,
          }}
          sx={{ alignItems: 'center' }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} position='relative'>
            <Box>
              {i18n.language === 'FR' && (
                <img
                  src={
                    mode === 'light'
                      ? BITMAMBO_INSTANTLY_LIGHT_FR
                      : BITMAMBO_INSTANTLY_DARK_FR
                  }
                  alt='illutration'
                  width='100%'
                />
              )}

              {i18n.language === 'EN' && (
                <img
                  src={
                    mode === 'light'
                      ? BITMAMBO_INSTANTLY_LIGHT
                      : BITMAMBO_INSTANTLY_DARK
                  }
                  alt='illutration'
                  width='100%'
                />
              )}
            </Box>
            <Box
              position='relative'
              display={{ base: 'block', xs: 'none', md: 'block' }}
              mt={6}
            >
              <img
                src={centerImg}
                alt='credit-card'
                width='100%'
                onLoad={() => setIsLoadedImage(true)}
              />
              {isLoadedImage && (
                <Box position='absolute' bottom={32}>
                  <Box display='flex' position='relative' left={-30} mt={2}>
                    <CoinPrice
                      logo={BTC}
                      price={data?.btc.price}
                      priceChange={data?.btc.price_change_24h}
                      name='Bitcoin'
                    />
                  </Box>
                  <Box display='flex' position='relative' right={-30} mt={2}>
                    <CoinPrice
                      logo={ETH}
                      price={data?.eth.price}
                      priceChange={data?.eth.price_change_24h}
                      name='Ethereum'
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {children}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Wrapper>
  );
}
export default SecondaryLayout;

