import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import _ from 'lodash';
import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { RoutePath } from 'src/routes/path';
import { useGlobalStore } from 'src/stores';
import { BitmamboLogo, CustomLink } from 'src/Features/core/Common';
import Mode from 'src/components/Common/Mode';
import UserAvatar from '../UserAvatar';
import { useTranslation } from 'react-i18next';
import SelectLanguage from '../../Language/SelectLanguage';

const menus = [
  {
    id: 'Home',
    name: 'home',
    path: RoutePath.HOME,
  },
  {
    id: 'Transaction history',
    name: 'transactionHistory',
    path: RoutePath.TRANSACTION_HISTORY,
  },
];

const LANGUAGES = [
  { label: 'English', code: 'en' },
  { label: 'French', code: 'fr' },
];

const MenuSmallScreen = () => {
  const { isAuth } = useGlobalStore();
  const [open, setOpen] = useState(false);
  const { i18n, t } = useTranslation();

  const handleToggle = () => {
    setOpen((current) => !current);
  };

  return (
    <>
      <IconButton onClick={handleToggle}>
        <MenuIcon />
      </IconButton>
      <Box width='100%' display='flex' justifyContent='center'>
        <BitmamboLogo />
      </Box>
      <Mode />
      <SwipeableDrawer
        anchor='left'
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Box width={260} display='flex' flexDirection='column' height='100%'>
          <Box p={2}>
            <BitmamboLogo />
          </Box>
          <Divider />
          <Box>
            {isAuth &&
              menus.map((item) => (
                <CustomLink to={item.path} key={item.id}>
                  <Button
                    key={item.id}
                    fullWidth
                    sx={{
                      borderRadius: 0,
                      display: 'block',
                      color: 'text.primary',
                    }}
                  >
                    {t(item.name)}
                  </Button>
                </CustomLink>
              ))}
          </Box>
          {!isAuth && (
            <Stack spacing={2} pb={4} px={2} mt={2}>
              <CustomLink to={RoutePath.REGISTER}>
                <Button fullWidth variant='contained'>
                  {t('Register')}
                </Button>
              </CustomLink>
              <CustomLink to={RoutePath.LOGIN}>
                <Button fullWidth variant='outlined'>
                  {t('Login')}
                </Button>
              </CustomLink>
            </Stack>
          )}
        </Box>
        <Box>
          <select defaultValue={'fr'}>
            {LANGUAGES.map(({ code, label }) => (
              <option key={code} value={code}>
                {label}
              </option>
            ))}
          </select>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

const MenuMediumScreen = () => {
  const { i18n, t } = useTranslation();
  const { isAuth } = useGlobalStore();
  return (
    <>
      <BitmamboLogo />
      {isAuth &&
        menus.map((item) => (
          <CustomLink to={item.path} key={item.id}>
            <Button
              key={item.id}
              sx={{
                display: 'block',
                color: 'text.primary',
              }}
            >
              {t(item.name)}
            </Button>
          </CustomLink>
        ))}
      <Box
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        flex={1}
        gap={1}
        mr={1}
      >
        <Box>
          <Mode />
        </Box>
        {!isAuth && (
          <>
            <CustomLink to={RoutePath.REGISTER}>
              <Button fullWidth variant='contained'>
                {t('Register')}
              </Button>
            </CustomLink>
            <CustomLink to={RoutePath.LOGIN}>
              <Button fullWidth variant='outlined'>
                {t('Login')}
              </Button>
            </CustomLink>
          </>
        )}
      </Box>
    </>
  );
};

const Header = () => {
  const { isAuth } = useGlobalStore();
  const handleLngChange = (e: string) => {
    const url = `${window.location.protocol}//${window.location.hostname}:${
      window.location.port
    }/${e.toLowerCase()}`;
    window.location.replace(url);
  };
  return (
    <Box
      sx={{
        bgcolor: 'transparent',
        border: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.paper',
      }}
    >
      <Container maxWidth='lg'>
        <Toolbar disableGutters>
          <Box display='flex' width='100%' alignItems='center'>
            <Box flexGrow={1}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'flex', md: 'none' },
                  alignItems: 'center',
                }}
              >
                <MenuSmallScreen />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', md: 'flex' },
                  alignItems: 'center',
                }}
              >
                <MenuMediumScreen />
              </Box>
            </Box>
            <Box display='flex' gap={1}>
              {isAuth && <UserAvatar />}
            </Box>
            <Box
              sx={{ display: { xs: 'none', md: 'flex', marginLeft: '1em' } }}
            >
              <SelectLanguage onChange={handleLngChange} />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                alignItems: 'center',
              }}
            >
              <SelectLanguage onChange={handleLngChange} />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </Box>
  );
};

export default Header;

