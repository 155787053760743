import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _ from 'lodash';

import { useGetCards, useRemoveCard } from 'src/hooks/queries/useCard';
import { DialogConfirm } from 'src/components/Common/DialogConfirm';
import { CardInfo } from 'src/Types/Card';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (values: CardInfo) => void;
};

const SelectCard = ({ onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [card_number, setCardNumber] = useState('');
  const [selectValue, setValue] = useState('');
  const { t } = useTranslation();

  const { data: cards } = useGetCards();
  const { mutateAsync, isLoading } = useRemoveCard();

  const handleChange = (event: SelectChangeEvent<typeof selectValue>) => {
    const {
      target: { value },
    } = event;
    setValue(value);
    onChange(cards?.find((item) => item.card_number === value) as CardInfo);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleShowModalConfirm = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    card: CardInfo
  ) => {
    event.stopPropagation();
    setIsOpen(true);
    setCardNumber(card.card_number);
  };

  const handleRemove = async () => {
    await mutateAsync({ card_number });
    handleClose();
  };

  if (!cards || cards.length == 0) {
    return null;
  }

  return (
    <>
      <FormControl>
        <FormLabel>{t('yourCard')}</FormLabel>
        <Select
          value={selectValue}
          onChange={handleChange}
          disabled={_.isEmpty(cards)}
          input={<OutlinedInput sx={{ '& .clear': { display: 'none' } }} />}
        >
          {(cards || []).map((card) => (
            <MenuItem key={card.card_number} value={card.card_number}>
              <Box display='flex' justifyContent='space-between' width='100%'>
                <Typography>{card.label}</Typography>
                <HighlightOffIcon
                  className='clear'
                  sx={{
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                  onClick={(e) => handleShowModalConfirm(e, card)}
                />
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DialogConfirm
        open={isOpen}
        isLoading={isLoading}
        onClose={handleClose}
        onCancel={handleClose}
        onAccept={handleRemove}
        contentText={t('removeThisCard')}
      />
    </>
  );
};

export default SelectCard;

