import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  Link,
  Stack,
  Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { BitmamboLogo, PaymentLogos, Socials } from 'src/Features/core/Common';
import { RoutePath } from 'src/routes/path';
import { TextInput } from 'src/components/Inputs';
import { useTranslation } from "react-i18next";


const Footer = () => {
  const { i18n, t } = useTranslation();
  return (
    <Box bgcolor="background.paper" pb={2} pt={4}>
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent={{
            base: 'left', xs: 'center', sm: 'center', md: 'center', lg: 'left',
          }}
        >
          <BitmamboLogo />
        </Box>
        <Grid container spacing={5} mt={1}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography fontWeight="bold" textTransform="uppercase" mb={2} display="block">{t('Support')}</Typography>
            <Stack fontWeight="medium" color="text.secondary">
              <Link
                component={RouterLink}
                to={RoutePath.REQUEST}
              >
                {t('Request')}
              </Link>
              <Link
                component={RouterLink}
                to={RoutePath.FAQ}
              >
                {t('FAQ')}
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography fontWeight="bold" textTransform="uppercase" mb={2} display="block">{t('Company')}</Typography>
            <Stack fontWeight="medium" color="text.secondary">
              <Link
                component={RouterLink}
                to={RoutePath.ABOUT_US}
              >
              {t('AboutUs')}
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={{
                xs: 'start', sm: 'start', md: 'start', lg: 'end',
              }}
            >
              <Stack spacing={3} maxWidth="400px" width="100%">
                <Typography fontWeight="bold" textTransform="uppercase" fontSize={16}>{t('Newsletter')}</Typography>
                <Box display="flex" gap={1} mt={4}>
                  <TextInput placeholder="Enter your email" />
                  <Button variant="contained" sx={{ borderRadius: 2 }}>Send</Button>
                </Box>
                <Typography fontSize={14}>
                {t('WeAcceptFollowingPaymentSystems')}
                </Typography>
                <PaymentLogos />
              </Stack>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ mt: 4, mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Typography
              fontSize={14}
              fontWeight="medium"
              color="text.secondary"
              textAlign={{
                base: 'left', xs: 'center', sm: 'center', md: 'left',
              }}
            >
              © 2023 bitmambo. All rights reserved
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Stack direction="row" spacing={5} justifyContent="center" fontSize={{ xs: 14, sm: 16 }}>
              <Link
                component={RouterLink}
                to={RoutePath.PRIVACY}
              >
                Privacy
              </Link>
              <Link
                component={RouterLink}
                to={RoutePath.USER_AGREEMENT}
              >
                User agreement
              </Link>
              <Link
                component={RouterLink}
                to={RoutePath.COOKIE_POLICY}
              >
                Cookie policy
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Box
              display="flex"
              justifyContent={{
                xs: 'center', sm: 'center', md: 'start', lg: 'end',
              }}
            >
              <Socials />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
};

export default Footer;
