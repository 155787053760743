import dayjs from 'dayjs';
import _ from 'lodash';
import {
  AuthResponse,
  CreateAccount,
  EmailIdentification,
  Login,
  User,
} from 'src/Types/Auth';
import { APIPath } from './api-path';
import request from './request';
import { getEnv, envEnum } from 'src/utils/env';

export const login = (data: Login): Promise<string> =>
  request.post(APIPath.LOGIN, sanitizeLogin(data));

export const loginConfirm = (data: Login): Promise<AuthResponse> =>
  request.post(APIPath.LOGIN_CONFIRM, sanitizeLogin(data));

export const refreshToken = (): Promise<AuthResponse> =>
  request.post(APIPath.REFRESH_TOKEN);

export const getProfile = (): Promise<User> =>
  request.post(APIPath.GET_PROFILE);

export const emailIdentification = (
  data: EmailIdentification
): Promise<{
  isExisting: boolean;
}> => request.post(APIPath.EMAIL_IDENTIFICATION, data);

export const createAccount = (data: CreateAccount) =>
  request.post(APIPath.CREATE_ACCOUNT, sanitizeCreateAccount(data));

export const updateAccount = (data: User) =>
  request.put(APIPath.UPDATE_ACCOUNT, sanitizeUpdateAccount(data));

const sanitizeCreateAccount = ({
  email,
  first_name,
  last_name,
  phone,
  ref_id,
}: CreateAccount) => ({
  email,
  first_name,
  last_name,
  phone,
  version: getEnv(envEnum.VERSION),
  ref_id,
});

const sanitizeLogin = ({ email, phone, otp }: Login) => {
  return _.pickBy(
    {
      email,
      phone,
      otp,
      version: getEnv(envEnum.VERSION),
    },
    _.identity
  );
};

const sanitizeUpdateAccount = (data: User) => {
  return {
    email: data.email,
    phone: data.phone,
    first_name: data.first_name,
    last_name: data.last_name,
    dayofbirth: data.dayofbirth,
    country: data.country.country_short,
    city: data.city,
    state: data.state,
    postal_code: data.postal_code,
    street: data.street1,
  };
};

