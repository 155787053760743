import { useQuery } from 'react-query';
import { APIPath } from 'src/apis/api-path';
import { getKycStatus, getKycUrl } from 'src/apis/kyc';

export const useGetKycStatus = () =>
  useQuery([APIPath.GET_KYC_STATUS], getKycStatus, { retry: 0 });

type UseGetKycUrlProps = {
  enabled?: boolean;
};

export const useGetKycUrl = ({ enabled = true }: UseGetKycUrlProps) =>
  useQuery([APIPath.GET_KYC_URL], getKycUrl, {
    refetchOnWindowFocus: false,
    enabled,
  });

