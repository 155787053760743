import { enCommon, frCommon, svCommon } from './common';
export const en = {
  translation: {
    NoAccountWithThisEmailExists: 'No account with this email exists.',
    PleaseProvideYourEmailToReceivePaymentNotifications:
      'Please provide your email to receive payment notifications',
    WeveSentAVerificationCodeToYourEmail:
      "We've sent a verification code to your email",
    VerifyEmail: 'Verify Email',
    ...enCommon,
  },
};

export const fr = {
  translation: {
    NoAccountWithThisEmailExists: "Aucun compte avec cet e-mail n'existe.",
    PleaseProvideYourEmailToReceivePaymentNotifications:
      'Veuillez fournir votre e-mail pour recevoir les notifications de paiement',
    WeveSentAVerificationCodeToYourEmail:
      'Nous avons envoyé un code de vérification à votre adresse e-mail',
    VerifyEmail: 'Vérifier les courriels',
    ...frCommon,
  },
};

export const sv = {
  translation: {
    NoAccountWithThisEmailExists: "Det finns inget konto med denna email-adressen .",
    PleaseProvideYourEmailToReceivePaymentNotifications:
      'Logga in med din mailadress',
    WeveSentAVerificationCodeToYourEmail:
      'Vi har skickat ett en verifieringskod till din mail',
    VerifyEmail: 'Verifiera din email',
    ...svCommon,
  },
};
