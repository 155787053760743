import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { OTPInput } from 'src/components/Inputs';
import { useLoginStore } from 'src/stores/login';
import { useLoginConfirm } from 'src/hooks/queries/useAuth';
import { shortEmail } from 'src/utils/string';
import CardWrapper from 'src/components/Common/CardWrapper';
import { useTranslation } from 'react-i18next';

export default function VerifyOTP() {
  const { i18n, t } = useTranslation();
  const {
    formState: { email, phone },
  } = useLoginStore();
  const { mutate, isLoading } = useLoginConfirm();
  const [otp, setOtp] = useState('');

  const isDisabled = otp.length < 6;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate({ email: email as string, otp, phone: phone as string });
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardWrapper>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h4' fontWeight='bold' mt={2}>
            {t('VerifyEmail')}
          </Typography>
          <Typography
            mt={2}
            textAlign='center'
            color='action.active'
            fontWeight='medium'
            maxWidth='300px'
          >
            {t('WeveSentAVerificationCodeToYourEmail')}{' '}
            {shortEmail(email as string)}`
          </Typography>
        </Box>
        <Box mt={2} display='flex' justifyContent='center'>
          <OTPInput value={otp} onChange={setOtp} numInputs={6} isInputNum />
        </Box>
        <Typography
          display='block'
          textAlign='center'
          variant='caption'
          fontWeight='medium'
          mt={2}
        >
          {t('ByEnteringOTPYouAgreeWithTermsAndPrivacyPolicy')}
        </Typography>
        <Box display='flex' justifyContent='center' mt={2}>
          <LoadingButton
            variant='contained'
            type='submit'
            disabled={isDisabled}
            loading={isLoading}
            fullWidth
          >
            {t('Continue')}
          </LoadingButton>
        </Box>
      </CardWrapper>
    </form>
  );
}

