import React, { useEffect, useState } from 'react';
import { Box, Typography, Stack, Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import _, { set } from 'lodash';

import CardWrapper from 'src/components/Common/CardWrapper';
import Brand from '../SubOptions/Brand';
import { Option } from 'src/Types/Currency';
import { MASTER_CARD, CELL_PHONE, BANK } from 'src/constants/images';
import { useTranslation } from 'react-i18next';
import AddNewCard from 'src/Features/BuyCrypto/AddNewCard';
import MobilePayment from 'src/Features/BuyCrypto/MobilePayment';
import BankTransfer from '../BankTransfer';

export const PaymentOptions = ({ options }: { options: Option[] }) => {
  const { i18n, t } = useTranslation();
  const [selectedId, setSelectedId] = useState('');
  const [submittedOption, setSubmittedOption] = useState('');
  const handleChangeOption = (id: string) => () => {
    setSelectedId(id);
  };
  const mapIcon = (name: String) => {
    switch (name) {
      case 'Bank Transfer':
        return BANK;
      case 'Mobile':
        return CELL_PHONE;
      case 'Card':
        return MASTER_CARD;
      default:
        return '';
    }
  };

  const handleSubmit = () => {
    setSubmittedOption(selectedId);
  };
  if (!submittedOption || submittedOption == '') {
    return (
      <CardWrapper>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h4' fontWeight='bold' mb={1}>
            {t('selectPaymentOption')}
          </Typography>
        </Box>
        <Stack spacing={2} mt={4}>
          <Stack spacing={2} mt={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {options?.map((item) => (
                <Brand
                  key={item.id}
                  title={item.name}
                  id={item.id}
                  selectedId={selectedId}
                  onClick={handleChangeOption}
                  image={mapIcon(item.name)}
                />
              ))}
            </Box>
          </Stack>
        </Stack>
        <Box display='flex' justifyContent='center' mt={2}>
          <LoadingButton
            variant='contained'
            type='submit'
            fullWidth
            onClick={handleSubmit}
          >
            {t('Continue')}
          </LoadingButton>
        </Box>
      </CardWrapper>
    );
  } else {
    const selectedOption = options.find(
      (op: Option) => op.id === submittedOption
    );
    switch (selectedOption?.name) {
      case 'Bank Transfer':
        return <BankTransfer />;
      case 'Mobile':
        return <MobilePayment />;
      case 'Card':
        return <AddNewCard />;
      default:
        return null;
    }
  }
};

export default PaymentOptions;
