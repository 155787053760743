import React from 'react';
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Box,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';

import { useGlobalStore } from 'src/stores';
import { RoutePath } from 'src/routes/path';
import { AccountKycStatus } from 'src/Features/core/Common';
import { useGetProfile } from 'src/hooks/queries/useAuth';
import { useGetKycStatus } from 'src/hooks/queries/useKyc';
import { KycStatus } from 'src/Types/Kyc';
import _ from 'lodash';
import { getFullName } from 'src/utils/string';

const UserAvatar = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const { logout } = useGlobalStore();
  const { data: userProfile } = useGetProfile();
  const { data: kycStatus } = useGetKycStatus();
  const history = useHistory();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    logout();
  };

  const gotoProfilePage = () => {
    history.push(RoutePath.PROFILE);
  };

  return (
    <Box>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        PaperProps={{
          style: {
            width: 220,
          },
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <Box p={2}>
          <Box p={1} border="1px solid" borderColor="divider" borderRadius={2}>
            <Typography fontSize={12} noWrap fontWeight="bold">
              {getFullName(userProfile)}
            </Typography>
            <Typography fontSize={12} noWrap>
              {userProfile?.email}
            </Typography>

          </Box>
        </Box>
        <MenuItem onClick={gotoProfilePage}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserAvatar;
