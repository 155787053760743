import { Box } from '@mui/material';
import React from 'react';
import { useLoginStore } from 'src/stores/login';

import CheckEmail from './CheckEmail';
import VerifyOTP from './VerifyOTP';
import { getEnv, envEnum } from 'src/utils/env';
import CheckPhone from './CheckPhone';
import { useSettingsStore } from 'src/stores/settings';

const LoginWithEmail = () => {
  const { step } = useLoginStore();
  return (
    <Box maxWidth='xs' width='100%' sx={{ float: 'right' }}>
      {step === 1 ? <CheckEmail /> : <VerifyOTP />}
    </Box>
  );
};

const LoginWithPhone = () => {
  const { step } = useLoginStore();
  return (
    <Box maxWidth='xs' width='100%' sx={{ float: 'right' }}>
      {step === 1 ? <CheckPhone /> : <VerifyOTP />}
    </Box>
  );
};

const Login = () => {
  const version = getEnv(envEnum.VERSION);
  const { authenticationMethod } = useSettingsStore();
  return authenticationMethod === 'email' ? (
    <LoginWithEmail />
  ) : (
    <LoginWithPhone />
  );
};

export default Login;

