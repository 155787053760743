import React, { useEffect } from 'react';
import { Box, Typography, Stack, Modal, CircularProgress } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

import { TextInput } from 'src/components/Inputs';
import { getError, getErrorMessage } from 'src/utils/common';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { usePaymentConfirm, usePayment } from 'src/hooks/queries/useCurrency';
import CardWrapper from 'src/components/Common/CardWrapper';
import Brand from '../SubOptions/Brand';
import { CountryPhoneInput } from 'src/components/Inputs';
import { useTranslation } from 'react-i18next';
import { getCountry } from 'src/utils/localStorage';
import { useHistory } from 'react-router';
import { RoutePath } from 'src/routes/path';

const formSchema = yup.object().shape({
  phone: yup.string().required('Required'),
  sub_option: yup.string().required('Required'),
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export const CISubOptions = () => {
  const { t } = useTranslation();
  const [formInputs, setFormInputs] = React.useState<any>([]);
  const [instructions, setInstructions] = React.useState('');
  const [selectedId, setSelectedId] = React.useState<string>('');
  const history = useHistory();
  const country = getCountry();
  const {
    transactionState: { id, subOptions },
  } = useBuyCurrencyStore();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      history.push(
        `${RoutePath.TRANSACTION_DETAILS.replace(':id', id as string)}`
      );
    }, 1000);
  };

  const { mutate: confirm, isLoading } = usePaymentConfirm();
  const { mutate: payment, isLoading: getOptionLoading } = usePayment();

  const { register, formState, handleSubmit, control, watch, setValue } =
    useForm({
      resolver: yupResolver(formSchema),
    });
  const watchOption = watch('sub_option');

  const handleSubmitForm = (values: any) => {
    confirm(
      {
        ...values,
        phone_number: values.phone,
        payment_id: id as string,
        payment_option: 'Mobile',
      },
      {
        onSuccess: (data) => {
          setInstructions(_.get(data, 'result.paymentDetails[0].value', ''));
          handleOpen();
        },
      }
    );
  };

  useEffect(() => {
    if (watchOption) {
      const subOption = subOptions?.find((item) => item.id === watchOption);
      setFormInputs(_.get(subOption, 'formInputs', []));
    }
  }, [watchOption]);

  useEffect(() => {
    if (id)
      payment({
        expiration_date: '',
        cvv: '',
        card_holder_name: '',
        card_number: '',
        country: 'CI',
        state: 'Mock state',
        zip: '700000',
        city: 'Mock city',
        address: 'Mock address',
        phone_number: '0',
        payment_id: id as string,
        payment_option: 'Mobile',
      });
  }, [id]);

  const handleBrandChange = (id: string) => () => {
    setSelectedId(id);
    setValue('sub_option', id);
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <CardWrapper>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h4' fontWeight='bold' mb={1}>
            {t('MobilePayment')}
          </Typography>
        </Box>
        <Stack spacing={2} mt={4}>
          <Typography textAlign='center' color='grey.400' fontWeight='bold'>
            {t('PaymentDetails')}
          </Typography>
          {subOptions?.length === 0 && (
            <Box display='flex' flexDirection='column' alignItems={'center'}>
              <CircularProgress />
              <Typography mb={1} color='grey.400'>
                {t('fetchingPaymentOption')}
              </Typography>
            </Box>
          )}
          {subOptions?.length != 0 && (
            <Stack spacing={2} mt={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {subOptions?.map((item) => (
                  <Brand
                    key={item.id}
                    title={item.name}
                    id={item.id}
                    selectedId={selectedId}
                    onClick={handleBrandChange}
                    image={item.logo}
                  />
                ))}
              </Box>
              {formInputs?.map((item: any) => {
                if (item.name == 'phone') {
                  return (
                    <CountryPhoneInput
                      country={'CI'}
                      label={t('phoneNumber')}
                      error={getError(formState, 'phone')}
                      message={getErrorMessage(formState, 'phone')}
                      control={control}
                      {...register('phone')}
                    />
                  );
                }
                if (item.name == 'voucher') {
                  return (
                    <TextInput
                      label={item.label}
                      required
                      {...register('voucher')}
                    />
                  );
                }
              })}
            </Stack>
          )}
        </Stack>
        <Box display='flex' justifyContent='center' mt={2}>
          <LoadingButton
            variant='contained'
            type='submit'
            loading={isLoading}
            fullWidth
            disabled={subOptions?.length === 0}
          >
            {t('payment')}
          </LoadingButton>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              {t('instructions')}
            </Typography>
            <Typography id='modal-modal-description' sx={{ mt: 2 }}>
              {instructions}
            </Typography>
          </Box>
        </Modal>
      </CardWrapper>
    </form>
  );
};

export default CISubOptions;
