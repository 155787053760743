import React, { useState } from 'react';
import { LightModeOutlined, Brightness4Outlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useGlobalStore } from 'src/stores';
import { DialogConfirm } from 'src/components/Common/DialogConfirm';

const Mode = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { mode, switchMode } = useGlobalStore();

  const handleShowComfirm = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSwitchMode = () => {
    handleClose();
    switchMode();
    window.location.reload();
  };

  return (
    <>
      <IconButton onClick={handleShowComfirm}>
        {mode === 'light' ? <Brightness4Outlined /> : <LightModeOutlined /> }
      </IconButton>
      <DialogConfirm
        open={isOpen}
        onCancel={handleClose}
        onAccept={handleSwitchMode}
        contentText="It's will reload the page"
      />
    </>
  );
};

export default Mode;
