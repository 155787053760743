import * as React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { formatCurrency } from 'src/utils/string';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { usePreparePayment } from 'src/hooks/queries/useCurrency';
import { getCountry } from 'src/utils/localStorage';
type PaymentDetails = {
  label: string;
  value: string;
};
type PaymentDetailsProps = {
  values: PaymentDetails[];
};
type RePaymentModalProps = DialogProps & {
  onCancel: () => void;
  onAccept: (id: string) => void;
  paymentDetails: PaymentDetails[];
  isLoading: boolean;
};

const RenderPaymentDetails = ({ values }: PaymentDetailsProps) => {
  return (
    <>
      {values.map((value: any) => (
        <>
          <Grid item xs={6}>
            <Typography>{value.label}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight='bold'>{value.value}</Typography>
          </Grid>
        </>
      ))}
    </>
  );
};

export const RePaymentModal = ({
  onCancel,
  onAccept,
  paymentDetails,
  isLoading,
  ...props
}: RePaymentModalProps) => {
  const {
    transactionState: { spend, receive, from_currency, to_currency, network },
    formState: { walletAddress },
  } = useBuyCurrencyStore();
  const { mutate: prepare, isLoading: isPrepareLoading } = usePreparePayment();
  const country = getCountry();

  const handleRepayment = () => {
    prepare(
      {
        from_currency: from_currency as string,
        to_currency: to_currency as string,
        network: network as string,
        address: walletAddress as string,
        amount: spend as number,
        country: country as string,
      },
      {
        onSuccess: (data) => {
          onAccept(data.id);
        },
      }
    );
  };

  return (
    <Dialog
      maxWidth='md'
      PaperProps={{ sx: { borderRadius: '20px' } }}
      {...props}
    >
      <DialogTitle variant='button'>Re-Submit Payment</DialogTitle>
      <DialogContent sx={{ width: '720px' }}>
        <Typography mb={1} color={'error'}>
          Request could not be completed.
        </Typography>
        <Typography fontWeight='bold'>
          Do you want to re-submit the payment?
        </Typography>
        <Typography display={'inline'}>You're paying </Typography>
        <Typography display={'inline'} color={'primary'}>
          {parseFloat(`${spend}`)} {from_currency}
        </Typography>
        <Typography display={'inline'}> for </Typography>
        <Typography display={'inline'} color={'primary'}>
          {parseFloat(`${receive}`)} {to_currency}
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight='bold' fontSize={'small'}>
                Spend:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Fiat Amount</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>
                {formatCurrency(parseFloat(`${spend}`), from_currency || '')}
              </Typography>
            </Grid>
            <RenderPaymentDetails values={paymentDetails} />

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight='bold' fontSize={'small'}>
                Receive:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Token</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>USDT (TRC20)</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Address</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight='bold' fontSize={'small'} noWrap>
                {walletAddress}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box display='flex' mt={4} gap={1}>
          <LoadingButton onClick={onCancel} variant='outlined' size='small'>
            Cancel
          </LoadingButton>
          <LoadingButton
            onClick={handleRepayment}
            variant='outlined'
            size='small'
            loading={isPrepareLoading || isLoading}
            color='success'
          >
            Submit Payment
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
