import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';

import { CountryPhoneInput, TextInput } from 'src/components/Inputs';
import { getError, getErrorMessage } from 'src/utils/common';
import { CreateAccount } from 'src/Types/Auth';
import { useCreateAccount } from 'src/hooks/queries/useAuth';
import { isValidPhoneNumber } from 'react-phone-number-input';
import CardWrapper from 'src/components/Common/CardWrapper';
import { useTranslation } from 'react-i18next';
import { useLoginStore } from 'src/stores/login';
import { getCountry } from 'src/utils/localStorage';
import { RoutePath } from 'src/routes/path';
import { useHistory, useLocation } from 'react-router';

const formSchema = yup.object().shape({
  email: yup.string().email('Email is invalid').required('Required'),
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  phone: yup
    .string()
    .nullable()
    .required('Required')
    .test('phone', 'Invalid phone number', (phone) =>
      isValidPhoneNumber(phone || '')
    ),
  checked: yup.boolean().oneOf([true], 'Required'),
});

const RegisterWithEmail = () => {
  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const prefillEmail = searchParams.get('email');
  const refId = searchParams.get('refId');
  const { i18n, t } = useTranslation();
  const country = getCountry();
  const { register, handleSubmit, formState, control, setValue, watch } =
    useForm<CreateAccount>({
      resolver: yupResolver(formSchema),
      defaultValues: {
        country: '',
        type: 'ID',
        dayofbirth: null,
        phone: '',
      },
      mode: 'all',
    });

  const checked = watch('checked');

  const { mutate, isLoading } = useCreateAccount();
  const handleSubmitForm = async (values: CreateAccount) => {
    mutate(
      { ...values, ref_id: refId },
      {
        onSuccess: () => {
          setTimeout(() => {
            history.push(`${RoutePath.LOGIN}?email=${values.email}`);
          }, 1000);
        },
      }
    );
  };

  useEffect(() => {
    if (prefillEmail) {
      let _email = prefillEmail.trim();

      setValue('email', _email);
    }
  }, [prefillEmail]);

  return (
    <Box maxWidth='sm' sx={{ mx: 'auto' }}>
      <CardWrapper>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='h4' fontWeight='bold' my={2}>
              {t('RegisterAccount')}
            </Typography>
          </Box>
          <Stack spacing={2}>
            <TextInput
              label={t('email')}
              placeholder={t('EnterYourEmail')}
              error={getError(formState, 'email')}
              message={getErrorMessage(formState, 'email')}
              {...register('email')}
            />
            <Box display='flex' gap={2}>
              <TextInput
                label={t('firstName')}
                placeholder={t('enterYourFirstName')}
                error={getError(formState, 'first_name')}
                message={getErrorMessage(formState, 'first_name')}
                {...register('first_name')}
              />
              <TextInput
                label={t('lastName')}
                placeholder={t('enterYourLastName')}
                error={getError(formState, 'last_name')}
                message={getErrorMessage(formState, 'last_name')}
                {...register('last_name')}
              />
            </Box>
            <Box display='flex' gap={2}>
              <CountryPhoneInput
                country={country || ''}
                label={t('phoneNumber')}
                error={getError(formState, 'phone')}
                message={getErrorMessage(formState, 'phone')}
                control={control}
                {...register('phone')}
              />
            </Box>
          </Stack>
          <Box display='flex' justifyContent='center' mt={2}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...register('checked')} />}
                label={
                  <Typography color='action.active' fontWeight='medium'>
                    {t('IAgreeBitmambo')}{' '}
                    <Typography
                      component='span'
                      color='primary'
                      fontWeight='medium'
                    >
                      {t('TermsOfUse')}
                    </Typography>
                  </Typography>
                }
              />
            </FormGroup>
          </Box>
          <Box display='flex' justifyContent='center' mt={2}>
            <LoadingButton
              variant='contained'
              type='submit'
              loading={isLoading}
              fullWidth
              disabled={!checked}
            >
              {t('Continue')}
            </LoadingButton>
          </Box>
        </form>
      </CardWrapper>
    </Box>
  );
};

export default RegisterWithEmail;
