import {
  Box, BoxProps, Chip, Typography,
} from '@mui/material';
import React from 'react';
import { formatPrice } from 'src/utils/number';

type Props = BoxProps & {
  price: number;
  name: string;
  logo: string;
  priceChange: number;
}

export const CoinPrice = ({
  price, name, logo, priceChange, ...props
}: Props) => {
  const percentage = formatPrice((priceChange / price) * 100);
  const formatPriceChange = formatPrice(priceChange * (priceChange > 0 ? 1 : -1));
  return (
    <Box
      display="flex"
      p={2}
      bgcolor="#3233ffab"
      borderRadius={4}
      gap={2}
      boxShadow={6}
      {...props}
    >
      <img src={logo} alt="logo" width="54px" />
      <Box>
        <Box display="flex" gap={1} color="white">
          <Typography fontWeight="bold">{name}</Typography>
          <Typography fontWeight="bold">
            $
            {price}
          </Typography>
        </Box>
        <Chip
          sx={{ color: priceChange > 0 ? '#1ed01e' : 'error.main', fontWeight: 'bold', bgcolor: 'white' }}
          label={`${priceChange > 0 ? '+' : ''}${percentage}% $${formatPriceChange}`}
          size="small"
        />
      </Box>
    </Box>
  );
};
