import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Stack,
  InputAdornment,
  SelectChangeEvent,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import _, { set } from 'lodash';

import { TextInput } from 'src/components/Inputs';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { useGetFee, useGetPairs } from 'src/hooks/queries/useCurrency';
import { toastWarning } from 'src/utils/toast';
import CardWrapper from 'src/components/Common/CardWrapper';
import { PaymentLogos } from 'src/Features/core/Common';
import SelectCurrency from './SelectCurrency';
import SelectCrypto from './SelectCrypto';
import { useTranslation } from 'react-i18next';
import SelectCountry from 'src/Features/SelectCountry';
import { getCountry } from 'src/utils/localStorage';
import { getEnv, envEnum } from 'src/utils/env';
export default function BuyCurrency() {
  const { i18n, t } = useTranslation();

  const [fetching, setFetching] = useState(false);
  const country = getCountry();

  const { data: pairsData, refetch } = useGetPairs();

  const {
    onNext,
    setTransactionState,
    transactionState: {
      receive,
      spend,
      from_currency,
      to_currency,
      to_currency_id,
      currency,
      network,
      minPayment,
      maxPayment,
      serviceFee,
      networkFee,
      networkFeeInUSD,
    },
  } = useBuyCurrencyStore();

  useGetFee({
    currency: from_currency,
    token: to_currency,
    network,
    country,
    version: getEnv(envEnum.VERSION),
  });

  const pairOfStrings = _.get(pairsData, 'pairOfStrings', []);
  let currencies = _.get(pairsData, `pairs.${from_currency}`, []);

  const price = _.get(currency, 'price', 0);
  const isDisableInput = !to_currency;

  const handleChangeFiat = (e: SelectChangeEvent<string>) => {
    const _currencies = _.get(pairsData, `pairs.${e.target.value}`, []);
    const _currency = _.find(
      _currencies,
      (item) => _.get(item, 'short_name', '') === to_currency
    );
    const _price = _.get(_currency, 'price', 0);
    currencies = _currencies;

    const _receive = spend
      ? _.round(
          (Number(spend) -
            (Number(spend) * (serviceFee || 1)) / 100 -
            Number(networkFee)) /
            _price,
          8
        )
      : '';
    setTransactionState({
      from_currency: e.target.value,
      receive: _receive,
      currency: _currency,
      minPayment: _.get(_currency, 'min_pay', 0),
      maxPayment: _.get(_currency, 'max_pay', 10000),
    });
  };

  const handleChangeCrypto = (e: SelectChangeEvent<string>) => {
    const currency = _.find(
      currencies,
      (item) => _.get(item, 'id', '') === e.target.value
    );
    const code = _.get(currency, 'short_name');
    const networkCode = _.get(currency, 'network');
    setTransactionState({
      to_currency: code,
      to_currency_id: e.target.value,
      currency,
      network: networkCode,
    });
  };

  const handleChangeSpend = (value: string) => {
    const receive = value
      ? _.round(
          (Number(value) -
            (Number(value) * (serviceFee || 1)) / 100 -
            Number(networkFee)) /
            price,
          8
        )
      : '';
    setTransactionState({ receive, spend: value });
  };

  const handleChangeReceive = (value: string) => {
    const payWithFee =
      (Number(value) * price + Number(networkFee)) /
      (1 - Number(serviceFee) / 100);

    const spend = value ? _.round(payWithFee, 8) : '';
    setTransactionState({ spend, receive: value });
  };

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      Number(spend) < (minPayment || 0) ||
      Number(spend) > (maxPayment || 1000)
    ) {
      toastWarning({
        description: `Spend must be greater than ${minPayment} and smaller than ${maxPayment}.`,
      });
      return;
    }
    if (receive && parseFloat(`${receive}`) <= 1) {
      toastWarning({
        description: t('spTooLow'),
      });
      return;
    }
    onNext();
  };

  useEffect(() => {
    const {
      transactionState: { to_currency_id, spend },
    } = useBuyCurrencyStore.getState();
    if (!from_currency && !to_currency) {
      const pairOfStrings = _.get(pairsData, 'pairOfStrings', []);
      const currencies = _.get(pairsData, `pairs.${pairOfStrings[0]}`, []);
      const currency = currencies[0];
      const code = _.get(currency, 'short_name');
      const networkCode = _.get(currency, 'network');
      const to_currency_id = _.get(currency, 'id');

      setTransactionState({
        from_currency: pairOfStrings[0],
        to_currency: code,
        to_currency_id,
        currency,
        network: networkCode,
        minPayment: _.get(currency, 'min_pay', 0),
        maxPayment: _.get(currency, 'max_pay', 10000),
      });
    }

    if (to_currency_id && spend && serviceFee && networkFee) {
      const currencies = _.get(pairsData, `pairs.${from_currency}`, []);
      const currency = _.find(
        currencies,
        (item) => _.get(item, 'id', '') === to_currency_id
      );
      const price = _.get(currency, 'price', 0);
      const receive = _.round(
        (Number(spend) -
          (Number(spend) * (serviceFee || 1)) / 100 -
          Number(networkFee)) /
          price,
        8
      );

      setTransactionState({ currency, receive });
    }
  }, [pairsData]);

  const isDisableSubmit = !(
    spend && (receive && parseFloat(`${receive}`) > 0 ? true : false)
  );

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const refetchPairs = async (value: string) => {
    setFetching(true);
    await sleep(1000);
    refetch().then(({ data }) => {
      const pairOfStrings = _.get(data, 'pairOfStrings', []);
      const currencies = _.get(data, `pairs.${pairOfStrings[0]}`, []);
      const currency = currencies[0];
      const code = _.get(currency, 'short_name');
      const networkCode = _.get(currency, 'network');
      const to_currency_id = _.get(currency, 'id');

      setTransactionState({
        from_currency: pairOfStrings[0],
        to_currency: code,
        to_currency_id,
        currency,
        network: networkCode,
        minPayment: _.get(currency, 'min_pay', 0),
        maxPayment: _.get(currency, 'max_pay', 10000),
      });
    });
    setFetching(false);
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={fetching}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <CardWrapper>
        <SelectCountry onChange={refetchPairs} />
        <form onSubmit={handleSubmitForm}>
          <Stack spacing={2}>
            <Typography
              fontWeight='bold'
              textAlign='center'
              mb={2}
              fontSize={{ base: 24, xs: 20, sm: 32 }}
            >
              {t('BuyCrypto')}
            </Typography>
            <TextInput
              placeholder='0.00'
              sx={{ paddingRight: 1 }}
              type='number'
              label={t('YouSpend')}
              disabled={isDisableInput}
              endAdornment={
                <InputAdornment position='end'>
                  <SelectCurrency
                    value={from_currency}
                    pairOfStrings={pairOfStrings}
                    onChange={handleChangeFiat}
                  />
                </InputAdornment>
              }
              value={spend}
              onChange={(e) => handleChangeSpend(e.target.value)}
            />
            <TextInput
              placeholder='0.00'
              sx={{ paddingRight: 1 }}
              type='number'
              label={t('YouReceive')}
              endAdornment={
                <InputAdornment position='end'>
                  <SelectCrypto
                    value={to_currency_id}
                    currencies={currencies}
                    onChange={handleChangeCrypto}
                  />
                </InputAdornment>
              }
              value={receive && parseFloat(`${receive}`) > 0 ? receive : 0}
              onChange={(e) => handleChangeReceive(e.target.value)}
            />
            {to_currency && (
              <Box>
                <Box display='flex'>
                  <Typography mr={1}>{t('YouWillReceive')}</Typography>
                  <Typography fontWeight='bold'>
                    {`${
                      receive && parseFloat(`${receive}`) > 0 ? receive : 0
                    } ${to_currency}`}
                  </Typography>
                </Box>
                <Box display='flex'>
                  <Typography mr={1}>{t('transactionFee')}</Typography>
                  <Typography fontWeight='bold'>
                    {`${networkFeeInUSD || 0} USD`}
                  </Typography>
                </Box>
              </Box>
            )}
          </Stack>
          <Box display='flex' justifyContent='center' mt={5}>
            <Button
              variant='contained'
              type='submit'
              disabled={isDisableSubmit}
              fullWidth
            >
              {t('Continue')}
            </Button>
          </Box>

          <PaymentLogos containerProps={{ justifyContent: 'center', mt: 4 }} />
        </form>
      </CardWrapper>
    </Box>
  );
}

