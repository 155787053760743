import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import {
  Box, Button, Card, Container, Drawer, Link, Stack, Typography,
} from '@mui/material';
import { RoutePath } from 'src/routes/path';
import { Link as RouterLink } from 'react-router-dom';

type LegalLayoutProps = {
  children: ReactNode
}

export const LegalLayout = ({ children }: LegalLayoutProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderMenu = () => (
    <>
      <Typography variant="button" display="block" mb={2}>
        LEGAL
      </Typography>
      <Stack>
        <Link
          to={RoutePath.PRIVACY}
          component={RouterLink}
        >
          Privacy
        </Link>
        <Link
          to={RoutePath.USER_AGREEMENT}
          component={RouterLink}
        >
          User agreement
        </Link>
        <Link
          to={RoutePath.COOKIE_POLICY}
          component={RouterLink}
        >
          Cookie policy
        </Link>
      </Stack>
    </>
  );

  return (
    <Container maxWidth="lg">
      <Box
        mb={2}
        display={{
          xs: 'block', sm: 'block', md: 'block', lg: 'none',
        }}
      >
        <Button fullWidth variant="contained" onClick={() => setIsOpen(true)}>
          LEGAL
        </Button>
      </Box>
      <Wrapper>
        <Box
          width="200px"
          flexShrink={0}
          display={{
            xs: 'none', sm: 'none', md: 'none', lg: 'block',
          }}
        >
          {renderMenu()}
        </Box>
        <Box flex={1} width="100%">
          <Card sx={{ borderRadius: 4 }}>
            <Box sx={{ px: 4, py: 6 }}>
              {children}
            </Box>
          </Card>
        </Box>
        <Drawer anchor="left" open={isOpen} onClose={() => setIsOpen(false)}>
          <Box p={4} width="260px">
            {renderMenu()}
          </Box>
        </Drawer>
      </Wrapper>
    </Container>
  );
};

const Privacy = () => (
  <LegalLayout>
    <Box>
      <div>
        <div>
          <Typography textAlign="center" fontWeight="bold" fontSize={24}>PRIVACY POLICY&nbsp;</Typography>
        </div>
        <Box mt={4}>
          <div>
            <p>
              Global Wallet E.O.O.D., a company registered in Bulgaria, operates
              the BitMambo cryptocurrency exchange. Your privacy is essential to
              us, and this Privacy Policy outlines the types of information we
              collect, how we use it, and the measures we take to protect it in
              compliance with the General Data Protection Regulation
              (GDPR).&nbsp;
            </p>
          </div>
          <div>
            <ol start={1} className="title">
              <li>
                <p>INFORMATION WE COLLECT&nbsp;</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              We may collect and process the following categories of personal
              information:&nbsp;
            </p>
          </div>
          <div>
            <ol start={1}>
              <li>
                <p>
                  Account information: This includes your name, email address,
                  date of birth, phone number, physical address, and
                  government-issued identification documents.&nbsp;
                </p>
              </li>
            </ol>
          </div>
        </Box>
        <div>
          <div>
            <ol start={2}>
              <li>
                <p>
                  Transaction information: This encompasses your transaction
                  history, including deposits, withdrawals, and trading
                  activity.&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={3}>
              <li>
                <p>
                  Payment information: We collect your bank account details and
                  credit card information when you use our services.&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={4}>
              <li>
                <p>
                  Technical information: This refers to data about your device,
                  IP address, browser type, operating system, and your
                  interaction with our website,&nbsp;
                  <a href="https://bitmambo.com/" target="_blank" rel="noreferrer noopener">
                    https://bitmambo.com
                  </a>
                  .&nbsp;
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={5}>
              <li>
                <p>
                  Communication information: We may collect data from your
                  correspondence with our support team or other communications
                  you have with us.&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={2} className="title">
              <li>
                <p>USE OF YOUR INFORMATION&nbsp;</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              We use the personal information we collect for the following
              purposes:&nbsp;
            </p>
          </div>
          <div>
            <ol start={1}>
              <li>
                <p>To provide, maintain, and improve our services.&nbsp;</p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={2}>
              <li>
                <p>
                  To comply with legal requirements, including anti-money
                  laundering (AML) and know-your-customer (KYC)
                  regulations.&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={3}>
              <li>
                <p>
                  To communicate with you, including providing updates,
                  promotional materials, and responding to
                  inquiries.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={4}>
              <li>
                <p>
                  To ensure the security of our platform and protect against
                  fraud.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={5}>
              <li>
                <p>
                  To analyze and understand user behavior to improve our
                  services.&nbsp;
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={3}>
              <li>
                <p>LEGAL BASIS FOR PROCESSING YOUR DATA&nbsp;</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              We process your personal data based on the following legal
              grounds:&nbsp;
            </p>
          </div>
          <div>
            <ol start={1}>
              <li>
                <p>
                  Contractual necessity: To enter into a contract with you and
                  fulfill our obligations under that contract.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={2}>
              <li>
                <p>
                  Legal obligations: To comply with applicable laws and
                  regulations, such as AML and KYC requirements.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={3}>
              <li>
                <p>
                  Legitimate interests: To improve our services, ensure the
                  security of our platform, and communicate with
                  you.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={4}>
              <li>
                <p>
                  Consent: In some cases, we may ask for your consent to process
                  your data for specific purposes.&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={4}>
              <li>
                <p>DATA RETENTION&nbsp;</p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <p>
              We retain your personal information for as long as necessary to
              fulfill the purposes for which it was collected, comply with legal
              obligations, or resolve disputes. After that period, we will
              securely delete or anonymize your data.&nbsp;
            </p>
          </div>
          <div>
            <ol start={5} className="title">
              <li>
                <p>YOUR RIGHTS&nbsp;</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              Under the GDPR, you have several rights regarding your personal
              data, including:&nbsp;
            </p>
          </div>
          <div>
            <ol start={1}>
              <li>
                <p>
                  Right to access: You have the right to request a copy of the
                  personal data we hold about you.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={2}>
              <li>
                <p>
                  Right to rectification: You can request that we correct any
                  inaccurate or incomplete information about you.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={3}>
              <li>
                <p>
                  Right to erasure: In certain circumstances, you may request
                  the deletion of your personal data.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={4}>
              <li>
                <p>
                  Right to restrict processing: You may ask us to restrict the
                  processing of your data in specific cases.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={5}>
              <li>
                <p>
                  Right to data portability: You have the right to request that
                  we transfer your data to another organization or directly to
                  you, where technically feasible.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={6}>
              <li>
                <p>
                  Right to object: You may object to our processing of your data
                  for direct marketing or other legitimate
                  interests.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={7}>
              <li>
                <p>
                  Right to withdraw consent: If we rely on your consent for
                  processing, you can withdraw it at any time.&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              To exercise any of these rights, please contact us
              at&nbsp;dpo@bitmambo.com.&nbsp;
            </p>
          </div>
          <div>
            <ol start={6} className="title">
              <li>
                <p>SHARING YOUR INFORMATION&nbsp;</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              We may share your personal information with third parties only for
              the following reasons:&nbsp;
            </p>
          </div>
          <div>
            <ol start={1}>
              <li>
                <p>
                  To comply with legal obligations or requests from law
                  enforcement authorities.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={2}>
              <li>
                <p>
                  To prevent fraud, protect our rights, or ensure the security
                  of our platform.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={3}>
              <li>
                <p>
                  With service providers that help us deliver our services, such
                  as payment processors or hosting providers. These third
                  parties must comply with strict confidentiality and data
                  protection obligations.&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={7} className="title">
              <li>
                <p>INTERNATIONAL DATA TRANSFERS&nbsp;</p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <p>
              Your data may be transferred to, stored, and processed in
              countries outside the European Economic Area (EEA). In such cases,
              we ensure that appropriate safeguards are in place to protect your
              data in accordance with the GDPR. These safeguards may
              include:&nbsp;
            </p>
          </div>
          <div>
            <ol start={1}>
              <li>
                <p>
                  Standard contractual clauses approved by the European
                  Commission.&nbsp;&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={2}>
              <li>
                <p>
                  Data processing agreements with our service providers that
                  adhere to GDPR requirements.&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={8} className="title">
              <li>
                <p>SECURITY&nbsp;</p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <p>
              We implement appropriate technical and organizational measures to
              protect your personal information from unauthorized access,
              disclosure, alteration, or destruction. This includes the use of
              encryption, secure servers, and access controls. However, no
              method of transmission or storage is completely secure, and while
              we strive to protect your data, we cannot guarantee its absolute
              security.&nbsp;
            </p>
          </div>
          <div>
            <ol start={9} className="title">
              <li>
                <p>COOKIES AND SIMILAR TECHNOLOGIES&nbsp;</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              We use cookies and similar technologies, such as web beacons and
              pixels, to collect and store information about your use of our
              website and services. Cookies help us recognize you, remember your
              preferences, and improve your experience on our platform. You can
              manage your cookie preferences in your browser settings; however,
              disabling cookies may affect the functionality of our
              services.&nbsp;
            </p>
          </div>
          <div>
            <ol start={10} className="title">
              <li>
                <p>THIRD-PARTY WEBSITES&nbsp;</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              Our website,&nbsp;
              <a href="https://bitmambo.com/" target="_blank" rel="noreferrer noopener">
                https://bitmambo.com
              </a>
              , may contain links to third-party websites or services. We are
              not responsible for the privacy practices of those websites or
              services, and this Privacy Policy does not apply to them. We
              encourage you to review the privacy policies of any third-party
              websites you visit.&nbsp;
            </p>
          </div>
        </div>
        <div>
          <div>
            <ol start={11} className="title">
              <li>
                <p>CHANGES TO THIS PRIVACY POLICY&nbsp;</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              We may update this Privacy Policy from time to time. If we make
              significant changes, we will notify you by email or by posting a
              notice on our website. Your continued use of our services after
              any changes constitutes your acceptance of the updated Privacy
              Policy.&nbsp;
            </p>
          </div>
          <div>
            <ol start={12} className="title">
              <li>
                <p>CONTACT US&nbsp;</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our handling of your personal information, please contact us
              at&nbsp;support@bitmambo.com.&nbsp;
            </p>
          </div>
        </div>
      </div>
    </Box>
  </LegalLayout>
);

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  p, ol {
    margin: 0;
  }

  ol {
    margin-left: 0;
    padding-left: 20px;
  }

  ol[start="10"], ol[start="11"], ol[start="12"] {
    padding-left: 28px;
  }

  ol > li > p {
  }

  
  .title {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: bold;
  }

`;

export default Privacy;
