import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppRoutes from 'src/routes';
import GlobalProvider from 'src/providers/GlobalProvider';

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <CssBaseline />
        <ToastContainer />
        <AppRoutes />
      </GlobalProvider>
    </QueryClientProvider>
  );
}

