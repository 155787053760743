import React, { ReactNode, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Drawer,
  Link,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { RoutePath } from 'src/routes/path';
import { useTranslation } from 'react-i18next';

type LegalLayoutProps = {
  children: ReactNode;
};

const CompanyLayout = ({ children }: LegalLayoutProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n, t } = useTranslation();

  const renderMenu = () => (
    <>
      <Typography variant='button' display='block' mb={2}>
        COMPANY
      </Typography>
      <Link
        to={RoutePath.ABOUT_US}
        component={RouterLink}
        sx={{ textDecoration: 'none', color: 'text.primary', display: 'block' }}
      >
        {t('AboutUs')}
      </Link>
    </>
  );

  return (
    <Container maxWidth='lg'>
      <Box
        mb={2}
        display={{
          xs: 'block',
          sm: 'block',
          md: 'block',
          lg: 'none',
        }}
      >
        <Button fullWidth variant='contained' onClick={() => setIsOpen(true)}>
          COMPANY
        </Button>
      </Box>
      <Box display='flex'>
        <Box
          width='200px'
          flexShrink={0}
          display={{
            xs: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block',
          }}
        >
          {renderMenu()}
        </Box>
        <Box flex={1}>
          <Card sx={{ borderRadius: 4 }}>
            <Box sx={{ px: 4, py: 6 }}>{children}</Box>
          </Card>
        </Box>
        <Drawer anchor='left' open={isOpen} onClose={() => setIsOpen(false)}>
          <Box p={4} width='260px'>
            {renderMenu()}
          </Box>
        </Drawer>
      </Box>
    </Container>
  );
};

const AboutUS = () => (
  <CompanyLayout>
    <Typography fontWeight='bold' fontSize={24} textAlign='center'>
      About Us
    </Typography>
    <Typography mt={5}>
      Welcome to Bitmambo, the on-ramp cryptocurrency exchange designed to make
      digital assets simple and accessible for everyone. Our innovative platform
      connects the worlds of traditional finance and cryptocurrency, providing a
      user-friendly interface for purchasing and securely transferring
      cryptocurrencies to personal addresses.
    </Typography>
    <Typography mt={2}>
      At Bitmambo, our mission is to empower individuals by offering an easy,
      fast, and secure gateway to the world of digital assets. Our intuitive
      platform enables even those new to the crypto sphere to navigate the
      process with confidence and trust, making cryptocurrency more accessible
      for all.
    </Typography>
    <Typography mt={2}>
      Security, transparency, and compliance are at the heart of everything we
      do. Our dedicated team of industry experts works diligently to ensure your
      transactions and personal information remain protected. We pride ourselves
      on maintaining the highest standards of service and fostering a diverse,
      inclusive global crypto community.
    </Typography>
    <Typography mt={2}>
      Join us on our journey as we shape the future of finance together. Connect
      with Bitmambo on LinkedIn to stay informed about our latest developments,
      news, and opportunities. Welcome to the Bitmambo family!
    </Typography>
  </CompanyLayout>
);

export default AboutUS;

