/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Box, Typography, Stack, Paper, Chip, Grid } from '@mui/material';
import _ from 'lodash';
import { ReceiptLongOutlined } from '@mui/icons-material';

import { useGetTransactionDetails } from 'src/hooks/queries/useCurrency';
import { TransactionStatus } from 'src/Types/Currency';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { colors } from 'src/constants/transactionStatus';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { parseTokenName } from 'src/utils/string';

const StatusChip = ({ status }: { status: TransactionStatus }) => {
  const { t } = useTranslation();
  const color = colors[status]?.replace('.main', '');
  return <Chip color={color as any} label={t(status)} />;
};

const Item = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box display='flex' mt={2}>
      <Typography variant='body1' color='text.secondary'>
        {label}:
      </Typography>
      <Typography variant='body1' color='text.primary' fontWeight={'bold'}>
        &nbsp;&nbsp;
        {value}
      </Typography>
    </Box>
  );
};

export default function TransactionDetails() {
  const { t } = useTranslation();
  const params = useParams();
  const id = _.get(params, 'id', '');
  const { data } = useGetTransactionDetails(id);
  const { resetStore } = useBuyCurrencyStore();

  useEffect(() => {
    resetStore();
  }, []);

  return (
    <Box>
      <Typography
        mt={2}
        mb={2}
        display='flex'
        alignItems='center'
        justifyContent='center'
        variant='button'
        fontSize={20}
      >
        <ReceiptLongOutlined sx={{ mr: 2 }} />
        {t('transactionHistory')}
      </Typography>
      <Typography
        display='flex'
        alignItems='center'
        justifyContent='center'
        variant='button'
        fontSize={16}
      >
        {id}
      </Typography>
      <Stack
        direction='row'
        spacing={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <StatusChip status={data?.status as any} />
      </Stack>
      <Box maxWidth='xs' sx={{ mt: 5, mx: 'auto' }}>
        <Paper
          sx={{
            p: {
              base: 5,
              xs: 3,
              sm: 3,
              md: 3,
              lg: 5,
            },
            borderRadius: '20px',
          }}
        >
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6} sm={12}>
                <Typography variant='h6' color='text.primary'>
                  {t('from')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sm={12}>
                <Typography variant='h6' color='text.primary'>
                  {t('to')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sm={12}>
                <Item
                  label={t('currency')}
                  value={_.get(data, 'payment_details.from_currency', 'N/A')}
                />
                <Item
                  label={t('amount')}
                  value={_.get(data, 'payment_details.from_amount', 'N/A')}
                />
                {_.get(data, 'payment_details.mobile', null) !== 'null' && (
                  <>
                    <Item
                      label={t('phoneNumber')}
                      value={_.get(
                        data,
                        'payment_details.mobile.phone_number',
                        'N/A'
                      )}
                    />
                    <Item
                      label={t('voucher')}
                      value={_.get(
                        data,
                        'payment_details.mobile.voucher',
                        'N/A'
                      )}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={6} md={6} sm={12}>
                <Item
                  label={t('token')}
                  value={parseTokenName(
                    _.get(data, 'payment_details.to_currency', 'N/A'),
                    _.get(data, 'payment_details.network', 'N/A')
                  )}
                />
                <Item
                  label={t('amount')}
                  value={_.get(data, 'payment_details.to_amount', 'N/A')}
                />
                <Item
                  label={t('network')}
                  value={_.get(data, 'payment_details.network', 'N/A')}
                />
                <Item
                  label={t('address')}
                  value={_.get(data, 'payment_details.address', 'N/A')}
                />
              </Grid>
              <Grid item xs={12} md={12} sm={12} mt={2}>
                <Typography variant='h6' color='text.primary'>
                  {t('fees')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Item
                  label={t('networkFee')}
                  value={`${_.get(data, 'fees.tx_fee.fee', 'N/A')} ${_.get(
                    data,
                    'fees.tx_fee.unit',
                    'N/A'
                  )}`}
                />
              </Grid>
            </Grid>
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
}
