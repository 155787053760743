import React from 'react';
import RegisterWithEmail from './RegisterWithEmail';
import RegisterWithPhone from './RegisterWithPhone';
import { getEnv, envEnum } from 'src/utils/env';
import { useSettingsStore } from 'src/stores/settings';

const Register = () => {
  const { authenticationMethod } = useSettingsStore();
  return authenticationMethod === 'email' ? (
    <RegisterWithEmail />
  ) : (
    <RegisterWithPhone />
  );
};

export default Register;

