import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

export const CustomLink = (props: LinkProps) => {
  const location = useLocation();
  const { to, children } = props;

  if (location.pathname === to) {
    return <span>{children}</span>;
  }
  return <Link {...props} />;
};
