import { Paper, Box } from '@mui/material';
import React from 'react';

const SubUI = () => (
  <Box display='flex' flexDirection='column' gap={1.2}>
    {Array.from(Array(9).keys()).map((key) => (
      <Box display='flex' gap={1.2} key={key}>
        {Array.from(Array(9).keys()).map((key) => (
          <Box
            width={10}
            height={10}
            borderRadius='50%'
            bgcolor='primary.main'
            sx={{ opacity: 0.3 }}
            key={key}
          />
        ))}
      </Box>
    ))}
  </Box>
);

type Props = {
  children: React.ReactNode;
};

const CardWrapper = ({ children }: Props) => (
  <Box position='relative'>
    <Paper
      sx={{
        px: {
          base: 5,
          xs: 2,
          sm: 5,
        },
        py: {
          base: 5,
          xs: 5,
          sm: 10,
        },
        borderRadius: 6,
      }}
    >
      {children}
    </Paper>
    {/* <Box position="absolute" top={-48} right={-48} zIndex={-1}>
      <SubUI />
    </Box>
    <Box position="absolute" bottom={-48} left={-48} zIndex={-1}>
      <SubUI />
    </Box> */}
  </Box>
);

export default CardWrapper;

