import React, { forwardRef, ReactElement } from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';

export type SelectInputOption = {
  id: string;
  label: string;
  value: string;
}

export type SelectInputProps = SelectProps & {
  options: SelectInputOption[]
  error?: boolean;
  message?: string;
  label: string;
  renderOption?: (values: SelectInputOption) => ReactElement;
}

export const SelectInput = forwardRef<
  HTMLSelectElement,
  SelectInputProps
>(({
  label,
  options,
  error,
  message,
  renderOption,
  ...props
}, ref) => (

  <FormControl fullWidth>
    {label && (
    <FormLabel error={error}>
      {label}
    </FormLabel>
    )}
    <Select
      displayEmpty
      ref={ref}
      {...props}
    >
      {options.map(({
        value,
        label,
        id,
      }) => (
        <MenuItem value={value} key={id}>
          {label}
        </MenuItem>
      ))}
    </Select>
    {error && message && (
    <FormHelperText error={error}>
      {message}
    </FormHelperText>
    )}
  </FormControl>
));
