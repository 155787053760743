import React, { useEffect } from 'react';
import {
  CardContent,
  Typography,
  Card,
  CardActionArea,
  Box,
} from '@mui/material';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  selected: {
    border: '1px solid green',
  },
});

type Props = {
  title: string | undefined;
  id: string;
  selectedId: string;
  image: string;
  onClick: (id: string) => () => void;
};

export const Brand = ({ title, id, selectedId, onClick, image }: Props) => {
  const classes = useStyles();

  return (
    <Card
      className={id === selectedId ? classes.selected : undefined}
      sx={{
        display: 'flex',
        mr: 1,
        mb: 1,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;',
      }}
    >
      <CardActionArea onClick={onClick(id)}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box
              component='img'
              sx={{
                height: 50,
                width: 50,
                maxHeight: { xs: 50, md: 50 },
                maxWidth: { xs: 50, md: 50 },
                mr: 1,
              }}
              alt={title}
              src={image}
            />
            <Typography>{title}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Brand;
