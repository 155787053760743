import { LoadingButton } from '@mui/lab';
import {
  Box, Stack, Typography,
} from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import SendIcon from '@mui/icons-material/Send';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { TextInput } from 'src/components/Inputs';
import { useRequestSupport } from 'src/hooks/queries/useSupport';
import { RequestSupport } from 'src/Types/Support';
import { yupResolver } from '@hookform/resolvers/yup';
import { getError, getErrorMessage } from 'src/utils/common';
import { SuportLayout } from '../Faq';
import { useTranslation } from 'react-i18next';

const formSchema = yup.object().shape({
  first_name: yup
    .string()
    .required('Required'),
  last_name: yup
    .string()
    .required('Required'),
  email: yup
    .string()
    .email('Invalid')
    .required('Required'),
  question: yup
    .string()
    .required('Required'),
});

const Request = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState,
  } = useForm<RequestSupport>({
    resolver: yupResolver(formSchema),
  });
  const {t} = useTranslation();
  const { mutateAsync, isLoading } = useRequestSupport();
  const handleSubmitForm = async (values: RequestSupport) => {
    await mutateAsync(values);
    handleReset();
  };

  const handleReset = () => {
    reset();
  };

  return (
    <SuportLayout>
      <Typography fontWeight="bold" fontSize={24} textAlign="center">
      {t('requestSupport')}
      </Typography>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Stack spacing={2}>
          <TextInput
            label={t('firstName')}
            error={getError(formState, 'first_name')}
            message={getErrorMessage(formState, 'first_name')}
            {...register('first_name')}
          />
          <TextInput
            label={t('lastName')}
            error={getError(formState, 'last_name')}
            message={getErrorMessage(formState, 'last_name')}
            {...register('last_name')}
          />
          <TextInput
            label={t('email')}
            error={getError(formState, 'email')}
            message={getErrorMessage(formState, 'email')}
            {...register('email')}
          />
          <TextInput
            label="Description"
            rows={10}
            multiline
            error={getError(formState, 'question')}
            message={getErrorMessage(formState, 'question')}
            {...register('question')}
          />
          <Box display="flex" justifyContent="center" gap={2}>
            <LoadingButton
              variant="contained"
              startIcon={<SendIcon />}
              type="submit"
              loading={isLoading}
            >
              Send
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
            >
              Reset
            </LoadingButton>
          </Box>
        </Stack>
      </form>
    </SuportLayout>
  );
};

export default Request;
