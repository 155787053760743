import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const TransactionCompleted = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: 10,
      }}
    >
      <CircularProgress sx={{ alignSelf: 'center' }} />
      <Typography sx={{ alignSelf: 'center', marginTop: 3 }} variant='h5'>
        Transaction completed
      </Typography>
      <Typography sx={{ alignSelf: 'center', marginTop: 1 }}>
        Please close this popup
      </Typography>
    </Box>
  );
};

export default TransactionCompleted;

