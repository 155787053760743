/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { LegalLayout } from '../Privacy';

const CookiePolicy = () => (
  <LegalLayout>
    <Box>
      <div>
        <div>
          <Typography textAlign="center" fontWeight="bold" fontSize={24}>COOKIE POLICY&nbsp;</Typography>
        </div>
        <div>
          <p>&nbsp;</p>
        </div>
        <div>
          <p>
            This Cookie Policy explains how Global Wallet E.O.O.D. ("we",
            "us", "our") uses cookies and similar technologies when you visit
            our website,&nbsp;
            <a
              href="https://bitmambo.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://bitmambo.com
            </a>
            . By using our website, you consent to the use of cookies in
            accordance with this policy.&nbsp;
          </p>
        </div>
        <div>
          <p>WHAT ARE COOKIES?&nbsp;</p>
        </div>
        <div>
          <p>
            Cookies are small text files that are stored on your device
            (computer, tablet, or mobile phone) when you visit a website. They
            help websites remember your preferences, login details, and other
            information to improve your user experience.&nbsp;
          </p>
        </div>
        <div>
          <p>TYPES OF COOKIES WE USE&nbsp;</p>
        </div>
        <div>
          <p>We use the following types of cookies on our website:&nbsp;</p>
        </div>
        <div>
          <div>
            <ol start={1}>
              <li>
                <p>
                  Essential cookies: These cookies are necessary for the
                  proper functioning of our website and cannot be turned off.
                  They enable basic features like page navigation and access
                  to secure areas.&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <ol start={2}>
              <li>
                <p>
                  Performance cookies: These cookies help us understand how
                  users interact with our website by collecting and reporting
                  information about user&nbsp;behavior, such as the number of
                  visitors, pages visited, and the time spent on the
                  site.&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <ol start={3}>
              <li>
                <p>
                  Functionality cookies: These cookies allow our website to
                  remember choices you have made, such as your language
                  preferences or login details, to provide a more personalized
                  experience.&nbsp;
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <ol start={4}>
              <li>
                <p>
                  Targeting/Advertising cookies: These cookies are used to
                  deliver relevant ads to you based on your interests. They
                  also help us measure the effectiveness of our advertising
                  campaigns.&nbsp;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <p>&nbsp;</p>
          </div>
          <div>
            <p>THIRD-PARTY COOKIES&nbsp;</p>
          </div>
          <div>
            <p>
              We may use third-party cookies from service providers, such as
              Google Analytics, to help
              us&nbsp;analyze&nbsp;user&nbsp;behavior&nbsp;and improve our
              website. These third-party cookies are subject to their
              respective privacy policies, which you should review.&nbsp;
            </p>
          </div>
        </div>
        <div>
          <div>
            <p>MANAGING YOUR COOKIE PREFERENCES&nbsp;</p>
          </div>
          <div>
            <p>
              You can manage your cookie preferences in your web browser
              settings. Most browsers allow you to block or delete cookies,
              but please note that disabling essential cookies may affect the
              functionality of our website.&nbsp;
            </p>
          </div>
          <div>
            <p>
              To learn more about managing cookies in popular browsers, please
              visit the following links:&nbsp;
            </p>
          </div>
          <div>
            <ul>
              <li>
                <p>
                  Google Chrome:&nbsp;
                  <a
                    href="https://support.google.com/chrome/answer/95647"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    https://support.google.com
                  </a>
                    &nbsp;
                </p>
              </li>
              <li>
                <p>
                  Mozilla Firefox:&nbsp;
                  <a
                    href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    https://support.mozilla.org
                  </a>
                    &nbsp;
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <ul>
              <li>
                <p>
                  Microsoft Edge:&nbsp;
                  <a
                    href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    https://support.microsoft.com
                  </a>
                    &nbsp;
                </p>
              </li>
              <li>
                <p>
                  Apple Safari:&nbsp;
                  <a
                    href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    https://support.apple.com
                  </a>
                    &nbsp;
                </p>
              </li>
            </ul>
          </div>
          <div>
            <p>CHANGES TO THIS COOKIE POLICY&nbsp;</p>
          </div>
          <div>
            <p>
              We may update this Cookie Policy from time to time. If we make
              significant changes, we will notify you by email or by posting a
              notice on our website. Your continued use of our services after
              any changes constitutes your acceptance of the updated Cookie
              Policy.&nbsp;
            </p>
          </div>
          <div>
            <p>CONTACT US&nbsp;</p>
          </div>
        </div>
        <div>
          <p>
            If you have any questions or concerns about this Cookie Policy,
            please contact us at support@bitmambo.com.&nbsp;
          </p>
        </div>
      </div>
    </Box>
  </LegalLayout>
);

export default CookiePolicy;
