export const getEnv = (key: string) => {
  switch (key) {
    case 'BASE_URL':
      return process.env.REACT_APP_BASE_URL || 'localhost:81/api/v1';
    case 'NODE_ENV':
      return process.env.REACT_APP_NODE_ENV || 'development';
    case 'VERSION':
      return process.env.REACT_APP_VERSION || 'main';
    default:
      return '';
  }
};

export const envEnum = {
  BASE_URL: 'BASE_URL',
  NODE_ENV: 'NODE_ENV',
  VERSION: 'VERSION',
};
