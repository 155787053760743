import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { BITMAMBO_BLACK, BITMAMBO_WHITE } from 'src/constants/images';
import { useGlobalStore } from 'src/stores';
import { RoutePath } from 'src/routes/path';

export const BitmamboLogo = () => {
  const { mode } = useGlobalStore();

  return (
    <Link to={RoutePath.HOME} component={RouterLink}>
      <img
        src={mode === 'dark' ? BITMAMBO_WHITE : BITMAMBO_BLACK}
        alt="logo"
        width={140}
      />
    </Link>
  );
};
