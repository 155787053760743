import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';
import { makeStyles } from '@mui/styles';

import { FormControl, FormHelperText, FormLabel, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    '& .PhoneInput': {
      border: `2px solid ${theme.palette.action.disabled}`,
      padding: '16.5px 14px',
      borderRadius: '12px',
      '& input': {
        border: 'none !important',
        outline: 'none !important',
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body1.fontSize,
      },

      '&:hover': {
        borderColor: theme.palette.text.primary,
      },

      '&:focus-within': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  error: {
    '& .PhoneInput': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
}));

type Props = {
  name: string;
  error?: boolean;
  message?: string;
  label?: string;
  control?: any;
  country: string;
};

export const CountryPhoneInput = React.forwardRef<HTMLInputElement, Props>(
  ({ label, name, error, message, control, country }) => {
    const [isFocus, setIsFocus] = useState(false);
    const classes = useStyles();

    return (
      <FormControl
        fullWidth
        error={error}
        focused={isFocus}
        className={`${classes.input} ${error ? classes.error : ''}`}
        sx={{ border: 'none !important' }}
      >
        {label && <FormLabel error={error}>{label}</FormLabel>}
        <PhoneInput
          international
          countryCallingCodeEditable={false}
          defaultCountry={country as any | ''}
          control={control}
          name={name}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
        {error && message && (
          <FormHelperText error={error}>{message}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

