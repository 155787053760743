import { GetKycStatusResponse, GetKycUrlResponse } from 'src/Types/Kyc';
import { APIPath } from './api-path';
import request from './request';
import { getCountry } from 'src/utils/localStorage';
import { getEnv, envEnum } from 'src/utils/env';

export const getKycStatus = (): Promise<GetKycStatusResponse> =>
  request.get(APIPath.GET_KYC_STATUS);

export const getKycUrl = (): Promise<GetKycUrlResponse> =>
  request.post(APIPath.GET_KYC_URL, {
    country: getCountry() || 'US',
    version: getEnv(envEnum.VERSION),
  });

