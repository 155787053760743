import {
  AC,
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BV,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  EU,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  IC,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TA,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UM,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  XK,
  YE,
  YT,
  ZA,
  ZM,
  ZW,
} from 'country-flag-icons/string/3x2';

export const getCountryFlag = (countryCode: string) => {
  switch (countryCode) {
    case 'AC':
      return AC;
    case 'AD':
      return AD;
    case 'AE':
      return AE;
    case 'AF':
      return AF;
    case 'AG':
      return AG;
    case 'AI':
      return AI;
    case 'AL':
      return AL;
    case 'AM':
      return AM;
    case 'AO':
      return AO;
    case 'AQ':
      return AQ;
    case 'AR':
      return AR;
    case 'AS':
      return AS;
    case 'AT':
      return AT;
    case 'AU':
      return AU;
    case 'AW':
      return AW;
    case 'AX':
      return AX;
    case 'AZ':
      return AZ;
    case 'BA':
      return BA;
    case 'BB':
      return BB;
    case 'BD':
      return BD;
    case 'BE':
      return BE;
    case 'BF':
      return BF;
    case 'BG':
      return BG;
    case 'BH':
      return BH;
    case 'BI':
      return BI;
    case 'BJ':
      return BJ;
    case 'BL':
      return BL;
    case 'BM':
      return BM;
    case 'BN':
      return BN;
    case 'BO':
      return BO;
    case 'BQ':
      return BQ;
    case 'BR':
      return BR;
    case 'BS':
      return BS;
    case 'BT':
      return BT;
    case 'BV':
      return BV;
    case 'BW':
      return BW;
    case 'BY':
      return BY;
    case 'BZ':
      return BZ;
    case 'CA':
      return CA;
    case 'CC':
      return CC;
    case 'CD':
      return CD;
    case 'CF':
      return CF;
    case 'CG':
      return CG;
    case 'CH':
      return CH;
    case 'CI':
      return CI;
    case 'CK':
      return CK;
    case 'CL':
      return CL;
    case 'CM':
      return CM;
    case 'CN':
      return CN;
    case 'CO':
      return CO;
    case 'CR':
      return CR;
    case 'CU':
      return CU;
    case 'CV':
      return CV;
    case 'CW':
      return CW;
    case 'CX':
      return CX;
    case 'CY':
      return CY;
    case 'CZ':
      return CZ;
    case 'DE':
      return DE;
    case 'DJ':
      return DJ;
    case 'DK':
      return DK;
    case 'DM':
      return DM;
    case 'DO':
      return DO;
    case 'DZ':
      return DZ;
    case 'EC':
      return EC;
    case 'EE':
      return EE;
    case 'EG':
      return EG;
    case 'EH':
      return EH;
    case 'ER':
      return ER;
    case 'ES':
      return ES;
    case 'ET':
      return ET;
    case 'EU':
      return EU;
    case 'FI':
      return FI;
    case 'FJ':
      return FJ;
    case 'FK':
      return FK;
    case 'FM':
      return FM;
    case 'FO':
      return FO;
    case 'FR':
      return FR;
    case 'GA':
      return GA;
    case 'GB':
      return GB;
    case 'GD':
      return GD;
    case 'GE':
      return GE;
    case 'GF':
      return GF;
    case 'GG':
      return GG;
    case 'GH':
      return GH;
    case 'GI':
      return GI;
    case 'GL':
      return GL;
    case 'GM':
      return GM;
    case 'GN':
      return GN;
    case 'GP':
      return GP;
    case 'GQ':
      return GQ;
    case 'GR':
      return GR;
    case 'GS':
      return GS;
    case 'GT':
      return GT;
    case 'GU':
      return GU;
    case 'GW':
      return GW;
    case 'GY':
      return GY;
    case 'HK':
      return HK;
    case 'HM':
      return HM;
    case 'HN':
      return HN;
    case 'HR':
      return HR;
    case 'HT':
      return HT;
    case 'HU':
      return HU;
    case 'IC':
      return IC;
    case 'ID':
      return ID;
    case 'IE':
      return IE;
    case 'IL':
      return IL;
    case 'IM':
      return IM;
    case 'IN':
      return IN;
    case 'IO':
      return IO;
    case 'IQ':
      return IQ;
    case 'IR':
      return IR;
    case 'IS':
      return IS;
    case 'IT':
      return IT;
    case 'JE':
      return JE;
    case 'JM':
      return JM;
    case 'JO':
      return JO;
    case 'JP':
      return JP;
    case 'KE':
      return KE;
    case 'KG':
      return KG;
    case 'KH':
      return KH;
    case 'KI':
      return KI;
    case 'KM':
      return KM;
    case 'KN':
      return KN;
    case 'KP':
      return KP;
    case 'KR':
      return KR;
    case 'KW':
      return KW;
    case 'KY':
      return KY;
    case 'KZ':
      return KZ;
    case 'LA':
      return LA;
    case 'LB':
      return LB;
    case 'LC':
      return LC;
    case 'LI':
      return LI;
    case 'LK':
      return LK;
    case 'LR':
      return LR;
    case 'LS':
      return LS;
    case 'LT':
      return LT;
    case 'LU':
      return LU;
    case 'LV':
      return LV;
    case 'LY':
      return LY;
    case 'MA':
      return MA;
    case 'MC':
      return MC;
    case 'MD':
      return MD;
    case 'ME':
      return ME;
    case 'MF':
      return MF;

    case 'MG':
      return MG;
    case 'MH':
      return MH;
    case 'MK':
      return MK;
    case 'ML':
      return ML;
    case 'MM':
      return MM;
    case 'MN':
      return MN;
    case 'MO':
      return MO;
    case 'MP':
      return MP;
    case 'MQ':
      return MQ;
    case 'MR':
      return MR;
    case 'MS':
      return MS;
    case 'MT':
      return MT;
    case 'MU':
      return MU;
    case 'MV':
      return MV;
    case 'MW':
      return MW;
    case 'MX':
      return MX;
    case 'MY':
      return MY;
    case 'MZ':
      return MZ;
    case 'NA':
      return NA;
    case 'NC':
      return NC;
    case 'NE':
      return NE;
    case 'NF':
      return NF;
    case 'NG':
      return NG;

    case 'NI':
      return NI;
    case 'NL':
      return NL;
    case 'NO':
      return NO;
    case 'NP':
      return NP;
    case 'NR':
      return NR;
    case 'NU':
      return NU;
    case 'NZ':
      return NZ;
    case 'OM':
      return OM;
    case 'PA':
      return PA;
    case 'PE':
      return PE;
    case 'PF':
      return PF;
    case 'PG':
      return PG;
    case 'PH':
      return PH;
    case 'PK':
      return PK;
    case 'PL':
      return PL;
    case 'PM':
      return PM;
    case 'PN':
      return PN;
    case 'PR':
      return PR;
    case 'PS':
      return PS;
    case 'PT':
      return PT;
    case 'PW':
      return PW;
    case 'PY':
      return PY;
    case 'QA':
      return QA;
    case 'RE':
      return RE;
    case 'RO':
      return RO;
    case 'RS':
      return RS;
    case 'RU':
      return RU;
    case 'RW':
      return RW;
    case 'SA':
      return SA;
    case 'SB':
      return SB;
    case 'SC':
      return SC;
    case 'SD':
      return SD;
    case 'SE':
      return SE;
    case 'SG':
      return SG;
    case 'SH':
      return SH;
    case 'SI':
      return SI;
    case 'SJ':
      return SJ;
    case 'SK':
      return SK;
    case 'SL':
      return SL;
    case 'SM':
      return SM;
    case 'SN':
      return SN;
    case 'SO':
      return SO;
    case 'SR':
      return SR;
    case 'SS':
      return SS;
    case 'ST':
      return ST;
    case 'SV':
      return SV;
    case 'SX':
      return SX;
    case 'SY':
      return SY;
    case 'SZ':
      return SZ;
    case 'TA':
      return TA;
    case 'TC':
      return TC;

    case 'TD':
      return TD;
    case 'TF':
      return TF;
    case 'TG':
      return TG;
    case 'TH':
      return TH;
    case 'TJ':
      return TJ;
    case 'TK':
      return TK;
    case 'TL':
      return TL;
    case 'TM':
      return TM;
    case 'TN':
      return TN;
    case 'TO':
      return TO;

    case 'TR':
      return TR;
    case 'TT':
      return TT;
    case 'TV':
      return TV;
    case 'TW':
      return TW;
    case 'TZ':
      return TZ;
    case 'UA':
      return UA;
    case 'UG':
      return UG;
    case 'UM':
      return UM;
    case 'US':
      return US;
    case 'UY':
      return UY;
    case 'UZ':
      return UZ;
    case 'VA':
      return VA;
    case 'VC':
      return VC;
    case 'VE':
      return VE;

    case 'VG':
      return VG;

    case 'VI':
      return VI;
    case 'VN':
      return VN;
    case 'VU':
      return VU;
    case 'WF':
      return WF;
    case 'WS':
      return WS;
    case 'XK':
      return XK;
    case 'YE':
      return YE;
    case 'YT':
      return YT;
    case 'ZA':
      return ZA;

    case 'ZM':
      return ZM;
    case 'ZW':
      return ZW;
    default:
      return 'https://www.countryflags.io/unknown/flat/64.png';
  }
};
