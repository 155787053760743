import { Mode } from 'src/stores';

const TOKEN = 'TOKEN';
const MODE = 'MODE';
const LANGUAGE = 'LANGUAGE';
const COUNTRY = 'COUNTRY';

export const getToken = () => localStorage.getItem(TOKEN);
export const setToken = (token: string) => localStorage.setItem(TOKEN, token);
export const removeToken = () => localStorage.removeItem(TOKEN);

export const getMode = () => (localStorage.getItem(MODE) || 'light') as Mode;
export const setMode = (mode: string) => localStorage.setItem(MODE, mode);

export const setLanguage = (language: string) =>
  localStorage.setItem(LANGUAGE, language);
export const getLanguage = () => localStorage.getItem(LANGUAGE) || 'fr';

export const setCountry = (country: string) =>
  localStorage.setItem(COUNTRY, country);
export const getCountry = () => localStorage.getItem(COUNTRY);

