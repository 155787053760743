import _ from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { APIPath } from 'src/apis/api-path';
import {
  getCardDetail,
  getCards,
  removeCard,
  saveCard,
} from 'src/apis/card';
import { CardInfo } from 'src/Types/Card';
import { handleError } from 'src/utils/toast';

export const useGetCards = () => useQuery(
  [APIPath.GET_CARDS],
  async () => {
    const res = await getCards();
    const cards: CardInfo[] = _.get(res, 'docs', []);
    return cards.map(({
      card_holder_name,
      card_number,
      expiration_date,
    }) => ({
      label: card_holder_name,
      value: card_number,
      id: card_number,
      card_holder_name,
      card_number,
      expiration_date,
    }));
  },
);

export const useGetCardDetail = (
  id: string,
) => useQuery(
  [APIPath.GET_CARD_DETAIL, id],
  () => getCardDetail(id),
  { enabled: !!id },
);

export const useSaveCard = () => useMutation(saveCard);

export const useRemoveCard = () => {
  const queryClient = useQueryClient();
  return useMutation(removeCard, {
    onSuccess: () => {
      queryClient.invalidateQueries([APIPath.GET_CARDS]);
    },
    onError: (err) => {
      handleError(err);
    },
  });
};
