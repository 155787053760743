import { create } from 'zustand';

export type FormState = {
  email: string | null;
  phone: string | null;
};

export type LoginState = {
  step: number;
  formState: FormState;
  onNext: () => void;
  setFormState: (values: FormState) => void;
  resetStore: () => void;
};

const initialState: LoginState = {
  step: 1,
  formState: {
    email: null,
    phone: null,
  },

  onNext: () => null,
  setFormState: () => null,
  resetStore: () => null,
};

export const useLoginStore = create<LoginState>((set) => ({
  ...initialState,
  onNext: () => set(({ step }) => ({ step: step + 1 })),
  setFormState: (values: FormState) =>
    set(({ formState }) => ({
      formState: { ...formState, ...values },
    })),

  resetStore: () =>
    set(() => ({
      step: initialState.step,
      formState: { ...initialState.formState },
    })),
}));

