import React from 'react';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { Box, Typography } from '@mui/material';

export interface ToastMessageProps {
  title?: string;
  description?: string;
  type?: 'success' | 'error' | 'warning' | 'info';
  onClick?: () => void;
}

const mapColor = {
  success: 'success.main',
  error: 'error.main',
  warning: 'warning.main',
  info: 'info.main',
};

export const ToastMessage = ({
  title,
  description,
  type = 'info',
  onClick,
}: ToastMessageProps) => (
  <Box display="flex" onClick={onClick}>
    <ErrorOutlinedIcon sx={{ color: mapColor[type] }} />
    <Box ml={1}>
      {title && <Typography>{title}</Typography>}
      {description && <Typography>{description}</Typography>}
    </Box>
  </Box>
);
