import React, { useEffect } from 'react';
import { Box, Paper, Stack, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

import { TextInput, SelectInput } from 'src/components/Inputs';
import { User } from 'src/Types/Auth';
import { useGetProfile, useUpdateProfile } from 'src/hooks/queries/useAuth';
import { useTranslation } from 'react-i18next';
import { envEnum, getEnv } from 'src/utils/env';
import { COUNTRIES } from 'src/constants/country';
import { useSettingsStore } from 'src/stores/settings';

const Infomation = () => {
  const { authenticationMethod } = useSettingsStore();
  const { t } = useTranslation();
  const isPhoneEditable = authenticationMethod == 'phone';
  const isEmailEditable = authenticationMethod == 'email';

  const { register, reset, handleSubmit, control } = useForm<User>({
    defaultValues: { dayofbirth: null },
  });
  const { data } = useGetProfile();
  const { mutate } = useUpdateProfile();

  const handleSubmitForm = (values: User) => {
    mutate(values);
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  return (
    <Box maxWidth='sm' sx={{ mt: 5, mx: 'auto' }}>
      <Paper
        sx={{
          p: {
            base: 5,
            xs: 3,
            sm: 3,
            md: 3,
            lg: 5,
          },
          borderRadius: '20px',
        }}
      >
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Stack spacing={2}>
            <TextInput
              label={t('email')}
              placeholder={t('EnterYourEmail')}
              readOnly={!isEmailEditable}
              {...register('email')}
            />
            <Box
              display='flex'
              gap={{
                base: 2,
                xs: 1,
                sm: 1,
                md: 2,
              }}
            >
              <TextInput
                label={t('firstName')}
                placeholder={t('enterYourFirstName')}
                {...register('first_name')}
              />
              <TextInput
                label={t('lastName')}
                placeholder={t('enterYourLastName')}
                {...register('last_name')}
              />
            </Box>
            <Box
              display='flex'
              gap={{
                base: 2,
                xs: 1,
                sm: 1,
                md: 2,
              }}
            >
              <TextInput label={t('dayOfBirth')} {...register('dayofbirth')} />
              <TextInput
                label={t('phoneNumber')}
                placeholder={t('enterYourPhoneNumber')}
                {...register('phone')}
                readOnly={!isPhoneEditable}
              />
            </Box>
            <Box
              display='flex'
              gap={{
                base: 2,
                xs: 1,
                sm: 1,
                md: 2,
              }}
            >
              <Controller
                name='country.country_short'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <SelectInput
                    label={t('country')}
                    placeholder={t('country')}
                    options={COUNTRIES}
                    {...field}
                  />
                )}
              />

              <TextInput label={t('city')} {...register('city')} />
            </Box>
            <Box
              display='flex'
              gap={{
                base: 2,
                xs: 1,
                sm: 1,
                md: 2,
              }}
            >
              <TextInput
                label={t('state')}
                placeholder={t('state')}
                {...register('state')}
              />
              <TextInput
                label={t('zipcode')}
                placeholder={t('zipcode')}
                type='number'
                {...register('postal_code')}
              />
            </Box>
            <TextInput
              label={t('street')}
              placeholder={t('street')}
              {...register('street1')}
            />

            <Button
              variant='contained'
              type='submit'
              disabled={false}
              fullWidth
            >
              {t('update')}
            </Button>
          </Stack>
        </form>
      </Paper>
    </Box>
  );
};

export default Infomation;

