import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { COUNTRIES } from 'src/constants/country';
import { getCountryFlag } from 'src/constants/countryFlags';
import { useSettingsStore } from 'src/stores/settings';
import { setCountry, getCountry } from 'src/utils/localStorage';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
type Props = {
  onChange: (country: string) => void;
};
const SelectCountry = ({ onChange }: Props) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const country = getCountry();
  const lang = i18n.language;
  

  const {
    languagesState: { defaultLanguage, languages },
    supportedCountriesState: { countries: supportedCountriesCodes },
  } = useSettingsStore();

  const countries = _.filter(COUNTRIES, (country) =>
    supportedCountriesCodes.includes(country.id)
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value: string) => {
    setCountry(value);
    onChange(value);
    handleClose();
    const countryLanguage = _.find(languages, { country: value });
    const url = `${window.location.protocol}//${window.location.hostname}:${
      window.location.port
    }/${countryLanguage?.language || defaultLanguage}`;
    window.location.replace(url);
  };

  return (
    <>
      <Stack direction='row' alignItems='center' spacing={1} mt={-4}>
        <Typography variant='caption'>{t('selectCountry')}</Typography>
        <Chip
          label={country}
          onClick={handleOpen}
          onDelete={() => undefined}
          deleteIcon={<ChevronRightOutlinedIcon />}
          avatar={
            <Avatar
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                getCountryFlag(country || '')
              )}`}
            />
          }
        />
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='xs'
        PaperProps={{ sx: { borderRadius: 4 } }}
      >
        <DialogTitle id='alert-dialog-title'>
          <Typography fontWeight='bold'>Select Country</Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 2,
              maxHeight: '420px',
              overflow: 'auto',
              mt: 2,
            }}
          >
            <MenuList>
              {countries.map((country) => (
                <MenuItem
                  key={country.id}
                  onClick={() => handleChange(country.id)}
                >
                  <Box display='flex'>
                    <Avatar
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        getCountryFlag(country.id)
                      )}`}
                    />
                    <Box ml={2}>
                      <Typography
                        variant='button'
                        display='block'
                        lineHeight={1.2}
                      >
                        {country.label}
                      </Typography>
                      <Typography variant='caption' lineHeight={1}>
                        {country.id}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SelectCountry;
