import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { Mode } from 'src/stores';

// A custom theme for this app
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xl_4k: true;
    xl_2k: true;
  }
}
const getTheme = (mode: Mode) =>
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xl_4k: 3800,
        xl_2k: 2000,
      },
    },
    palette: {
      mode,
      primary: {
        main: '#3233FF',
      },
      secondary: {
        main: '#f50057',
      },
      error: {
        main: red.A400,
      },
      background: {
        paper: mode === 'dark' ? '#18191d' : '#fff',
        default: mode === 'dark' ? '#141416' : '#ebf1ff',
      },
    },
    typography: {
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      fontFamily: "'Montserrat', sans-serif",
      button: {
        fontWeight: 700,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        a {
          text-decoration: none;
        }
      `,
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '32px',
            padding: '12px 32px',
          },
          contained: {
            ':disabled': {
              color: 'white',
              backgroundColor: 'rgb(153 153 255)',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
          },
          notchedOutline: {
            borderWidth: '2px',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            fontWeight: 700,
            marginBottom: '8px',
            textTransform: 'uppercase',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
            boxShadow: '0 64px 64px -48px rgb(15 15 15 / 10%)',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '16px',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none',
            color: 'inherit',
          },
        },
      },
    },
  });

export default getTheme;

