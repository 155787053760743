import React, { useEffect, useState } from 'react';
import _, { get } from 'lodash';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';

import { CountryPhoneInput } from 'src/components/Inputs';
import { getError, getErrorMessage } from 'src/utils/common';
import { RoutePath } from 'src/routes/path';
import { useLogin } from 'src/hooks/queries/useAuth';
import { EmailIdentification } from 'src/Types/Auth';
import { useLoginStore } from 'src/stores/login';
import { CustomLink } from 'src/Features/core/Common';
import CardWrapper from 'src/components/Common/CardWrapper';
import { useTranslation } from 'react-i18next';
import { getCountry } from 'src/utils/localStorage';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

const formSchema = yup.object().shape({
  phone: yup.string().required('Phone number is required'),
  checked: yup.boolean().oneOf([true], 'Required'),
});

const CheckPhone = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const prePhoneNumber = searchParams.get('phone');

  const history = useHistory();
  const { i18n, t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const { onNext, setFormState } = useLoginStore();
  const storageCountry = getCountry();

  const [country, setCountry] = useState(storageCountry);
  const { mutateAsync, isLoading } = useLogin();
  const { register, handleSubmit, formState, watch, control, setValue } =
    useForm<EmailIdentification>({ resolver: yupResolver(formSchema) });

  const checked = watch('checked');

  const handleSubmitForm = async ({ phone }: EmailIdentification) => {
    try {
      await mutateAsync({ phone, email: '' });
      setFormState({ phone, email: null });
      onNext();
    } catch (error) {
      const errCode = _.get(error, 'response.status', null);
      if (errCode === 404) {
        history.push(`${RoutePath.REGISTER}?phone=${phone}`);
      }
      setIsError(true);
    }
  };

  useEffect(() => {
    if (!storageCountry) {
      setTimeout(() => {
        const _country = getCountry();
        if (_country) {
          setCountry(_country);
        }
      }, 2000);
    } else {
      setCountry(storageCountry);
    }
  }, [storageCountry]);

  useEffect(() => {
    if (prePhoneNumber) {
      let _phone = prePhoneNumber;
      if (!prePhoneNumber.startsWith('+')) {
        _phone = '+' + prePhoneNumber;
      }
      setValue('phone', prePhoneNumber);
      handleSubmitForm({
        phone: _phone.replace(' ', ''),
        checked: true,
        email: '',
      });
    }
  }, [prePhoneNumber]);

  return (
    <Box>
      <CardWrapper>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography
              fontSize={{
                base: 32,
                xs: 22,
                sm: 24,
                md: 32,
              }}
              fontWeight='bold'
              mt={2}
              textAlign='center'
            >
              {t('WelcomeToBitmambo')}
            </Typography>
            <Typography
              fontWeight='medium'
              maxWidth='320px'
              textAlign='center'
              color='action.active'
            >
              {t('PleaseProvideYourEmailToReceivePaymentNotifications')}
            </Typography>
          </Box>
          <Box mt={4}>
            <CountryPhoneInput
              country={country || ''}
              label={t('phoneNumber')}
              error={getError(formState, 'phone')}
              message={getErrorMessage(formState, 'phone')}
              control={control}
              {...register('phone')}
            />
          </Box>
          {isError && (
            <Typography
              textAlign='center'
              mt={2}
              color='primary'
              fontWeight='bold'
            >
              {t('NoAccountWithThisEmailExists')}:{' '}
              <CustomLink
                to={RoutePath.REGISTER}
                style={{ color: 'inherit', textDecoration: 'underline' }}
              >
                {t('ClickHereToRegisterABitmamboAccountNow')}
              </CustomLink>
            </Typography>
          )}
          <Box display='flex' justifyContent='center' mt={1}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...register('checked')} />}
                label={
                  <Typography color='action.active' fontWeight='medium'>
                    {t('IAgreeBitmambo')}{' '}
                    <Typography
                      component='span'
                      color='primary'
                      fontWeight='medium'
                    >
                      {t('TermsOfUse')}
                    </Typography>
                  </Typography>
                }
              />
            </FormGroup>
          </Box>
          <Box display='flex' justifyContent='center' mt={4}>
            <LoadingButton
              variant='contained'
              type='submit'
              loading={isLoading}
              fullWidth
              disabled={!checked}
            >
              {t('Continue')}
            </LoadingButton>
          </Box>
        </form>
      </CardWrapper>
    </Box>
  );
};

export default CheckPhone;
