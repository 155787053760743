import { useQuery, useMutation } from 'react-query';
import { getCountry } from 'src/apis/common';
import { APIPath } from 'src/apis/api-path';
import { useLoginStore } from 'src/stores/login';
import _ from 'lodash';

import { setCountry } from 'src/utils/localStorage';

export const useGetCountry = () =>
  useQuery([APIPath.GET_COUNTRY], async () => {
    const { country } = await getCountry();
    return {
      country,
    };
  });

export const useMutateGetCountry = () => {
  return useMutation(getCountry, {
    onSuccess: (data) => {
      setCountry(_.get(data, 'country', 'CI'));
    },
  });
};
