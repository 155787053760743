import { Avatar, AvatarProps, Box, BoxProps } from '@mui/material';
import React from 'react';
import { MOOV_MONEY, MTN, ORANGE_MONEY } from 'src/constants/images';

type Props = {
  containerProps?: BoxProps;
  avatarProps?: AvatarProps;
};

const AfricaPaymentLogos = ({ avatarProps, containerProps }: Props) => (
  <Box display='flex' gap={2} {...containerProps}>
    <Avatar
      src={MOOV_MONEY}
      alt='moov_money'
      {...avatarProps}
      sx={{
        borderRadius: 0,
        width: 'auto',
        ...avatarProps?.sx,
        maxWidth: 40,
        maxHeight: 40,
      }}
    />
    <Avatar
      src={MTN}
      alt='mtn'
      {...avatarProps}
      sx={{
        borderRadius: 0,
        width: 'auto',
        ...avatarProps?.sx,
        maxWidth: 40,
        maxHeight: 40,
      }}
    />
    <Avatar
      src={ORANGE_MONEY}
      alt='orange-money'
      {...avatarProps}
      sx={{
        borderRadius: 1.5,
        width: 60,
        height: 40,
        ...avatarProps?.sx,
        maxWidth: 60,
        maxHeight: 40,
      }}
    />
  </Box>
);

export default AfricaPaymentLogos;
