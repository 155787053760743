import { create } from 'zustand';

import { getMode, removeToken, setMode } from 'src/utils/localStorage';

export type User = {
  username: string;
  email: string;
  token: string;
}

export type Mode = 'light' | 'dark';

export type GlobalState = {
  user: User | null;
  isAuth: boolean;
  mode: Mode;
  switchMode: () => void;
  setUser: (user: User) => void;
  logout: () => void;
  setIsAuth: (value: boolean) => void;
}

const initialState: GlobalState = {
  user: null,
  isAuth: false,
  mode: getMode(),
  switchMode: () => null,
  setUser: () => null,
  logout: () => null,
  setIsAuth: () => null,
};

export const useGlobalStore = create<GlobalState>((set, get) => ({
  ...initialState,
  switchMode: () => {
    const { mode } = get();
    const newMode = mode === 'dark' ? 'light' : 'dark';
    set(() => ({ mode: newMode }));
    setMode(newMode);
  },
  setUser: (user: User) => set(() => ({ user })),
  logout: () => {
    set(() => ({ user: null, isAuth: false }));
    removeToken();
  },
  setIsAuth: (value: boolean) => set({ isAuth: value }),
}));
