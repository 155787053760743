import React from 'react';
import { Box } from '@mui/material';

import {
  FACEBOOK, INSTAGRAM, TWITTER,
} from 'src/constants/images';

export const Socials = () => (
  <Box display="flex" gap={1}>
    <a href="https://facebook.com/bitmambo" target="_blank" rel="noreferrer">
      <img src={FACEBOOK} alt="facebook" />
    </a>
    <a href="https://www.instagram.com/bitmamboexchange/" target="_blank" rel="noreferrer">
      <img src={INSTAGRAM} alt="instagram" />
    </a>
    {/* <a href="https://twitter.com/BitMambo" target="_blank" rel="noreferrer">
      <img src={YOUTUBE} alt="youtube" />
    </a> */}
    <a href="https://twitter.com/BitMambo" target="_blank" rel="noreferrer">
      <img src={TWITTER} alt="twitter" />
    </a>
    {/* <a href="https://twitter.com/BitMambo" target="_blank" rel="noreferrer">
      <img src={LINKED_IN} alt="linked_in" />
    </a> */}
  </Box>
);
