import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker';
import { FormControl, FormLabel } from '@mui/material';

type View = 'day' | 'month' | 'year';

export type Props = Omit<DesktopDatePickerProps<any, any >, 'renderInput'> & {
  label: string;
  error?: boolean;
  message?: string;
  format?: string;
  views?: View[];
  onChange: any;
  value: any;
  onBlur: any;
}

export const DatePickerInput = React.forwardRef<any, Props>(({
  label,
  error,
  message,
  format = 'DD/MM/YYYY',
  views = ['day', 'month', 'year'],
  onBlur,
  ...rest
}, ref) => {
  const [isFocus, setIsFocus] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        {...rest}
        views={views}
        inputFormat={format}
        InputProps={{ onBlur }}
        ref={ref}
        renderInput={({ error: inputError, ...params }) => (
          <FormControl error={error} focused={isFocus} fullWidth>
            <FormLabel>{label}</FormLabel>
            <TextField
              {...params}
              inputProps={{ onBlur: () => setIsFocus(false), ...params.inputProps }}
              onFocus={() => setIsFocus(true)}
              error={error}
              helperText={error && message}
              label={null}
            />
          </FormControl>
        )}
      />
    </LocalizationProvider>
  );
});
