import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { en as enG, fr as frG, sv as svG } from './global';
import { en as enA, fr as frA, sv as svA } from './africa';
import { getEnv, envEnum } from 'src/utils/env';

export const supportedLanguages = ['en', 'fr', 'sv'];
export const defaultLang = 'fr';

const getLanguage = () => {
  const version = getEnv(envEnum.VERSION);
  switch (version) {
    case 'global':
      return { en: enG, fr: frG, sv: svG };
      break;
    case 'Africa':
      return { en: enA, fr: frA, sv: svA };
      break;
    default:
      return { en: enG, fr: frG, sv: svG };
      break;
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: defaultLang,
    supportedLngs: supportedLanguages,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['path', 'navigator'],
      lookupFromPathIndex: 0,
    },
    resources: {
      en: getLanguage().en,
      fr: getLanguage().fr,
      sv: getLanguage().sv,
    },
  });

export default i18n;

