/* eslint-disable react/no-unescaped-entities */
import React, { ReactNode, useState } from 'react';
import {
  Box, Button, Card, Container, Drawer, Link, Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { RoutePath } from 'src/routes/path';
import { useTranslation } from "react-i18next";

type LegalLayoutProps = {
  children: ReactNode
}

export const SuportLayout = ({ children }: LegalLayoutProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation();

  const renderMenu = () => (
    <>
      <Typography variant="button" display="block" mb={2}>
        SUPPORT
      </Typography>
      <Link
        to={RoutePath.REQUEST}
        component={RouterLink}
        sx={{ textDecoration: 'none', color: 'text.primary', display: 'block' }}
      >
        {t('Request')}
      </Link>
      <Link
        to={RoutePath.FAQ}
        component={RouterLink}
        sx={{ textDecoration: 'none', color: 'text.primary', display: 'block' }}
      >
        FAQ
      </Link>
    </>
  );

  return (
    <Container maxWidth="lg">
      <Box
        mb={2}
        display={{
          xs: 'block', sm: 'block', md: 'block', lg: 'none',
        }}
      >
        <Button fullWidth variant="contained" onClick={() => setIsOpen(true)}>
          SUPPORT
        </Button>
      </Box>
      <Box display="flex">
        <Box
          width="200px"
          flexShrink={0}
          display={{
            xs: 'none', sm: 'none', md: 'none', lg: 'block',
          }}
        >
          {renderMenu()}
        </Box>
        <Box flex={1}>
          <Card sx={{ borderRadius: 4 }}>
            <Box sx={{ px: 4, py: 6 }}>
              {children}
            </Box>
          </Card>
        </Box>
      </Box>
      <Drawer anchor="left" open={isOpen} onClose={() => setIsOpen(false)}>
        <Box p={4} width="260px">
          {renderMenu()}
        </Box>
      </Drawer>
    </Container>
  );
};

const FAQ = () => (
  <SuportLayout>
    <Typography fontWeight="bold" fontSize={24} textAlign="center">FAQ</Typography>
    <Typography fontWeight="bold" my={1}>
      1.
      What is instant.bitmambo?
    </Typography>
    <Typography>
      Instant.bitmambo
      is a user
      -
      friendly on
      -
      ramp cryptocurrency exchange platform that allows
      users to buy digital assets using various fiat methods such as cards, open banking, and
      wallets. We strive to make cryptocurrency purchases simple and secure for everyone.
    </Typography>
    <Typography fontWeight="bold" my={1}>
      2.
      Do I need t
      o have my own crypto wallet to use instant.bitmambo?
    </Typography>
    <Typography>
      Yes, you need to have your own cryptocurrency wallet
      to use our platform. Instant.bitmambo
      does not offer custodian wallets, ensuring that you have
      full control over your digital assets.
    </Typography>
    <Typography fontWeight="bold" my={1}>
      3.
      What are the ben
      efits of not offering custodian wallets?
    </Typography>
    <Typography>
      By not offering custodian wallets, we empower you to have complete control and ownership
      of your digital assets. This means that we cannot run away with your cryptocurrencies,
      ensuring a more secure and transparent
      experience.
    </Typography>
    <Typography fontWeight="bold" my={1}>4. What fiat payment methods are accepted at instant.bitmambo?</Typography>
    <Typography>
      Instant.bitmambo supports a variety of fiat payment methods, including credit/debit cards,
      open banking, and digital wallets. We aim to provide a seamless and convenient experience
      for
      purchasing digital assets.
    </Typography>
    <Typography fontWeight="bold" my={1}>5. Is instant.bitmambo safe to use?</Typography>
    <Typography>
      Yes, instant.bitmambo prioritizes the security of your transactions and personal information.
      Our platform adheres to strict security protocols and industry best practices to ensure a safe
      and se
      cure experience for our users.
    </Typography>
    <Typography fontWeight="bold" my={1}>
      6.
      How can I get started with instant.bitmambo?
    </Typography>
    <Typography>
      To get started, simply visit instant.bitmambo.com and follow the easy step
      -
      by
      -
      step
      instructions to buy your desired cryptocurrency. You'll need to provide your personal wallet
      addr
      ess to receive your purchased digital assets.
    </Typography>
    <Typography fontWeight="bold" my={1}>
      7.
      Can I buy multiple cryptocurrencies on instant.bitmambo?
    </Typography>
    <Typography>
      Yes, instant.bitmambo
      supports a wide range of popular cryptocurrencies. We continuously
      update our platform to include additional digital assets based on market demand and trends.
    </Typography>
  </SuportLayout>
);

export default FAQ;
