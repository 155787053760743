import * as React from 'react';
import styled from '@emotion/styled';
import { Container } from '@mui/material';

import { useListenRouterChange } from 'src/hooks/app/useListenRouter';
import Header from 'src/Features/core/Header';
import Footer from '../Footer';

type Props = {
  children: React.ReactNode;
};

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

function MainLayout({ children }: Props) {
  // Listen router change
  useListenRouterChange();

  return (
    <Wrapper>
      <Header />
      <Container maxWidth='xl' sx={{ flex: 1, pt: 2, pb: 4 }}>
        {children}
      </Container>
      <Footer />
    </Wrapper>
  );
}
export default MainLayout;

