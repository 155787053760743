import dayjs from 'dayjs';
import { Pagination } from 'src/Types/Common';
import {
  GetFee,
  GetFeeResponse,
  PairResponse,
  Payment,
  PaymentConfirm,
  PaymentResponse,
  PaymentOptionResponse,
  PreparePayment,
  PreparePaymentResponse,
  TransactionHistoriesResponse,
  VerifyWalletAddress,
  VerifyWalletAddressResponse,
} from 'src/Types/Currency';
import { APIPath } from './api-path';
import request from './request';
import { envEnum, getEnv } from 'src/utils/env';

export const getPairs = (country: string): Promise<PairResponse> => {
  const path = `${APIPath.GET_PAIRS_CURRENCY}?country=${country}&version=${process.env.REACT_APP_VERSION}`;
  return request.post(path);
};

export const getFee = (data: GetFee): Promise<GetFeeResponse> =>
  request.post(APIPath.GET_FEE, data);

export const verifyWalletAddress = (
  data: VerifyWalletAddress
): Promise<VerifyWalletAddressResponse> =>
  request.post(APIPath.VERIFY_ADDRESS, data);

export const preparePayment = (
  data: PreparePayment
): Promise<PreparePaymentResponse> =>
  request.post(APIPath.PREPARE_PAYMENT, {
    ...data,
    version: getEnv(envEnum.VERSION),
  });

export const payment = (data: Payment): Promise<PaymentResponse> => {
  const payload = sanitizePayment(data);
  return request.post(APIPath.PAYMENT, payload);
};

export const confirm = (data: PaymentConfirm): Promise<PaymentResponse> =>
  request.post(APIPath.CONFIRM, data);
export const getTransactionHistories = (
  params: Pagination
): Promise<TransactionHistoriesResponse> =>
  request.get(APIPath.GET_TRANSACTION_HISTORIES, { params });
export const getPaymentOptions = (): Promise<PaymentOptionResponse> => {
  return request.get(
    `${APIPath.GET_PAYMENT_OPTIONS}?version=${getEnv(envEnum.VERSION)}`
  );
};
export const getTransactionDetails = (id: string) => {
  return request.get(`${APIPath.GET_TRANSACTION_DETAILS}?payment_id=${id}`);
};
const sanitizePayment = ({
  phone_number,
  payment_option,
  address,
  card_holder_name,
  card_number,
  city,
  country,
  cvv,
  expiration_date,
  payment_id,
  state,
  zip,
}: Payment) => {
  if (payment_option == 'Mobile') {
    return {
      payment_option,
      payment_id,
      country,
      state,
      address,
      city,
      zip,
      phone_number,
    };
  }
  if (payment_option == 'Card')
    return {
      payment_option,
      payment_id,
      card: {
        card_holder_name,
        card_number,
        expiration_date: dayjs(expiration_date).format('MM/YYYY'),
        cvv,
      },
      country,
      state,
      address,
      city,
      zip,
    };
  if (payment_option == 'Bank Transfer')
    return {
      payment_option,
      payment_id,
      country,
      state,
      address,
      city,
      zip,
    };
};

