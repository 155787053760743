import React, { ReactNode, useEffect, useState } from 'react';
import {
  Box, CircularProgress, Card, Container, Drawer, Link, Typography,
} from '@mui/material';
import { RoutePath } from 'src/routes/path';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useHistory } from 'react-router';

const Referral = () => {
  const history = useHistory();
  const params = useParams();
  const id = _.get(params, 'id', '');
  useEffect(() => {
    if (id) {
      history.push(`${RoutePath.REGISTER}?refId=${id}`)
    }
  }, [])
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
      <CircularProgress />
    </Box>
  )
};

export default Referral;
